import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { isValidInput } from "../../utils/validations";
import {
  useLazyGetAllSubAdminsQuery,
  useChangeSubAdminStatusMutation,
  useDeleteSubAdminMutation,
} from "../../services/main";
import { Loader, showError, showToast } from "../../constants";
import { SubAdmin } from "../../types/General";
import WarnModal from "../../components/WarnModal";
import Pagination from "../../components/Pagination";
import useTranslation from "../../hooks/Translation";

const ManageSubAdmin = () => {
  const navigate = useNavigate();
  const translate = useTranslation();
  const [getAllSubAdmin] = useLazyGetAllSubAdminsQuery();
  const [updateStatus] = useChangeSubAdminStatusMutation();
  const [deleteSubAdmin] = useDeleteSubAdminMutation();

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [page, setPage] = useState(1);
  const [subAdminData, setSubAdminData] = useState<SubAdmin[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [loading, setLoading] = useState(false);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  let totalPages = Math.ceil(totalCount / 10);

  const getSubAdmin = async () => {
    setLoading(true);
    try {
      const response = await getAllSubAdmin({
        page: page,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setLoading(false);
        setSubAdminData(response?.data?.subAdmin || []);
        setTotalCount(response?.data?.count);
      } else {
        setSubAdminData([]);
        setLoading(false);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
      setLoading(false);
    }
  };

  const updateSubAdminStatus = async (id: string, status: boolean) => {
    const response = await updateStatus({
      subAdminId: id,
      body: {
        isBlocked: status,
      },
    }).unwrap();
    if (response?.statusCode === 200) {
      const updatedData = subAdminData.map((x) => {
        if (x._id === id) {
          return {
            ...x,
            isBlocked: status,
          };
        }
        return x;
      });
      setSubAdminData(updatedData);
      showToast(translate.error.statusUpdate);
    } else {
      showError("Please try again");
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteSubAdmin({ subAdminId: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast(translate.error.subAdminDel);
        getSubAdmin();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };

  useEffect(() => {
    getSubAdmin();
  }, [debouncedSearchTerm]);

  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translate?.sideBar?.subAdmin}</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-subAdmin/add")}
              >
                {translate?.main?.addSubAdmin}
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {translate?.globals?.serialNo}
                  </TableCell>
                  <TableCell>{translate?.globals?.name}</TableCell>
                  <TableCell>{translate?.auth?.email}</TableCell>
                  <TableCell>{translate?.auth?.phone}</TableCell>
                  <TableCell>{translate?.globals?.status}</TableCell>
                  <TableCell>{translate?.globals?.actions}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subAdminData?.length > 0 ? (
                  subAdminData?.map((row, i) => (
                    <TableRow>
                      <TableCell align="center">{i + 1}</TableCell>
                      <TableCell>
                        {row?.firstName + " " + row?.lastName || "-"}
                      </TableCell>
                      <TableCell>{row?.email || "-"}</TableCell>
                      <TableCell>
                        {row?.countryCode + " " + row?.phone || "-"}
                      </TableCell>
                      <TableCell>
                        <Switch
                          {...label}
                          checked={!row?.isBlocked}
                          onChange={() =>
                            updateSubAdminStatus(row?._id, !row?.isBlocked)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          <IconButton
                            onClick={() =>
                              navigate(`/manage-subAdmin/edit/${row?._id}`)
                            }
                          >
                            <ModeEditIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setOpen(true);
                              setSelectedId(row?._id);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      {translate?.error?.noData}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        {subAdminData?.length > 0 ? (
          <Pagination
            setPage={setPage}
            module={subAdminData}
            page={page}
            onPageChange={onPageChange}
            totalPages={totalPages}
          />
        ) : (
          ""
        )}
      </div>

      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="Sub-Admin"
      />
    </MainContainer>
  );
};

export default ManageSubAdmin;
