import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { Button, Card, Grid, TextField, Typography } from "@mui/material";
import EditText from "../../components/EditText";
import PhoneInput from "react-phone-input-2";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import * as Yup from "yup";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import { useLazyGetCmsQuery, usePostCmsMutation } from "../../services/cms";
import "react-phone-input-2/lib/bootstrap.css";
import { showError, showToast } from "../../constants";
import useAuth from "../../hooks/useAuth";
import useTranslation from "../../hooks/Translation";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageCms = () => {
  const userData = useAuth();
  const translate = useTranslation();
  const [getAllCms] = useLazyGetCmsQuery();
  const [postCms, { isLoading }] = usePostCmsMutation();

  // const [privacyPolicy, setPrivacyPolicy] = useState<string>("");
  const [legal, setLegal] = useState<string>("");
  const [legalAr, setLegalAr] = useState<string>("");
  const [termsAndConditions, setTermsAndConditions] = useState<string>("");
  const [termsAndConditionsAr, setTermsAndConditionsAr] = useState<string>("");
  const [privacyPolicy, setPrivacyPolicy] = useState<string>("");
  const [privacyPolicyAr, setPrivacyPolicyAr] = useState<string>("");

  const [value, setValue] = React.useState(0);
  const [cmsId, setCmsId] = useState<string>("");
  const [phoneCode, setPhoneCode] = useState("+91");
  const [error, setError] = useState(false);
  console.log(privacyPolicy?.length);
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });

  // const [value, setValue] = React.useState(0);

  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    setPhoneCode(country?.dialCode);
  };

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
      phone: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required(translate.error.emailReq)
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          translate.error.validEmail
        ),

      phone: Yup.string()
        .required(translate.error.phoneReq)
        .min(6, translate.error.minPhone),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      let hasError = false;

      if (
        value === 1 &&
        (!privacyPolicy.trim() || privacyPolicy.trim().length < 8)
      ) {
        setError(true);
        hasError = true;
      } else if (
        value === 1 &&
        (!privacyPolicyAr.trim() || privacyPolicyAr.trim().length < 8)
      ) {
        setError(true);
        hasError = true;
      } else if (value === 2 && (!legal.trim() || legal.trim().length < 8)) {
        setError(true);
        hasError = true;
      } else if (
        value === 2 &&
        (!legalAr.trim() || legalAr.trim().length < 8)
      ) {
        setError(true);
        hasError = true;
      } else if (
        value === 3 &&
        (!termsAndConditions.trim() || termsAndConditions.trim().length < 8)
      ) {
        setError(true);
        hasError = true;
      } else if (
        value === 3 &&
        (!termsAndConditionsAr.trim() || termsAndConditionsAr.trim().length < 8)
      ) {
        setError(true);
        hasError = true;
      } else {
        setError(false);
      }

      if (hasError) {
        // If there is an error, don't proceed with form submission
        formik.setSubmitting(false);
        return;
      }

      const body = {
        email: formik.values.email,
        phone: formik.values.phone,
        privacyPolicy: privacyPolicy,
        privacyPolicy_ar: privacyPolicyAr,
        legal: legal,
        legal_ar: legalAr,
        countryCode: phoneCode,
        termsAndConditions: termsAndConditions,
        termsAndConditions_ar: termsAndConditionsAr,
      };

      try {
        const response = await postCms({ body }).unwrap();

        if (response?.statusCode === 200) {
          showToast(translate.error.cmsUpdate);
        }
      } catch (error: any) {
        console.log(error, "error");
        showError(error?.data?.message || "");
      }
    },
  });

  const getCmsDetail = async () => {
    try {
      const res = await getAllCms({}).unwrap();
      if (res?.statusCode === 200 && res?.data) {
        formik.setFieldValue("email", res?.data[0]?.email);
        formik.setFieldValue("phone", res?.data[0]?.phone);
        setPrivacyPolicy(res?.data[0]?.privacyPolicy || " ");
        setPrivacyPolicyAr(res?.data[0]?.privacyPolicy_ar || " ");
        setLegal(res?.data[0]?.legal);
        setLegalAr(res?.data[0]?.legal_ar);
        setTermsAndConditions(res?.data[0]?.termsAndConditions);
        setTermsAndConditionsAr(res?.data[0]?.termsAndConditions_ar);
        setCmsId(res?.data[0]?._id);
        setPhoneCode(res?.data[0]?.countryCode || "");
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const checkPermission = () => {
    const permissions = userData?.permission;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex((ele: any) => ele?.name === "Manage CMS");

      if (idx != -1) {
        let data = {
          isAdd: permissions[idx]?.isAdd,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);

  useEffect(() => {
    getCmsDetail();
  }, []);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translate.sideBar.cms}</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label={translate.main.contactSupport} {...a11yProps(0)} />
              <Tab label={translate.main.privacyPolicy} {...a11yProps(1)} />
              <Tab label={translate.main.aboutUs} {...a11yProps(2)} />
              <Tab label={translate.main.terms} {...a11yProps(3)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translate.auth.email}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"email"}
                      name="email"
                      variant="outlined"
                      fullWidth
                      className="text_field"
                      placeholder={translate.auth.email}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      inputProps={{ maxLength: 50 }}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translate.auth.phone}
                    </Typography>
                    <PhoneInput
                      country={"us"}
                      value={phoneCode + formik.values.phone}
                      placeholder="0 (000) 000-000"
                      enableSearch={true}
                      inputStyle={{ width: "100%" }}
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <h6 className="err_msg">
                        {formik.touched.phone && formik.errors.phone}
                      </h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
                {hidePermission?.isAdd ? (
                  <div className="form_btn">
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                    >
                      {translate.globals.save}
                    </Button>
                  </div>
                ) : null}
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <form onSubmit={formik.handleSubmit}>
                <h3>{`${translate.main.privacyPolicy} ${translate.globals.english}`}</h3>
                <EditText
                  content={privacyPolicy}
                  setContent={setPrivacyPolicy}
                />
                {error ? (
                  <h6 className="err_msg">{`${translate.error.pleaseEnter} ${translate.main.privacyPolicy}`}</h6>
                ) : (
                  ""
                )}

                <h3>{`${translate.main.privacyPolicy} ${translate.globals.arabic}`}</h3>

                <EditText
                  content={privacyPolicyAr}
                  setContent={setPrivacyPolicyAr}
                />
                {error ? (
                  <h6 className="err_msg">{`${translate.error.pleaseEnter} ${translate.main.privacyPolicy}`}</h6>
                ) : (
                  ""
                )}
                {hidePermission?.isAdd ? (
                  <div className="form_btn">
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                    >
                      {translate.globals.save}
                    </Button>
                  </div>
                ) : null}
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <form onSubmit={formik.handleSubmit}>
                <h3>{`${translate.main.aboutUs} ${translate.globals.english}`}</h3>
                <EditText content={legal} setContent={setLegal} />
                {error ? (
                  <h6 className="err_msg">{`${translate.error.pleaseEnter} ${translate.main.aboutUs}`}</h6>
                ) : (
                  ""
                )}

                <h3>{`${translate.main.aboutUs} ${translate.globals.arabic}`}</h3>
                <EditText content={legalAr} setContent={setLegalAr} />
                {error ? (
                  <h6 className="err_msg">{`${translate.error.pleaseEnter} ${translate.main.aboutUs}`}</h6>
                ) : (
                  ""
                )}

                {hidePermission?.isAdd ? (
                  <div className="form_btn">
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                    >
                      {translate.globals.save}
                    </Button>
                  </div>
                ) : null}
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <form onSubmit={formik.handleSubmit}>
                <h3>{`${translate.main.terms} ${translate.globals.english}`}</h3>
                <EditText
                  content={termsAndConditions}
                  setContent={setTermsAndConditions}
                />
                {error ? (
                  <h6 className="err_msg">{`${translate.error.pleaseEnter} ${translate.main.terms}`}</h6>
                ) : (
                  ""
                )}

                <h3>{`${translate.main.terms} ${translate.globals.arabic}`}</h3>
                <EditText
                  content={termsAndConditionsAr}
                  setContent={setTermsAndConditionsAr}
                />
                {error ? (
                  <h6 className="err_msg">{`${translate.error.pleaseEnter} ${translate.main.terms}`}</h6>
                ) : (
                  ""
                )}

                {hidePermission?.isAdd ? (
                  <div className="form_btn">
                    <Button
                      size="large"
                      type="submit"
                      className="btn btn_primary"
                    >
                      {translate.globals.save}
                    </Button>
                  </div>
                ) : null}
              </form>
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageCms;
