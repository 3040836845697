import { string } from "yup";
import { END_POINTS } from "../constants/url";
import { Discount } from "../types/General";

import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
  totalPages: number;
};

type getMerchantParams = {
  merchantType: number;
  page: number;
  query: string;
};

type CommonParams = {
  page: number;
  query: string;
  type: number;
};

type UpdateRequestParams = {
  merchantId: string | undefined;
  body: {
    profileStatus: number;
  };
};

type AddDiscountBody = {
  code: string;
  courses: any[];
  discountPercentage: string;
  discountType: number;
  valideFrom: string;
  valideTill: string;
};

type AddPlanDiscountBody = {
  code: string;
  subscriptions: any[];
  discountPercentage: string;
  discountType: number;
  valideFrom: string;
  valideTill: string;
};

type updateMerchantParams = {
  discountId: string;
  body: {
    code: string;
    courses: any[];
    discountPercentage: string;
    discountType: number;
    valideFrom: string;
    valideTill: string;
  };
};

type updateDiscountParams = {
  discountId: string;
  body: {
    code: string;
    subscriptions: any[];
    discountPercentage: string;
    discountType: number;
    valideFrom: string;
    valideTill: string;
  };
};

export const discountApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getDiscountCoupons: builder.query<
      CommonResponseType & {
        data: {
          count: number;
          data: Discount[];
        };
      },
      CommonParams
    >({
      query: ({ page, query, type }) => ({
        url: `${END_POINTS.discount}?search=${query}&page=${page}&discountType=${type}`,
        method: "GET",
      }),
    }),

    deleteDiscount: builder.mutation<
      CommonResponseType,
      { discountId: string }
    >({
      query: ({ discountId }) => ({
        url: `${END_POINTS.discount}/${discountId}`,
        method: "DELETE",
      }),
    }),

    addDiscount: builder.mutation<
      { message: string; statusCode: number; data: any },
      AddDiscountBody
    >({
      query: (body) => ({
        url: END_POINTS.discount,
        method: "POST",
        body,
      }),
    }),

    addPlanDiscount: builder.mutation<
      { message: string; statusCode: number; data: any },
      AddPlanDiscountBody
    >({
      query: (body) => ({
        url: END_POINTS.discount,
        method: "POST",
        body,
      }),
    }),

    updateDiscount: builder.mutation<CommonResponseType, updateMerchantParams>({
      query: ({ body, discountId }) => ({
        url: `${END_POINTS.discount}/${discountId}`,
        method: "PUT",
        body,
      }),
    }),

    updatePlanDiscount: builder.mutation<
      CommonResponseType,
      updateDiscountParams
    >({
      query: ({ body, discountId }) => ({
        url: `${END_POINTS.discount}/${discountId}`,
        method: "PUT",
        body,
      }),
    }),

    getDiscountById: builder.query<
      CommonResponseType & { data: any },
      { discountId: string | undefined }
    >({
      query: ({ discountId }) => ({
        url: `${END_POINTS.discount}/${discountId}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetDiscountCouponsQuery,

  useAddDiscountMutation,
  useUpdateDiscountMutation,
  useDeleteDiscountMutation,
  useLazyGetDiscountByIdQuery,
  useAddPlanDiscountMutation,
  useUpdatePlanDiscountMutation,
} = discountApi;
