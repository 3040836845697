export const ar = {
  globals: {
    save: "يحفظ",
    chefs: "الطهاة",
    photographers: "المصورين",
    submit: "يُقدِّم",
    back: "خلف",
    select: "يختار",
    serialNo: "رقم S",
    title: "عنوان",
    description: "وصف",
    price: "سعر",
    status: "حالة",
    actions: "أجراءات",
    free: "حر",
    yes: "نعم",
    no: "لا",
    found: "وجد",
    edit: "يحرر",
    delete: "يمسح",
    add: "يضيف",
    view: "منظر",
    paid: "مدفوع",
    english: "(English)",
    arabic: "(العربية)",
    searchHere: "ابحث هنا",
    image: "صورة",
    export: "تصدير CSV",
    areYouSure: "هل أنت متأكد من حذف هذا",
    name: "اسم",
    request: "طلب",
    remove: "يزيل",
    asterisk: "*",
  },
  auth: {
    emailId: "عنوان البريد الإلكتروني",
    password: "كلمة المرور",
    remember: "تذكرنى",
    forgotPassword: "هل نسيت كلمة السر",
    oldPass: "كلمة المرور القديمة",
    confmPas: "تأكيد كلمة المرور",
    otpVerification: "التحقق من كلمة المرور لمرة واحدة",
    verify: "التحقق من كلمة المرور لمرة واحدة",
    resendOtp: "إعادة إرسال كلمة المرور",
    login: "تسجيل الدخول",
    resetPass: "إعادة تعيين كلمة المرور",
    editProfile: "تعديل الملف الشخصي",
    firstName: "الاسم الأول",
    lastName: "اسم العائلة",
    email: "بريد إلكتروني",
    phone: "رقم التليفون",
    nickName: "كنية",
    myProfile: "ملفي",
    changePass: "تغيير كلمة المرور",
    logout: "تسجيل خروج",
    profileStatus: "حالة الملف الشخصي",
    newPass: "كلمة المرور الجديدة",
  },
  dashboard: {
    totalChefs: "مجموع الطهاة",
    totalPhoto: "مجموع المصورين",
    totalPurchase: "إجمالي المشتريات",
    totalCourses: "إجمالي الدورات",
    TotalRevenue: "إجمالي الإيرادات",
    customers: "إجمالي العملاء",
    daily: "يوميًا",
    weekly: "أسبوعي",
    monthly: "شهريا",
    yearly: "سنوي",
    revenue: "ربح",
  },
  main: {
    image: "صورة",
    addCategory: "إضافة فئة",
    editCategory: "تحرير الفئة",
    selectCat: "اختر الفئة",
    catName: "اسم التصنيف",
    bio: "السيرة الذاتية",
    headline: "العنوان",
    address: "عنوان",
    profession: "اختر المهنة",
    acctDetails: "تفاصيل الحساب",
    acctNo: "رقم مكيف الهواء",
    holder: "اسم الحامل",
    swift: "رمز السرعة",
    bank: "اسم البنك",
    addSubAdmin: "إضافة مشرف فرعي",
    module: "وحدة",
    addEdit: "إضافة/تحرير",
    accountStatus: "حالة الحساب",
    viewUser: "عرض العميل",
    editCustomer: "تحرير العميل",
    uploadLimit: "إدارة حد التحميل",
    videoSize: "حجم الفيديو (ميجابايت)",
    videoLength: "مدة الفيديو (بالدقائق)",
    tax: "ضريبة الخدمة",
    commission: "عمولة",
    fileFormat: "تنسيق الملف",
    question: "سؤال",
    answer: "إجابة",
    contactSupport: "اتصل بالدعم",
    privacyPolicy: "سياسة الخصوصية",
    aboutUs: "معلومات عنا",
    terms: "الأحكام والشروط",
    changeLang: "تغيير اللغة",
    notification: "إشعارات",
    showing: "عرض",
    items: "أغراض",
    dob: "تاريخ الميلاد",
    document: "تحميل الوثيقة",
    active: "نشيط",
    Inactive: "غير نشط",
    uploadedCourse: "الدورات المرفوعة",
    coupon: "كوبون الخصم",
    validFrom: "صالح من تاريخ",
    validTill: "صالح حتى",
    subscription: "الاشتراك",
    videoLimit: "حدود مقاطع الفيديو المجانية",
    guestUser: "المستخدمون الضيوف",
    nonSubUser: "المستخدمون غير المشتركين",
    message: "رسالة",
    receiver: "المتلقي",
    broadcast: "إذاعة",
    pending: "قيد الانتظار",
    accepted: "قبلت",
    accept: "يقبل",
    rejected: "مرفوض",
    reject: "يرفض",
    subsReq: "الاشتراك المطلوبة",
    atLeast1Feature: "يجب تحديد ميزة واحدة على الأقل",
    loginToCooklify: "قم بتسجيل الدخول إلى مسؤول Cooklify",
  },
  sideBar: {
    dashboard: "لوحة القيادة",
    subAdmin: "المشرف الفرعي",
    customer: "عملاء",
    category: "فئات",
    plans: "خطط الاشتراك",
    merchants: "الطهاة / المصورين",
    courses: "الدورات",
    freeVideos: "فيديوهات مجانية",
    promoVideos: "فيديوهات ترويجية",
    coupons: "كوبونات خصم",
    orders: "طلبات",
    noti: "إشعارات",
    reports: "التقارير والتحليلات",
    review: "التعليقات",
    cms: "نظام إدارة المحتوى",
    faqs: "الأسئلة الشائعة",
    setting: "إعدادات",
  },
  course: {
    courseName: "اسم الدورة التدريبية",
    addCourse: "إضافة دورة",
    editCourse: "تحرير الدورة",
    courseTitle: "عنوان الدورة",
    category: "اختر الفئة",
    type: "نوع التسعير",
    price: "سعر",
    merchant: "حدد المؤلف/الناشر",
    discount: "تخفيض (٪)",

    cover: "صورة الغلاف",
    addVideo: "أضف فيديو",
    upload: "رفع",
    title_en: "Title ( English )",
    title_ar: "Title ( Arabic )",
    addMore: "أضف المزيد",
    clear: "مرشح واضح",
    selectRating: "حدد التقييم",
    lowToHigh: "من أسفل إلى أعلى",
    highToLow: "من الأعلى إلى الأقل",
    priceRange: "حدد نطاق السعر",
    merchantName: "اسم التاجر",
    ratings: "التقييمات",
    courseDetail: "تفاصيل الدورة",
    cat: "فئة",
    videos: "أشرطة فيديو",
    courses: "الدورات",
  },
  error: {
    required: "هذه الخانة مطلوبه",
    imageSize: "الرجاء تحديد ملف فيديو أصغر من",
    imageType: "الرجاء تحديد نوع ملف فيديو مدعوم",
    imageValidate: "الرجاء تحديد فيديو أقصر من",
    minutes: "دقائق",
    validation1: "الرجاء تحديد فيديو صالح",
    maxValidation: "الحد الأقصى المسموح به هو 80 حرفًا",
    minValidation: "مطلوب 3 أحرف على الأقل",
    noData: "لم يتم العثور على نتائج",
    titlereq: "العنوان مطلوب",
    descReq: "الوصف مطلوب",
    lessThan: "يرجى تحديد فيديو أقل من",
    noFaq: "لم يتم العثور على قائمة الأسئلة الشائعة",
    pleaseEnter: "Please enter",
    emailReq: "البريد الالكتروني مطلوب!",
    validEmail: "أدخل عنوان بريد إلكتروني صالح!",
    firstReq: "الإسم الأول مطلوب",
    nicReq: "الاسم المستعار مطلوب",
    lastReq: "إسم العائلة مطلوب",
    phoneReq: "رقم الهاتف مطلوب",
    minPhone: "يجب أن يتكون رقم الهاتف من 6 أرقام على الأقل",
    maxPhone: "يجب أن يتكون رقم الهاتف من 16 رقمًا على الأقل",
    subAdminCreated: "تم إنشاء المشرف الفرعي بنجاح",
    subAdminUpdate: "تم تحديث المشرف الفرعي بنجاح",
    userUpdate: "تم تحديث المستخدم بنجاح",
    statusUpdate: "تم تحديث الحالة بنجاح",
    subAdminDel: "تم حذف المشرف الفرعي بنجاح",
    userDel: "تم حذف المستخدم بنجاح",
    max100: "الحد الأقصى المسموح به هو 100 حرف",
    max250: "الحد الأقصى المسموح به هو 250 حرفًا",
    max500: "الحد الأقصى المسموح به هو 500 حرف",
    catAdd: "تمت إضافة الفئة بنجاح",
    catUpdate: "تم تحديث الفئة بنجاح",
    vdoSize: "الرجاء إدخال حجم الفيديو",
    vdoLength: "الرجاء إدخال مدة الفيديو",
    settingUpdate: "تم تحديث الإعدادات بنجاح",
    frAllUser: "لكل المستخدمين",
    frAllMerchant: "لجميع التجار",
    faqAdd: "تمت إضافة الأسئلة الشائعة بنجاح",
    faqDel: "تم حذف الأسئلة الشائعة بنجاح",
    cmsUpdate: "تم تحديث نظام إدارة المحتوى بنجاح",
    messageReq: "الرسالة مطلوبة",
    notiAdd: "تمت إضافة الإخطار بنجاح",
    notiDel: "تم حذف الإشعار بنجاح",
    discountAdd: "تمت إضافة الخصم بنجاح",
    discountUpdated: "تم تحديث الخصم بنجاح",
    merchantDel: "تم حذف التاجر بنجاح",
    merchantAdd: "تمت إضافة التاجر بنجاح",
    merchantUpdate: "تم تحديث التاجر بنجاح",
    logutReq: "هل أنت متأكد أنك تريد تسجيل الخروج؟",
    courseUpdate: "تم تحديث الدورة بنجاح",
    courseAdd: "تمت إضافة الدورة بنجاح",
    courseDel: "تم حذف الدورة بنجاح",
  },
  orders: {
    order: "طلبات",
    userName: "اسم المستخدم",
    userEmail: "البريد الالكتروني للمستخدم",
    courseName: "عنوان الدورة",
    coursePrice: "سعر الدورة",
    date: "تاريخ",
    ratingReview: "المراجعة والتقييمات",
    ratings: "التقييمات",
    chefsName: "اسماء الطهاة",
    photoName: "اسماء المصورين",
    orderDetails: "تفاصيل الطلب",
    review: "مراجعة",
    subTotal: "المجموع الفرعي",
    taxAmount: "قيمة الضريبة",
    grandTotal: "المجموع الإجمالي",
    paymentDetails: "بيانات الدفع",
    paymentMode: "طريقة الدفع",
    paymentDate: "تاريخ الدفع",
    expiry: "تاريخ الانتهاء",
  },
  videos: {
    addProVideos: "إضافة فيديو ترويجي",
    vdo: "فيديو",
    promotional: "الترويجية",
    uploadVideo: "تحميل أشرطة الفيديو",
    proUploaded: "تم تحديث الفيديو الترويجي بنجاح",
    proAdd: "تم إنشاء الفيديو الترويجي بنجاح",
    vdoAdd: "تم إنشاء الفيديو بنجاح",
    vdoUpdated: "تم تحديث الفيديو بنجاح",
    UploadedBy: "تم الرفع بواسطة",
    vdoDlt: "تم حذف الفيديو بنجاح",
  },
  plans: {
    planName: "اسم الخطة",
    planValid: "صلاحية الخطة",
    planPrice: "سعر الخطة",
    planType: "حدد نوع الخطة",
    userType: "حدد نوع المستخدم",
    feature: "ميزة",
    planDetails: "تفاصيل الخطة",
    quarterly: "ربعي",
    customers: "عملاء",
    chefPhoto: "الطهاة / المصورين",
    food: "طعام",
    photoShoot: "إلتقاط صورة",
    both: "كلاهما",
    planFeature: "ميزات الخطة",
    plans: "الخطط",
  },
};
