import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Rating,
  Tooltip,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Grid,
  Checkbox,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  useLazyGetAllCoursesQuery,
  useDeleteCourseMutation,
  useChangeCourseStatusMutation,
  useLazyGetCourseCsvQuery,
  useFeatureCourseMutation,
} from "../../services/courses";

import {
  getFromStorage,
  Loader,
  showError,
  showToast,
  STORAGE_KEYS,
} from "../../constants";
import Pagination from "../../components/Pagination";
import { isValidInput } from "../../utils/validations";
import WarnModal from "../../components/WarnModal";
import useAuth from "../../hooks/useAuth";
import { useLazyGetAllCategoryQuery } from "../../services/main";
import { Courses } from "../../types/General";
import useTranslation from "../../hooks/Translation";

const ManageCourses = () => {
  const userData = useAuth();
  const navigate = useNavigate();
  const translate = useTranslation();
  const getLang = getFromStorage(STORAGE_KEYS.language);
  const [getAllCourses, { isLoading }] = useLazyGetAllCoursesQuery();
  const [courseCsv] = useLazyGetCourseCsvQuery();
  const [allCategory] = useLazyGetAllCategoryQuery();
  const [deleteCourse] = useDeleteCourseMutation();
  const [courseStatus] = useChangeCourseStatusMutation();
  const [featured] = useFeatureCourseMutation();

  const [value, setValue] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedId, setSelectedId] = useState<string>("");
  const [page, setPage] = useState(1);
  const [courseData, setCourseData] = useState<Courses[]>([]);
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });
  const [categories, setCategories] = useState<any>();
  const [category, setCategory] = useState("");
  const [rating, setRating] = useState("");
  const [price, setPrice] = useState("");

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };

  const handleRatingChange = (event: SelectChangeEvent) => {
    setRating(event.target.value as string);
    setPrice("");
  };

  const handlePriceChange = (event: SelectChangeEvent) => {
    setPrice(event.target.value as string);
    setRating("");
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleClearFilters = () => {
    setRating("");
    setPrice("");
    setCategory("");
  };

  let totalPages = Math.ceil(totalCount / 10);

  const getCourses = async () => {
    try {
      const response = await getAllCourses({
        query: debouncedSearchTerm.trim(),
        page: page,
        category: category,
        rating: rating,
        price: price,
      }).unwrap();
      if (response?.statusCode === 200) {
        setCourseData(response?.data?.courses);
        setTotalCount(response?.data?.count);
      } else {
        setCourseData([]);
      }
    } catch (error: any) {
      console.log(error?.data?.message || "");
      setCourseData([]);
    }
  };

  const updateStatus = async (id: string, isBlocked: boolean | undefined) => {
    const body = {
      isBlocked: isBlocked == true ? false : true,
    };
    try {
      const response = await courseStatus({ courseId: id, body }).unwrap();
      if (response?.statusCode === 200) {
        showToast(translate.error.statusUpdate);
        getCourses();
      } else {
        showError("Please try again");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const featureCourse = async (id: string, isFeatured: boolean | undefined) => {
    const body = {
      isFeatured: isFeatured == true ? false : true,
    };
    try {
      const response = await featured({ courseId: id, body }).unwrap();
      if (response?.statusCode === 200) {
        showToast(translate.error.statusUpdate);
        getCourses();
      } else {
        showError("Please try again");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteCourse({ courseId: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast(translate.error.courseDel);
        getCourses();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const checkPermission = () => {
    const permissions = userData?.permission;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.name === "Manage Courses"
      );

      if (idx != -1) {
        let data = {
          isAdd: permissions[idx]?.isAdd,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };

  const getCourseCsv = async () => {
    try {
      const response = await courseCsv({
        query: debouncedSearchTerm.trim(),
        category: category,
        rating: rating,
        price: price,
      }).unwrap();
      if (response?.statusCode === 200) {
        window?.open(response?.data);
      } else {
        console.log("error");
      }
    } catch (error: any) {
      console.log(error?.data?.message || "");
    }
  };

  const getAllCate = async () => {
    try {
      const response = await allCategory({
        query: "",
        page: 1,
      }).unwrap();
      if (response?.statusCode === 200) {
        setCategories(response?.data?.req || []);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getAllCate();
  }, []);

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);

  useEffect(() => {
    getCourses();
  }, [page, debouncedSearchTerm, category, rating, price]);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translate?.sideBar?.courses}</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="cards_header">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  gap: 1,
                }}
              >
                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("/manage-courses/add")}
                >
                  {translate?.course?.addCourse}
                </Button>

                <Button className="btn btn_primary" onClick={getCourseCsv}>
                  <FileDownloadIcon /> {translate?.globals?.export}
                </Button>
              </Box>
            </Box>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: 5,
              }}
            >
              {category !== "" || rating !== "" || price !== "" ? (
                <Button className="btn" onClick={() => handleClearFilters()}>
                  {translate?.course?.clear}
                </Button>
              ) : null}
            </div>

            <Grid container spacing={2} sx={{ paddingTop: 1 }}>
              <Grid item lg={3} md={3} sm={6} xs={12}>
                <SearchBar
                  style={{ width: "100%" }}
                  value={searchTerm}
                  searchTerm={searchTerm}
                  onCross={() => setSearchTerm("")}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    className="select_div"
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={category}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    displayEmpty
                    onChange={handleCategoryChange}
                  >
                    <MenuItem value="" disabled>
                      {translate?.main?.selectCat}
                    </MenuItem>
                    {categories?.length
                      ? categories?.map((item: any) => (
                          <MenuItem key={item?._id} value={item?._id}>
                            {item?.name || ""}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    className="select_div"
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={rating}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    displayEmpty
                    onChange={handleRatingChange}
                  >
                    <MenuItem value="" disabled>
                      {translate?.course?.selectRating}
                    </MenuItem>
                    <MenuItem value="1">
                      {translate?.course?.lowToHigh}
                    </MenuItem>
                    <MenuItem value="-1">
                      {translate?.course?.highToLow}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item lg={3} md={3} sm={6} xs={12}>
                <FormControl sx={{ width: "100%" }}>
                  <Select
                    className="select_div"
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={price}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    displayEmpty
                    onChange={handlePriceChange}
                  >
                    <MenuItem value="" disabled>
                      {translate?.course?.priceRange}
                    </MenuItem>
                    <MenuItem value="1">
                      {translate?.course?.lowToHigh}
                    </MenuItem>
                    <MenuItem value="-1">
                      {translate?.course?.highToLow}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      {translate?.globals?.serialNo}
                    </TableCell>
                    <TableCell>{translate?.course?.courseName}</TableCell>
                    <TableCell>{translate?.globals?.price}</TableCell>
                    {/* <TableCell>Course Name</TableCell> */}
                    <TableCell>{translate?.course?.merchantName}</TableCell>
                    <TableCell>{translate?.course?.ratings}</TableCell>
                    <TableCell>{translate?.globals?.status}</TableCell>
                    <TableCell>Feature Course</TableCell>
                    <TableCell>{translate?.globals?.actions}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {courseData?.length > 0 ? (
                    courseData?.map((row, i) => (
                      <TableRow key={row._id}>
                        <TableCell align="center">{i + 1}</TableCell>
                        <TableCell>{row?.title || "-"}</TableCell>
                        <TableCell>
                          {`${row?.price ? "$" : ""} ${row?.price || "Free"}`}
                        </TableCell>
                        {/* <TableCell>{row?.course?.title || "-"}</TableCell> */}
                        <TableCell>{row?.merchant?.firstName || "-"}</TableCell>
                        <TableCell align="center">
                          <Rating
                            name="read-only"
                            value={
                              row?.ratings !== null ? row.ratings.toFixed(2) : 0
                            }
                            readOnly
                          />
                        </TableCell>

                        <TableCell>
                          <Switch
                            {...label}
                            defaultChecked
                            checked={!row?.isBlocked}
                            onChange={() =>
                              updateStatus(row?._id, row?.isBlocked)
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <Switch
                            {...label}
                            defaultChecked
                            checked={row?.isFeatured}
                            onChange={() =>
                              featureCourse(row?._id, row?.isFeatured)
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            <Tooltip title="View" placement="top">
                              <IconButton
                                onClick={() =>
                                  navigate(
                                    `/manage-courses/coursedetails/${row?._id}`
                                  )
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                            </Tooltip>

                            {hidePermission?.isAdd ? (
                              <Tooltip title="Edit" placement="top">
                                <IconButton
                                  onClick={() =>
                                    navigate(`/manage-courses/edit/${row?._id}`)
                                  }
                                >
                                  <ModeEditIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null}

                            {hidePermission?.isDelete ? (
                              <Tooltip title="Delete" placement="top">
                                <IconButton
                                  onClick={() => {
                                    setOpen(true);
                                    setSelectedId(row?._id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        {translate.error.noData}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Card>
        {courseData?.length > 0 ? (
          <Pagination
            setPage={setPage}
            module={courseData}
            page={page}
            onPageChange={onPageChange}
            totalPages={totalPages}
          />
        ) : (
          ""
        )}
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="course"
      />
    </MainContainer>
  );
};

export default ManageCourses;
