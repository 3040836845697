import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  SelectChangeEvent,
  Typography,
} from "@mui/material";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useAddSubscriptionMutation,
  useLazyGetSubscriptionByIdQuery,
  useUpdateSubscriptionMutation,
} from "../../services/subscription";
import { showError, showToast } from "../../constants";
import { isNumber, isString } from "../../utils/validations";
import { Discount } from "../../types/General";
import useTranslation from "../../hooks/Translation";

const AddSubscriptionPlan = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const translate = useTranslation();
  const { state } = location;
  const [subscriptionById] = useLazyGetSubscriptionByIdQuery();
  const [addSubscription] = useAddSubscriptionMutation();
  const [updateSubscription] = useUpdateSubscriptionMutation();
  const [subsData, setSubsData] = useState<Discount>();
  const [receiver, setReceiver] = useState<string>("All");
  const [validity, setValidity] = useState("");
  const [planType, setPlanType] = useState("");
  const [userType, setUserType] = useState("1");
  const [selectedPlan, setSelectedPlan] = useState<number[]>([]);

  const [error, setError] = useState(false);

  console.log(selectedPlan);
  const handleReceiverChange = (event: SelectChangeEvent) => {
    setReceiver(event.target.value as string);
  };

  const handleValidityChange = (event: SelectChangeEvent) => {
    setValidity(event.target.value as string);
  };

  const handlePlanChange = (event: SelectChangeEvent) => {
    setPlanType(event.target.value as string);
  };

  const handleUserChange = (event: SelectChangeEvent) => {
    setUserType(event.target.value as string);
  };

  const handlePlansChange = (courseId: number) => {
    if (selectedPlan.includes(courseId)) {
      setSelectedPlan((prevSelected) =>
        prevSelected.filter((id) => id !== courseId)
      );
    } else {
      setSelectedPlan((prevSelected) => [...prevSelected, courseId]);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: subsData?.name || "",
      name_ar: subsData?.name_ar || "",
      price: subsData?.price || "",
      description: subsData?.description || "",
      description_ar: subsData?.description_ar || "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(translate.error.required)
        .max(100, translate.error.max100)
        .min(2, translate.error.minValidation),
      name_ar: Yup.string()
        .required(translate.error.required)
        .max(100, translate.error.max100)
        .min(2, translate.error.minValidation),
      price: Yup.string()
        .required(translate.error.required)
        .max(20, translate.error.maxValidation),

      description: Yup.string()
        .required(translate.error.required)
        .max(500, translate.error.max500)
        .min(2, translate.error.minValidation),
      description_ar: Yup.string()
        .required(translate.error.required)
        .max(500, translate.error.max500)
        .min(2, translate.error.minValidation),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      if (selectedPlan.length === 0) {
        showError(translate.main.atLeast1Feature);
        formik.setSubmitting(false);
        return;
      }

      const body = {
        features: selectedPlan,
        name: values.name,
        name_ar: values.name_ar,
        planType: Number(planType),
        price: values.price,
        userType: Number(userType),
        validity: Number(validity),
        description: values.description,
        description_ar: values.description_ar,
      };

      console.log(body);

      if (id && id !== "add") {
        try {
          const response = await updateSubscription({
            subsId: id,
            body,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast("Subscription updated successfully");
            navigate("/manage-subscription", {
              state: userType === "2" ? "merchant" : "",
            });
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      } else {
        try {
          const response = await addSubscription(body).unwrap();
          if (response?.statusCode === 200) {
            showToast("Subscription Added Successfully");
            formik.resetForm();
            navigate("/manage-subscription", {
              state: userType === "2" ? "merchant" : "",
            });
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  const getSubscriptionDetail = async (id: string | undefined) => {
    try {
      const res = await subscriptionById({ subId: id }).unwrap();
      if (res?.statusCode === 200) {
        setSubsData(res?.data);
        setValidity(JSON.stringify(res?.data?.validity));
        setPlanType(JSON.stringify(res?.data?.planType));
        setUserType(JSON.stringify(res?.data?.userType));
        setSelectedPlan(res.data?.features);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };
  const UserPlanFeatures = [
    {
      id: 1,
      name: "The users will get discounts on the courses",
      enable: true,
    },
    {
      id: 2,
      name: "Ability to view more free videos than other users",
      enable: true,
    },
  ];

  const MerchantPlanFeatures = [
    {
      id: 1,
      name: "Merchants can add Courses and Promotional videos",
      enable: true,
    },
  ];

  useEffect(() => {
    if (id && id !== "add") {
      getSubscriptionDetail(id);
    }
  }, []);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          {state?.type === "edit" ? (
            <h1 className="mn_hdng">Edit Subscription Plan</h1>
          ) : (
            <h1 className="mn_hdng">Add Subscription Plan</h1>
          )}
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-subscription");
            }}
          >
            {translate?.globals?.back}
          </Button>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {`${translate?.plans?.planName} ${translate?.globals?.english}`}
                    <span className="asterisk">
                      {translate.globals.asterisk}
                    </span>
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="name"
                    variant="outlined"
                    fullWidth
                    className="text_field"
                    placeholder={`${translate?.plans?.planName} ${translate?.globals?.english}`}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {`${translate?.plans?.planName} ${translate?.globals?.arabic}`}
                    <span className="asterisk">
                      {translate.globals.asterisk}
                    </span>
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="name_ar"
                    variant="outlined"
                    fullWidth
                    className="text_field"
                    placeholder={`${translate?.plans?.planName} ${translate?.globals?.arabic}`}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (val.target.value) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.name_ar}
                    helperText={formik.touched.name_ar && formik.errors.name_ar}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translate.plans.planValid}
                    <span className="asterisk">
                      {translate.globals.asterisk}
                    </span>
                  </Typography>
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      fullWidth
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={validity}
                      // multiple
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      onChange={handleValidityChange}
                    >
                      <MenuItem value="" disabled>
                        {translate.globals.select}
                      </MenuItem>
                      <MenuItem value="1">
                        {translate.dashboard.yearly}
                      </MenuItem>
                      <MenuItem value="2">{translate.plans.quarterly}</MenuItem>
                      <MenuItem value="3">
                        {translate.dashboard.monthly}
                      </MenuItem>
                    </Select>
                    {error && !validity ? (
                      <h6 className="err_msg">Please select Validity</h6>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translate.plans.planPrice}
                    <span className="asterisk">
                      {translate.globals.asterisk}
                    </span>
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="price"
                    variant="outlined"
                    fullWidth
                    className="text_field"
                    placeholder="Plan Price"
                    onChange={(val) => {
                      const inputValue = val.target.value;

                      // Check if the input is a valid number with up to 2 decimals
                      const isValidInput = /^(\d{1,10}(\.\d{0,2})?)?$/.test(
                        inputValue
                      );

                      if (isValidInput) {
                        // Update the form field only if the input is valid
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.price}
                    helperText={formik.touched.price && formik.errors.price}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translate.plans.userType}
                    <span className="asterisk">
                      {translate.globals.asterisk}
                    </span>
                  </Typography>

                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      fullWidth
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={userType}
                      // multiple
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      onChange={handleUserChange}
                    >
                      <MenuItem value="" disabled>
                        {translate.globals.select}
                      </MenuItem>
                      <MenuItem value="1">{translate.plans.customers}</MenuItem>
                      <MenuItem value="2">{translate.plans.chefPhoto}</MenuItem>
                    </Select>
                    {error && !userType ? (
                      <h6 className="err_msg">Please select User Type</h6>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translate.plans.planType}
                    <span className="asterisk">
                      {translate.globals.asterisk}
                    </span>
                  </Typography>

                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      fullWidth
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={planType}
                      // multiple
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      onChange={handlePlanChange}
                    >
                      <MenuItem value="" disabled>
                        {translate.globals.select}
                      </MenuItem>
                      <MenuItem value="1">{translate.plans.food}</MenuItem>
                      <MenuItem value="2">
                        {translate.plans.photoShoot}
                      </MenuItem>
                      {userType !== "2" ? (
                        <MenuItem value="3">{translate.plans.both}</MenuItem>
                      ) : (
                        ""
                      )}
                    </Select>
                    {error && !planType ? (
                      <h6 className="err_msg">Please select Plan Type</h6>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {`${translate?.globals?.description} ${translate?.globals?.english}`}
                    <span className="asterisk">
                      {translate.globals.asterisk}
                    </span>
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    className="text_field"
                    name="description"
                    fullWidth
                    multiline
                    placeholder={`${translate?.globals?.description} ${translate?.globals?.english}`}
                    minRows={4}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (isString(val.target.value)) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {`${translate?.globals?.description} ${translate?.globals?.arabic}`}
                    <span className="asterisk">
                      {translate.globals.asterisk}
                    </span>
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    className="text_field"
                    name="description_ar"
                    fullWidth
                    multiline
                    placeholder={`${translate?.globals?.description} ${translate?.globals?.arabic}`}
                    minRows={4}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else if (val.target.value) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.description_ar}
                    helperText={
                      formik.touched.description_ar &&
                      formik.errors.description_ar
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      component="h2"
                      sx={{
                        fontSize: "20px",
                        color: "#051140",
                        fontWeight: 700,
                      }}
                    >
                      {translate.plans.planFeature}
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <TableContainer
                    className="table_container"
                    sx={{ margin: 0 }}
                  >
                    <Box className="heading"></Box>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">
                            {translate.globals.serialNo}
                          </TableCell>
                          <TableCell>{translate.plans.feature}</TableCell>
                          <TableCell> {translate.globals.status}</TableCell>
                          {/* <TableCell>Actions</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {userType === "1"
                          ? UserPlanFeatures.map((row, i) => (
                              <TableRow key={i}>
                                <TableCell align="center">{i + 1}</TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>
                                  <Switch
                                    {...label}
                                    checked={selectedPlan.includes(row?.id)}
                                    size="small"
                                    onChange={() => handlePlansChange(row?.id)}
                                  />
                                </TableCell>
                              </TableRow>
                            ))
                          : MerchantPlanFeatures.map((row, i) => (
                              <TableRow key={i}>
                                <TableCell align="center">{i + 1}</TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>
                                  <Switch
                                    {...label}
                                    checked={selectedPlan.includes(row?.id)}
                                    size="small"
                                    onChange={() => handlePlansChange(row?.id)}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
              <div className="form_btn">
                <Button
                  size="large"
                  type="submit"
                  className="btn btn_primary"
                  onClick={() => setError(true)}
                >
                  {translate.globals.save}
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default AddSubscriptionPlan;
