import { PropsWithoutRef, useEffect, useState } from "react";
import {
  Drawer,
  Toolbar,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Box,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import SummarizeTwoToneIcon from "@mui/icons-material/SummarizeTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import DiscountIcon from "@mui/icons-material/Discount";
import BarChartIcon from "@mui/icons-material/BarChart";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SettingsIcon from "@mui/icons-material/Settings";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import RateReviewIcon from "@mui/icons-material/RateReview";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import PsychologyIcon from "@mui/icons-material/Psychology";
import { SIDEBAR_WIDTH } from "../constants";
import QuizIcon from "@mui/icons-material/Quiz";
import SavingsIcon from "@mui/icons-material/Savings";
import VideoSettingsIcon from "@mui/icons-material/VideoSettings";
import BookIcon from "@mui/icons-material/Book";
import MovieFilterIcon from "@mui/icons-material/MovieFilter";
import useAuth from "../hooks/useAuth";
import useTranslation from "../hooks/Translation";

interface Props {
  mobileOpen: boolean;
  handleSidebarToggle: () => void;
}

export default function Sidebar({
  mobileOpen,
  handleSidebarToggle,
}: PropsWithoutRef<Props>) {
  const userData = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const translation = useTranslation();

  const items = [
    {
      name: translation.sideBar.dashboard,
      icon: <DashboardIcon />,
      key: "1",
      selected: "/dashboard",
    },
    {
      name: translation?.sideBar?.subAdmin,
      icon: <PersonAddIcon />,
      key: "2",
      selected: "/manage-subAdmin",
    },
    {
      name: translation?.sideBar?.customer,
      icon: <PeopleIcon />,
      key: "3",
      selected: "/manageusers",
    },
    {
      name: translation?.sideBar?.plans,
      icon: <SubscriptionsIcon />,
      key: "4",
      selected: "/manage-subscription",
    },
    {
      name: translation?.sideBar?.category,
      icon: <ListAltIcon />,
      key: "5",
      selected: "/manage-categories",
    },
    {
      name: translation?.sideBar?.merchants,
      icon: <CoPresentIcon />,
      key: "6",
      selected: "/manage-chefs/photographers",
    },
    {
      name: translation?.sideBar?.courses,
      icon: <VideoLibraryIcon />,
      key: "7",
      selected: "/manage-courses",
    },
    {
      name: translation?.sideBar?.freeVideos,
      icon: <VideoSettingsIcon />,
      key: "8",
      selected: "/manage-videos",
    },
    {
      name: translation?.sideBar?.promoVideos,
      icon: <MovieFilterIcon />,
      key: "9",
      selected: "/promotional-videos",
    },
    {
      name: translation?.sideBar?.coupons,
      icon: <DiscountIcon />,
      key: "10",
      selected: "/manage-discount-coupons",
    },
    // {
    //   name: "Manage Customer Support",
    //   icon: <LiveHelpIcon />,
    //   key: "10",
    //   selected: "/customer-support",
    // },
    {
      name: translation?.sideBar?.orders,
      icon: <BookIcon />,
      key: "11",
      selected: "/manage-orders",
    },
    {
      name: translation?.sideBar?.noti,
      icon: <NotificationsActiveIcon />,
      key: "12",
      selected: "/manage-notifications",
    },
    {
      name: translation?.sideBar?.reports,
      icon: <BarChartIcon />,
      key: "13",
      selected: "/analytics",
    },
    {
      name: translation?.sideBar?.review,
      icon: <RateReviewIcon />,
      key: "14",
      selected: "/manage-review",
    },
    {
      name: translation?.sideBar?.cms,
      icon: <SummarizeTwoToneIcon />,
      key: "15",
      selected: "/cms",
    },
    {
      name: translation?.sideBar?.faqs,
      icon: <QuizIcon />,
      key: "16",
      selected: "/faq",
    },
    {
      name: translation?.sideBar?.setting,
      icon: <SettingsIcon />,
      key: "17",
      selected: "/settings",
    },
  ];

  const [list, setList] = useState<any>(
    userData?.role === "ADMIN" ? items : userData?.permission
  );

  useEffect(() => {
    let arr = [];
    if (userData?.role === "ADMIN") {
      setList(items);
    } else if (userData?.permission?.length) {
      const permission = userData?.permission;
      arr = items.map((item) => {
        let idx = -1;
        idx = permission?.findIndex((ele: any) => ele?.name === item?.name);
        if (idx > -1) {
          if (permission[idx].isView) {
            return item;
          }
          return false;
        }
      });
      setList(arr);
    }
  }, [userData]);

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { lg: 0 } }}
      className="sidebar-main-div"
    >
      <Drawer
        className="sidebar_drawer"
        variant="temporary"
        open={mobileOpen}
        onClose={handleSidebarToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: SIDEBAR_WIDTH,
            right: 0,
          },
        }}
      >
        <Toolbar className="sidebar-logo" sx={{ position: "relative" }}>
          <figure>
            <img
              src="/static/images/logo.png"
              alt=""
              onClick={() => navigate("/dashboard")}
            />
          </figure>
          <IconButton
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "white",
              display: { lg: "none" },
            }}
            onClick={handleSidebarToggle}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        {list?.length
          ? list
              .filter((ele: any) => ele !== undefined)
              .map((item: any) => {
                const isSelected = location.pathname.includes(item?.selected);
                return (
                  <List key={item?.name} className="sidebr-lst">
                    <ListItemButton
                      className={`lst-itm ${isSelected ? "Mui-selected" : ""}`}
                      onClick={() => navigate(item?.selected)}
                    >
                      <ListItemIcon>{item?.icon}</ListItemIcon>
                      <ListItemText
                        className="lstitm-txt"
                        primary={item?.name}
                      />
                    </ListItemButton>
                  </List>
                );
              })
          : null}
      </Drawer>
      <Drawer
        variant="permanent"
        className="sidebar_drawer"
        sx={{
          display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": {
            width: SIDEBAR_WIDTH,
            boxSizing: "border-box",
            right: 0,
          },
        }}
        open
      >
        <Toolbar className="sidebar-logo" sx={{ position: "relative" }}>
          <figure>
            <img
              src="/static/images/logo.png"
              alt=""
              onClick={() => navigate("/dashboard")}
            />
          </figure>
          <IconButton
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "white",
              display: { lg: "none" },
            }}
            onClick={handleSidebarToggle}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        {list?.length
          ? list
              .filter((ele: any) => ele !== undefined)
              .map((item: any) => {
                const isSelected = location.pathname.includes(item?.selected);
                return (
                  <List key={item?.name} className="sidebr-lst">
                    <ListItemButton
                      className={`lst-itm ${isSelected ? "Mui-selected" : ""}`}
                      // className="lst-itm"
                      // selected={
                      //   location.pathname.includes(item.selected) === item.selected
                      // }
                      onClick={() => navigate(item?.selected)}
                    >
                      <ListItemIcon>{item?.icon}</ListItemIcon>
                      <ListItemText
                        className="lstitm-txt"
                        primary={item?.name}
                      />
                    </ListItemButton>
                  </List>
                );
              })
          : null}
        {/* {drawer} */}
      </Drawer>
    </Box>
  );
}
