import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { useLazyGetUserByIdQuery } from "../../../services/users";
import MainContainer from "../../../layout/MainContainer";
import "../Users.scss";
import { showError } from "../../../constants";
import { AllUser } from "../../../types/General";
import useTranslation from "../../../hooks/Translation";

const UserDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const translate = useTranslation();
  const [getUserData] = useLazyGetUserByIdQuery();
  const [useDetails, setUserDetails] = useState<AllUser>();
  const [allergies, setAllergies] = useState<AllUser[]>([]);

  const getUserDetailById = async (id: string | undefined) => {
    try {
      const response = await getUserData({ user_id: id }).unwrap();
      if (response?.statusCode === 200) {
        setUserDetails(response?.data);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getUserDetailById(id);
  }, []);

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translate?.main?.viewUser}</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manageUsers");
            }}
          >
            {translate?.globals?.back}
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item lg={2} md={2} sm={6} xs={12}>
                <figure className="profile_img">
                  <img
                    src={
                      useDetails?.image
                        ? useDetails?.image
                        : "/static/images/user_placeholder.png"
                    }
                    alt=""
                  />
                </figure>
              </Grid>
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translate?.globals?.name}
                      </Typography>
                      <Typography component="p">
                        {`${useDetails?.firstName || "-"} ${
                          useDetails?.lastName
                        } `}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translate?.auth?.phone}
                      </Typography>
                      <Typography component="p">{`${useDetails?.countryCode} ${
                        useDetails?.phone || "-"
                      }`}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translate?.auth?.email}
                      </Typography>
                      <Typography component="p">
                        {useDetails?.email || "-"}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translate?.main?.accountStatus}
                      </Typography>
                      <Typography component="p">
                        {useDetails?.isBlocked == false ? "Active" : "Inactive"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item mt={3} xs={12} className="view_box_list">
                    <Typography
                      className="detail_title mn_hdng"
                      component="h2"
                      mb={3}
                    >
                      {translate?.plans?.planDetails}
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">
                            {translate?.plans?.planName}
                          </Typography>
                          <Typography component="p">-</Typography>
                        </Box>
                      </Grid>

                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">
                            {translate?.plans?.planPrice}
                          </Typography>
                          <Typography component="p">-</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </MainContainer>
  );
};

export default UserDetails;
