import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  useLazyGetAllCategoryQuery,
  useChangeCategoryStatusMutation,
  useDeleteCategoryMutation,
} from "../../services/main";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  getFromStorage,
  Loader,
  showError,
  showToast,
  STORAGE_KEYS,
} from "../../constants";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";
import { isValidInput } from "../../utils/validations";
import useAuth from "../../hooks/useAuth";
import useTranslation from "../../hooks/Translation";

const ManageCategories = () => {
  const userData = useAuth();
  const navigate = useNavigate();
  const translate = useTranslation();
  const getLang = getFromStorage(STORAGE_KEYS.language);
  const [getAllCategoryQuery, { isLoading }] = useLazyGetAllCategoryQuery();
  const [categoryStatus] = useChangeCategoryStatusMutation();
  const [deleteCategory] = useDeleteCategoryMutation();

  const [open, setOpen] = useState<boolean>(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [categoryData, setCategoryData] = useState<any>();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedId, setSelectedId] = useState<string>("");
  const [page, setPage] = useState(1);
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });
  const [loading, setLoading] = useState(false);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  let totalPages = Math.ceil(totalCount / 10);

  const getCategories = async () => {
    try {
      const response = await getAllCategoryQuery({
        query: debouncedSearchTerm.trim(),
        page: page,
      }).unwrap();
      if (response?.statusCode === 200) {
        setCategoryData(response?.data?.req);
        setTotalCount(response?.data?.count);
      } else {
        setCategoryData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const updateStatus = async (id: string, isBlocked: boolean | undefined) => {
    const body = {
      isBlocked: isBlocked == true ? false : true,
    };
    try {
      const response = await categoryStatus({ categoryId: id, body }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Status updated successfully");
        getCategories();
      } else {
        showError("Please try again");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteCategory({ categoryId: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Category deleted successfully");
        getCategories();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const checkPermission = () => {
    const permissions = userData?.permission;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.name === "Manage Categories"
      );

      if (idx != -1) {
        let data = {
          isAdd: permissions[idx]?.isAdd,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };

  useEffect(() => {
    getCategories();
  }, [page, debouncedSearchTerm]);

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translate?.sideBar?.category}</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="cards_header">
              <SearchBar
                value={searchTerm}
                searchTerm={searchTerm}
                onCross={() => setSearchTerm("")}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />
              <Box className="cards_header_right">
                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("/manage-categories/add")}
                >
                  {translate?.main?.addCategory}
                </Button>
              </Box>
            </Box>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      {translate?.globals?.serialNo}
                    </TableCell>
                    <TableCell>{translate?.globals?.image}</TableCell>
                    <TableCell>{translate?.main?.catName}</TableCell>
                    <TableCell>{translate?.globals?.status}</TableCell>
                    <TableCell>{translate?.globals?.actions}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {categoryData?.length > 0 ? (
                    categoryData.map((row: any, i: any) => (
                      <TableRow>
                        <TableCell align="center">{i + 1}</TableCell>
                        <TableCell>
                          <figure className="user_img">
                            <img
                              src={
                                row?.image
                                  ? row?.image
                                  : "/static/images/image_placeholder.jpeg"
                              }
                              alt=""
                            />
                          </figure>
                        </TableCell>
                        <TableCell>{row?.name || "-"}</TableCell>
                        <TableCell>
                          <Switch
                            {...label}
                            defaultChecked
                            checked={!row?.isBlocked}
                            onChange={() =>
                              updateStatus(row?._id, row?.isBlocked)
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            {hidePermission?.isAdd ? (
                              <Tooltip
                                title={translate.globals.edit}
                                placement="top"
                              >
                                <IconButton
                                  onClick={() =>
                                    navigate(
                                      `/manage-categories/edit/${row?._id}`
                                    )
                                  }
                                >
                                  <ModeEditIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                            {hidePermission?.isDelete ? (
                              <Tooltip
                                title={translate.globals.delete}
                                placement="top"
                              >
                                <IconButton
                                  onClick={() => {
                                    setOpen(true);
                                    setSelectedId(row?._id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            ) : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        {translate?.error?.noData}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Card>
        <Pagination
          setPage={setPage}
          module={categoryData}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="Category"
      />
    </MainContainer>
  );
};

export default ManageCategories;
