import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Tabs,
  Tab,
  Typography,
  DialogContent,
  TextField,
  Dialog,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  Grid,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import { isValidInput } from "../../utils/validations";
import useAuth from "../../hooks/useAuth";
import DiscountCode from "./discountCode";
import {
  useChangeSubscriptionStatusMutation,
  useDeleteSubscriptionMutation,
  useLazyGetAllSubscriptionsQuery,
} from "../../services/subscription";
import {
  getFromStorage,
  Loader,
  showError,
  showToast,
  STORAGE_KEYS,
} from "../../constants";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";
import useTranslation from "../../hooks/Translation";

const ManageSubscription = () => {
  const navigate = useNavigate();
  const userData = useAuth();
  const translate = useTranslation();
  const [planType, setPlanType] = useState("");
  const getLang = getFromStorage(STORAGE_KEYS.language);
  const [deleteSubscription] = useDeleteSubscriptionMutation();
  const [getSubscriptionsData] = useLazyGetAllSubscriptionsQuery();
  const [subscriptionStatus] = useChangeSubscriptionStatusMutation();
  const [value, setValue] = useState<number>(0);
  console.log(value);
  const [value1, setValue1] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedId, setSelectedId] = useState<string>("");
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });
  const [validity, setValidity] = useState("");
  const [page, setPage] = useState(1);
  const [subsData, setSubsData] = useState<any>();
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const { state } = location;
  console.log(state, "PPPPPPPP");

  const handlePlanChange = (event: SelectChangeEvent) => {
    setPlanType(event.target.value as string);
  };
  const handleValidityChange = (event: SelectChangeEvent) => {
    setValidity(event.target.value as string);
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  let totalPages = Math.ceil(totalCount / 10);

  const getSubscriptions = async () => {
    setLoading(true);
    try {
      const response = await getSubscriptionsData({
        query: debouncedSearchTerm.trim(),
        page: page,
        userType: value === 0 ? 1 : 2,
        planType: Number(planType) || "",
        validity: Number(validity) || "",
      }).unwrap();
      if (response?.statusCode === 200) {
        setLoading(false);
        setSubsData(response?.data?.data);
        setTotalCount(response?.data?.count);
      } else {
        setSubsData([]);
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      showError(error?.data?.message || "");
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteSubscription({ subsId: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Plan deleted successfully");
        getSubscriptions();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const handleClose = () => {
    setOpen(false);
    // formik.setFieldError("message", "");
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function a11yProps1(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleChange1 = (event: React.SyntheticEvent, val: number) => {
    setValue1(val);
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const checkPermission = () => {
    const permissions = userData?.permission;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.name === "Manage Subscription Plans"
      );

      if (idx != -1) {
        let data = {
          isAdd: permissions[idx]?.isAdd,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };

  const handleClearFilters = () => {
    setValidity("");
    setPlanType("");
  };

  const updateStatus = async (id: string, state: boolean | undefined | any) => {
    const response = await subscriptionStatus({
      subsId: id,
      body: {
        isBlocked: !state,
      },
    }).unwrap();
    if (response?.statusCode === 200) {
      const updatedData = subsData.map((x: any) => {
        if (x._id === id) {
          return {
            ...x,
            isBlocked: !state,
          };
        }
        return x;
      });
      setSubsData(updatedData);
      showToast("Status updated successfully");
    } else {
      showError("Please try again");
    }
  };

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);

  useEffect(() => {
    getSubscriptions();
  }, [value, debouncedSearchTerm, page, planType, validity]);

  useEffect(() => {
    if (state == "discount") {
      setValue1(1);
    }
  }, []);

  useEffect(() => {
    if (state == "merchant") {
      setValue(1);
    }
  }, []);

  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translate.sideBar.plans}</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value1}
              onChange={handleChange1}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links1"
              // sx={{ mb: 4 }}
            >
              <Tab label={translate.plans.plans} {...a11yProps1(0)} />
              <Tab label={translate.main.coupon} {...a11yProps1(1)} />
            </Tabs>
            <Box className="cards_header">
              {value1 == 0 ? (
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    gap: 1,
                    pb: 4,
                    alignItems: "center",
                  }}
                >
                  <Grid container spacing={2} sx={{ paddingTop: 1 }}>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                      <SearchBar
                        style={{ width: "100%" }}
                        value={searchTerm}
                        searchTerm={searchTerm}
                        onCross={() => setSearchTerm("")}
                        setDebouncedSearchTerm={setDebouncedSearchTerm}
                        onChange={(val: any) => {
                          if (isValidInput(val.target.value)) {
                            setSearchTerm(val.target.value);
                          }
                        }}
                      />
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                      <FormControl sx={{ width: "100%" }}>
                        <Select
                          fullWidth
                          className="select_div"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={planType}
                          // multiple
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                          displayEmpty
                          onChange={handlePlanChange}
                        >
                          <MenuItem value="" disabled>
                            {translate.plans.planType}
                          </MenuItem>
                          <MenuItem value="1">{translate.plans.food}</MenuItem>
                          <MenuItem value="2">
                            {translate.plans.photoShoot}
                          </MenuItem>

                          <MenuItem value="3">{translate.plans.both}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                      <FormControl sx={{ width: "100%" }}>
                        <Select
                          fullWidth
                          className="select_div"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={validity}
                          // multiple
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                          displayEmpty
                          onChange={handleValidityChange}
                        >
                          <MenuItem value="" disabled>
                            {`${translate.globals.select} ${translate.plans.planValid}`}
                          </MenuItem>
                          <MenuItem value="1">
                            {translate.dashboard.yearly}
                          </MenuItem>
                          <MenuItem value="2">
                            {translate.plans.quarterly}
                          </MenuItem>
                          <MenuItem value="3">
                            {translate.dashboard.monthly}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={12}>
                      {validity !== "" || planType !== "" ? (
                        <Button
                          className="btn"
                          onClick={() => handleClearFilters()}
                        >
                          {translate?.course?.clear}
                        </Button>
                      ) : null}
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                ""
              )}
            </Box>

            <CustomTabPanel value={value1} index={0}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  gap: 1,
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="basic tabs example"
                  className="custom_tabs_links"
                >
                  <Tab label={translate.plans.customers} {...a11yProps(0)} />
                  <Tab label={translate.plans.chefPhoto} {...a11yProps(1)} />
                </Tabs>
                <Box className="cards_header_right">
                  <Button
                    className="btn btn_primary"
                    onClick={() => navigate("/manage-subscription/add")}
                  >
                    {`${translate.globals.add} ${translate.plans.plans}`}
                  </Button>
                </Box>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <TableContainer className="table_container">
                  <Box className="heading"></Box>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          {translate.globals.serialNo}
                        </TableCell>
                        <TableCell>{translate.plans.planName}</TableCell>
                        <TableCell>{translate.plans.planValid}</TableCell>
                        <TableCell>{translate.plans.planPrice}</TableCell>
                        <TableCell>{translate.plans.planType}</TableCell>
                        <TableCell> {translate.globals.status}</TableCell>
                        <TableCell>{translate.globals.actions}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {subsData?.length > 0 ? (
                        subsData.map((row: any, i: any) => (
                          <TableRow key={row?._id}>
                            <TableCell align="center">{i + 1}</TableCell>
                            <TableCell>
                              {getLang === "en"
                                ? row?.name
                                : getLang === "ar"
                                ? row?.name_ar
                                : "-"}
                            </TableCell>
                            <TableCell>
                              {row?.validity === 1
                                ? translate.dashboard.yearly
                                : row?.validity === 2
                                ? translate.plans.quarterly
                                : translate.dashboard.monthly}
                            </TableCell>
                            <TableCell>$ {row?.price || "-"}</TableCell>
                            <TableCell>
                              {row?.planType === 1
                                ? translate.plans.food
                                : row?.planType === 2
                                ? translate.plans.photoShoot
                                : translate.plans.both}
                            </TableCell>

                            <TableCell>
                              <Switch
                                {...label}
                                defaultChecked
                                checked={!row?.isBlocked}
                                onChange={() =>
                                  updateStatus(row?._id, row?.isBlocked)
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <Box className="table_actions">
                                <IconButton
                                  onClick={() =>
                                    navigate(
                                      `/manage-subscription/edit/${row?._id}`
                                    )
                                  }
                                >
                                  <ModeEditIcon />
                                </IconButton>
                                {/* <IconButton
                                  onClick={() => {
                                    setOpen(true);
                                    setSelectedId(row?._id);
                                  }}
                                >
                                  <DeleteIcon />
                                </IconButton> */}
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={9} align="center">
                            {translate.error.noData}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <TableContainer className="table_container">
                  <Box className="heading"></Box>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          {translate.globals.serialNo}
                        </TableCell>
                        <TableCell>{translate.plans.planName}</TableCell>
                        <TableCell>{translate.plans.planValid}</TableCell>
                        <TableCell>{translate.plans.planPrice}</TableCell>
                        <TableCell>{translate.plans.planType}</TableCell>
                        <TableCell>{translate.globals.status}</TableCell>
                        <TableCell>{translate.globals.actions}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {subsData?.length > 0 ? (
                        subsData.map((row: any, i: any) => (
                          <TableRow key={row?._id}>
                            <TableCell align="center">{i + 1}</TableCell>
                            <TableCell>
                              {getLang === "en"
                                ? row?.name
                                : getLang === "ar"
                                ? row?.name_ar
                                : "-"}
                            </TableCell>
                            <TableCell>
                              {row?.validity === 1
                                ? translate.dashboard.yearly
                                : row?.validity === 2
                                ? translate.plans.quarterly
                                : translate.dashboard.monthly}
                            </TableCell>
                            <TableCell>$ {row?.price || "-"}</TableCell>
                            <TableCell>
                              {row?.planType === 1
                                ? translate.plans.food
                                : row?.planType === 2
                                ? translate.plans.photoShoot
                                : translate.plans.both}
                            </TableCell>

                            <TableCell>
                              <Switch
                                {...label}
                                defaultChecked
                                checked={!row?.isBlocked}
                                onChange={() =>
                                  updateStatus(row?._id, row?.isBlocked)
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <Box className="table_actions">
                                {hidePermission?.isAdd ? (
                                  <IconButton
                                    onClick={() =>
                                      navigate(
                                        `/manage-subscription/edit/${row?._id}`,
                                        {
                                          state: { type: "edit" },
                                        }
                                      )
                                    }
                                  >
                                    <ModeEditIcon />
                                  </IconButton>
                                ) : null}
                                {/* {hidePermission?.isDelete ? (
                                  <IconButton
                                    onClick={() => {
                                      setOpen(true);
                                      setSelectedId(row?._id);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                ) : null} */}
                              </Box>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={9} align="center">
                            {translate.error.noData}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </CustomTabPanel>
              {subsData?.length > 0 ? (
                <Pagination
                  setPage={setPage}
                  module={subsData}
                  page={page}
                  onPageChange={onPageChange}
                  totalPages={totalPages}
                />
              ) : (
                ""
              )}
            </CustomTabPanel>
            <CustomTabPanel value={value1} index={1}>
              <DiscountCode />
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="Plan"
      />
    </MainContainer>
  );
};

export default ManageSubscription;
