import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  FormControl,
  Grid,
  MenuItem,
  NativeSelect,
  Paper,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import PeopleIcon from "@mui/icons-material/People";
import WysiwygIcon from "@mui/icons-material/Wysiwyg";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import InventoryIcon from "@mui/icons-material/Inventory";
import LineChart from "../../components/LineChart";
import PaidIcon from "@mui/icons-material/Paid";
import { useNavigate } from "react-router-dom";
import {
  useLazyGetDashboardQuery,
  useLazyGetUserGraphQuery,
  useLazyGetRevenueGraphQuery,
  useLazyGetMerchantGraphQuery,
} from "../../services/main";
import { Dashboard } from "../../types/General";
import { Loader, showError } from "../../constants";
import useTranslation from "../../hooks/Translation";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#204e33" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const DashBoard = () => {
  const translate = useTranslation();
  const [getDashboardQuery] = useLazyGetDashboardQuery();
  const [userGraph] = useLazyGetUserGraphQuery();
  const [revenueGraph] = useLazyGetRevenueGraphQuery();
  const [merchantGraph] = useLazyGetMerchantGraphQuery();
  const [dashboardData, setDashboardData] = useState<Dashboard | undefined>();
  const [loading, setLoading] = useState(false);

  const [userFilter, setUserFilter] = useState<string>("monthly");
  const [userNames, setUserNames] = useState<string[]>([]);
  const [userValues, setUserValues] = useState<number[]>([]);
  const [revenueFilter, setRevenueFilter] = useState<string>("monthly");
  const [revenueNames, setRevenueNames] = useState<string[]>([]);
  const [revenueValues, setRevenueValues] = useState<number[]>([]);
  const [chefFilter, setChefFilter] = useState<string>("monthly");
  const [chefNames, setChefNames] = useState<string[]>([]);
  const [chefValues, setChefValues] = useState<number[]>([]);
  const [photoFilter, setPhotoFilter] = useState<string>("monthly");
  const [photoNames, setPhotoNames] = useState<string[]>([]);
  const [photoValues, setPhotoValues] = useState<number[]>([]);

  const handleOrderChange = (event: SelectChangeEvent) => {
    setUserFilter(event.target.value as string);
  };

  const handleFilterChange = (event: SelectChangeEvent) => {
    setRevenueFilter(event.target.value as string);
  };

  const handleChefChange = (event: SelectChangeEvent) => {
    setChefFilter(event.target.value as string);
  };

  const handlePhotoChange = (event: SelectChangeEvent) => {
    setPhotoFilter(event.target.value as string);
  };

  const getDashboard = async () => {
    setLoading(true);
    try {
      const response = await getDashboardQuery({}).unwrap();
      if (response?.statusCode === 200) {
        setLoading(false);
        setDashboardData(response?.data || "");
      } else {
        setDashboardData(undefined);
      }
    } catch (error: any) {
      setLoading(false);
      showError(error?.data?.message || "");
    }
  };

  const getUserGraph = async () => {
    try {
      const response = await userGraph({ type: userFilter }).unwrap();
      if (response?.statusCode === 200) {
        const Unames = Object.keys(response?.data).map((revenueName) =>
          capitalizeFirstLetter(revenueName)
        );
        setUserNames(Unames);
        setUserValues(Object.values(response?.data));
      } else {
        setUserNames([]);
        setUserValues([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const getRevenueGraph = async () => {
    try {
      const response = await revenueGraph({ type: revenueFilter }).unwrap();
      if (response?.statusCode === 200) {
        const Rvnames = Object.keys(response?.data).map((revenueName) =>
          capitalizeFirstLetter(revenueName)
        );
        setRevenueNames(Rvnames);
        setRevenueValues(Object.values(response?.data));
      } else {
        setRevenueNames([]);
        setRevenueValues([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const getChefGraph = async () => {
    try {
      const response = await merchantGraph({
        type: chefFilter,
        profession: 1,
      }).unwrap();
      if (response?.statusCode === 200) {
        const Cnames = Object.keys(response?.data).map((revenueName) =>
          capitalizeFirstLetter(revenueName)
        );
        setChefNames(Cnames);
        setChefValues(Object.values(response?.data));
      } else {
        setChefNames([]);
        setChefValues([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const getPhotographerGraph = async () => {
    try {
      const response = await merchantGraph({
        type: photoFilter,
        profession: 2,
      }).unwrap();
      if (response?.statusCode === 200) {
        const Pnames = Object.keys(response?.data).map((revenueName) =>
          capitalizeFirstLetter(revenueName)
        );
        setPhotoNames(Pnames);
        setPhotoValues(Object.values(response?.data));
      } else {
        setPhotoNames([]);
        setPhotoValues([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const graphOrderData = {
    labels: userNames,
    datasets: [
      {
        label: translate.plans.customers,
        data: userValues,
        borderColor: "#D63853",
        backgroundColor: "#D63853",
      },
    ],
  };
  const graphRevenueData = {
    labels: revenueNames,
    datasets: [
      {
        label: translate.dashboard.revenue,
        data: revenueValues,
        borderColor: "#D63853",
        backgroundColor: "#D63853",
      },
    ],
  };

  const chefsData = {
    labels: chefNames,
    datasets: [
      {
        label: translate.globals.chefs,
        data: chefValues,
        borderColor: "#D63853",
        backgroundColor: "#D63853",
      },
    ],
  };

  const photoGrapherData = {
    labels: photoNames,
    datasets: [
      {
        label: translate.globals.photographers,
        data: photoValues,
        borderColor: "#D63853",
        backgroundColor: "#D63853",
      },
    ],
  };

  useEffect(() => {
    getDashboard();
  }, []);

  useEffect(() => {
    getUserGraph();
  }, [userFilter]);

  useEffect(() => {
    getRevenueGraph();
  }, [revenueFilter]);

  useEffect(() => {
    getChefGraph();
  }, [chefFilter]);

  useEffect(() => {
    getPhotographerGraph();
  }, [photoFilter]);

  const navigate = useNavigate();

  return (
    <div className="main_loyout">
      <Loader isLoad={loading} />
      <div className="dashboard">
        <h1 className="mn_hdng">{translate?.sideBar?.dashboard}</h1>
      </div>

      <Grid container spacing={2} className="dashGrid">
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manageusers")}
          >
            <PeopleIcon className="svg_icn" />
            <div>
              <h3>{translate?.dashboard?.customers}</h3>
              <h4 className="mn_hdng">{dashboardData?.users || "-"}</h4>
            </div>
          </Item>
        </Grid>

        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-chefs/photographers")}
          >
            <PeopleIcon className="svg_icn" />
            <div>
              <h3>{translate?.dashboard?.totalChefs}</h3>
              <h4 className="mn_hdng">{dashboardData?.chefs || "-"}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() =>
              navigate("/manage-chefs/photographers", { state: "photo" })
            }
          >
            <PeopleIcon className="svg_icn" />
            <div>
              <h3>{translate?.dashboard?.totalPhoto}</h3>
              <h4 className="mn_hdng">{dashboardData?.photographers || "-"}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-orders")}
          >
            <InventoryIcon className="svg_icn" />
            <div>
              <h3>{translate?.dashboard?.totalPurchase}</h3>
              <h4 className="mn_hdng">{dashboardData?.purchases || "-"}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            onClick={() => navigate("/manage-courses")}
          >
            <InventoryIcon className="svg_icn" />
            <div>
              <h3>{translate?.dashboard?.totalCourses}</h3>
              <h4 className="mn_hdng">{dashboardData?.courses || "-"}</h4>
            </div>
          </Item>
        </Grid>
        <Grid item lg={3} md={3} sm={6} xs={12}>
          <Item
            className="cards dashGrid_item"
            // onClick={() => navigate("/manageservices")}
          >
            <PaidIcon className="svg_icn" />
            <div>
              <h3>{translate?.dashboard?.TotalRevenue}</h3>
              <h4 className="mn_hdng">
                {dashboardData?.earnings !== undefined
                  ? dashboardData?.earnings >= 1000000
                    ? `$ ${(dashboardData?.earnings / 1000000).toFixed(1)} m`
                    : Math.round(dashboardData?.earnings)
                  : "-"}
              </h4>
            </div>
          </Item>
        </Grid>
      </Grid>

      <Grid container spacing={2} className="dashGraph" sx={{ pt: 4 }}>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">
              {translate?.dashboard?.customers}
              <FormControl>
                <Select
                  className="select_div"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={userFilter}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  onChange={handleOrderChange}
                >
                  <MenuItem value="" disabled>
                    {translate?.globals?.select}
                  </MenuItem>
                  <MenuItem value="daily">
                    {translate?.dashboard?.daily}
                  </MenuItem>
                  <MenuItem value="weekly">
                    {translate?.dashboard?.weekly}
                  </MenuItem>
                  <MenuItem value="monthly">
                    {translate?.dashboard?.monthly}
                  </MenuItem>
                  <MenuItem value="yearly">
                    {translate?.dashboard?.yearly}
                  </MenuItem>
                </Select>
              </FormControl>
            </h2>

            <LineChart data={graphOrderData} />
          </Item>
        </Grid>
        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">
              {translate?.dashboard?.TotalRevenue}
              <FormControl>
                <Select
                  className="select_div"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={revenueFilter}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  onChange={handleFilterChange}
                >
                  <MenuItem value="" disabled>
                    {translate?.globals?.select}
                  </MenuItem>
                  <MenuItem value="daily">
                    {translate?.dashboard?.daily}
                  </MenuItem>
                  <MenuItem value="weekly">
                    {translate?.dashboard?.weekly}
                  </MenuItem>
                  <MenuItem value="monthly">
                    {translate?.dashboard?.monthly}
                  </MenuItem>
                  <MenuItem value="yearly">
                    {translate?.dashboard?.yearly}
                  </MenuItem>
                </Select>
              </FormControl>
            </h2>
            <LineChart data={graphRevenueData} />
          </Item>
        </Grid>

        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">
              {translate?.dashboard?.totalChefs}
              <FormControl>
                <Select
                  className="select_div"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={chefFilter}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  onChange={handleChefChange}
                >
                  <MenuItem value="" disabled>
                    {translate?.globals?.select}
                  </MenuItem>
                  <MenuItem value="daily">
                    {translate?.dashboard?.daily}
                  </MenuItem>
                  <MenuItem value="weekly">
                    {translate?.dashboard?.weekly}
                  </MenuItem>
                  <MenuItem value="monthly">
                    {translate?.dashboard?.monthly}
                  </MenuItem>
                  <MenuItem value="yearly">
                    {translate?.dashboard?.yearly}
                  </MenuItem>
                </Select>
              </FormControl>
            </h2>
            <LineChart data={chefsData} />
          </Item>
        </Grid>

        <Grid item lg={6} md={6} sm={6} xs={12}>
          <Item className="cards dashGraph_item">
            <h2 className="mn_hdng">
              {translate?.dashboard?.totalPhoto}
              <FormControl>
                <Select
                  className="select_div"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={photoFilter}
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  displayEmpty
                  onChange={handlePhotoChange}
                >
                  <MenuItem value="" disabled>
                    {translate?.globals?.select}
                  </MenuItem>
                  <MenuItem value="daily">
                    {translate?.dashboard?.daily}
                  </MenuItem>
                  <MenuItem value="weekly">
                    {translate?.dashboard?.weekly}
                  </MenuItem>
                  <MenuItem value="monthly">
                    {translate?.dashboard?.monthly}
                  </MenuItem>
                  <MenuItem value="yearly">
                    {translate?.dashboard?.yearly}
                  </MenuItem>
                </Select>
              </FormControl>
            </h2>
            <LineChart data={photoGrapherData} />
          </Item>
        </Grid>
      </Grid>
    </div>
  );
};

export default DashBoard;
