import { Box, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { Dispatch, SetStateAction, useEffect } from "react";
import useTranslation from "../hooks/Translation";

type SearchProps = {
  value?: string;
  onChange?: any;
  onCross?: () => void;
  searchTerm: string;
  setDebouncedSearchTerm: Dispatch<SetStateAction<string>>;
  style?: any;
};

const SearchBar = ({
  value,
  onChange,
  onCross,
  setDebouncedSearchTerm,
  searchTerm,
  style,
}: SearchProps) => {
  const translate = useTranslation();
  useEffect(() => {
    const delay = 1000; // Debounce delay in milliseconds
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, delay);

    return () => {
      clearTimeout(timerId); // Clear the timeout on cleanup
    };
  }, [searchTerm]);

  return (
    <Box className="search_bar" sx={style}>
      <TextField
        value={value}
        onChange={onChange}
        hiddenLabel
        placeholder={translate?.globals?.searchHere}
        className="txt_inpt"
        fullWidth
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className="search_icon">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end" className="cross_btn">
              {value && (
                <ClearIcon
                  onClick={onCross}
                  sx={{ cursor: "pointer", color: "rgba(0, 0, 0, 0.54)" }}
                />
              )}
            </InputAdornment>
          ),
        }}
        sx={[
          {
            ".MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(0, 0, 0, 0.23)", // Optional: Add a hover effect to the border
            },
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "rgba(0, 0, 0, 0.23)",
            },
          },
        ]}
      />
    </Box>
  );
};

export default SearchBar;
