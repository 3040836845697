import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Typography,
  Grid,
  TextField,
  SelectChangeEvent,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import { isValidInput } from "../../utils/validations";
import useAuth from "../../hooks/useAuth";
import { useLazyGetAllOrdersQuery } from "../../services/main";
import { Loader, showError } from "../../constants";
import Pagination from "../../components/Pagination";
import moment from "moment";
import useTranslation from "../../hooks/Translation";
import { start } from "repl";

const ManageOrders = () => {
  const userData = useAuth();
  const [allOrders] = useLazyGetAllOrdersQuery();
  const [ordersData, setOrdersData] = useState<any>([]);
  const [value, setValue] = useState<number>(0);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });
  const [totalCount, setTotalCount] = useState<number>(0);
  const [startDate, setStartDate] = useState("");
  console.log(startDate);
  const [endDate, setEndDate] = useState("");
  const [page, setPage] = useState(1);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const [loading, setLoading] = useState(false);
  const translate = useTranslation();

  let totalPages = Math.ceil(totalCount / 10);

  const getAllOrders = async () => {
    setLoading(true);
    try {
      const response = await allOrders({
        page: page,
        query: debouncedSearchTerm.trim(),
        startDate: startDate,
        endDate: endDate,
      }).unwrap();
      if (response?.statusCode === 200) {
        setLoading(false);
        setOrdersData(response?.data?.orders || []);
        setTotalCount(response?.data?.count);
      } else {
        setOrdersData([]);
      }
    } catch (error: any) {
      setLoading(false);
      // showError(error?.data?.message || "");
      setOrdersData([]);
    }
  };

  const navigate = useNavigate();
  function createData(
    name: string,
    expertName: string,
    description: string,
    price: string
  ) {
    return {
      name,
      expertName,
      description,
      price,
    };
  }
  // const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData(
      "John",
      "Harry",
      "How to make white sauce pasta at your home",
      "$100"
    ),
  ];

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const handleClearFilters = () => {
    setStartDate("");
    setEndDate("");
  };

  const checkPermission = () => {
    const permissions = userData?.permission;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex((ele: any) => ele?.name === "Manage Orders");

      if (idx != -1) {
        let data = {
          isAdd: permissions[idx]?.isAdd,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };

  const handleStateDate = (event: any) => {
    setStartDate(event.target.value as string);
  };

  const handleEndDate = (event: any) => {
    setEndDate(event.target.value as string);
  };

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);

  useEffect(() => {
    getAllOrders();
  }, [debouncedSearchTerm, page, startDate, endDate]);

  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translate.sideBar.orders}</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box sx={{ display: "flex", alignItems: "center", gap: 5 }}>
              <Box sx={{ paddingTop: 2.3 }}>
                <SearchBar
                  value={searchTerm}
                  searchTerm={searchTerm}
                  onCross={() => setSearchTerm("")}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }}
                />
              </Box>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography className="custom_label">Start Date</Typography>
                <TextField
                  hiddenLabel
                  type={"date"}
                  name="validFrom"
                  className="text_field"
                  variant="outlined"
                  fullWidth
                  placeholder={translate.main.validFrom}
                  onChange={(val) => handleStateDate(val)}
                  value={startDate}
                  onKeyDown={(e) => e.preventDefault()} // Prevent keyboard input
                  InputProps={{ disableUnderline: true }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <Typography className="custom_label">End Date</Typography>
                <TextField
                  hiddenLabel
                  type={"date"}
                  name="validTill"
                  className="text_field"
                  variant="outlined"
                  disabled={!startDate}
                  fullWidth
                  placeholder={translate.main.validTill}
                  onChange={(val) => handleEndDate(val)}
                  value={endDate}
                  inputProps={{
                    min: startDate,
                  }}
                  onKeyDown={(e) => e.preventDefault()} // Prevent keyboard input
                  InputProps={{ disableUnderline: true }}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={12} sx={{ paddingTop: 2.3 }}>
                {startDate !== "" || endDate !== "" ? (
                  <Button className="btn" onClick={() => handleClearFilters()}>
                    {translate?.course?.clear}
                  </Button>
                ) : null}
              </Grid>
            </Box>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      {translate.globals.serialNo}
                    </TableCell>
                    <TableCell>{translate.orders.userName}</TableCell>
                    <TableCell>{translate.orders.chefsName}</TableCell>
                    <TableCell>{translate.orders.photoName}</TableCell>
                    <TableCell>{translate.auth.email}</TableCell>
                    <TableCell>{translate.orders.courseName}</TableCell>
                    <TableCell>{translate.orders.date}</TableCell>

                    <TableCell>{translate.orders.grandTotal}</TableCell>
                    <TableCell>{translate.globals.actions}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ordersData?.length > 0 ? (
                    ordersData?.map((row: any, i: any) => (
                      <TableRow key={row.name}>
                        <TableCell align="center">{i + 1}</TableCell>
                        <TableCell>
                          {row?.user?.firstName || row?.user?.nickName}
                        </TableCell>
                        <TableCell>
                          {row?.merchant?.profession == 1
                            ? row?.merchant?.firstName ||
                              row?.merchant?.nickName
                            : "-"}
                        </TableCell>
                        <TableCell>
                          {row?.merchant?.profession == 2
                            ? row?.merchant?.firstName ||
                              row?.merchant?.nickName
                            : "-"}
                        </TableCell>
                        <TableCell>{row?.merchant?.email || "-"}</TableCell>
                        <TableCell>{row?.course?.title || "-"}</TableCell>
                        <TableCell>
                          {moment(row?.createdAt).format("MM-D-YY / h:mm A")}
                        </TableCell>

                        <TableCell>
                          {`${row?.grandTotal ? "$" : ""} ${
                            row?.grandTotal > 0
                              ? row?.grandTotal.toFixed(2)
                              : "Free"
                          }`}
                        </TableCell>

                        <TableCell>
                          <Box className="table_actions">
                            <IconButton
                              onClick={() =>
                                navigate(`/manage-orders/details/${row?._id}`)
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={9} align="center">
                        {translate.error.noData}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Card>
        {ordersData?.length > 0 ? (
          <Pagination
            setPage={setPage}
            module={ordersData}
            page={page}
            onPageChange={onPageChange}
            totalPages={totalPages}
          />
        ) : (
          ""
        )}
      </div>
    </MainContainer>
  );
};

export default ManageOrders;
