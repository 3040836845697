export const API_URL = "https://cooklifyapi.appgrowthcompany.com/api/v1/admin/"; //development

export const UPLOAD_URL =
  "https://cooklifyapi.appgrowthcompany.com/api/v1/merchant/upload"; // for upload

export const END_POINTS = {
  //onboarding
  login: "login",
  getUser: "profile",
  forgetPassword: "forgotPassword",
  verifyOtp: "verifyOtp",
  updateProfile: "updateProfile",
  resetPassword: "setPassword",
  changePassword: "changePassword",
  logout: "logout",

  //main
  merchant: "merchant",
  categories: "categories",
  addCategory: "category",
  faqs: "faq",
  course: "course",
  cms: "cms",
  customers: "customers",
  video: "video",
  settings: "settings",
  subadmin: "subadmin/",
  exportCourses: "exportCourses",
  exportUsers: "exportUsers",
  exportMerchants: "exportMerchants",
  orders: "orders",
  ratings: "ratings",
  dashboard: "dashboard",
  usersGraph: "usersGraph",
  revenueGraph: "revenueGraph",
  merchantGraph: "merchantGraph",
  discount: "discount",
  subscription: "subscription",
  notification: "notification",
};
