import React, { useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  SelectChangeEvent,
  Typography,
  Checkbox,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation, useNavigate } from "react-router-dom";

const AddCustomerPlan = () => {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const [receiver, setReceiver] = useState<string>("All");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsCheckboxChecked(!isCheckboxChecked);
  };
  const handleReceiverChange = (event: SelectChangeEvent) => {
    setReceiver(event.target.value as string);
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const planFeatures = [
    {
      id: 1,
      name: "Enhanced Budget Tools",
      enable: true,
    },
    {
      id: 2,
      name: "Link Bank Accounts",
      enable: true,
    },
    {
      id: 3,
      name: "Advanced Security",
      enable: true,
    },
    {
      id: 4,
      name: "Track Spending Habbits",
      enable: true,
    },
    {
      id: 5,
      name: "Graphs and Charts",
      enable: true,
    },
    {
      id: 6,
      name: "Savings jar to visually watch the progress",
      enable: true,
    },
    {
      id: 7,
      name: "Recommendations and Analytics",
      enable: true,
    },
    {
      id: 8,
      name: "Educational Tips",
      enable: true,
    },
    {
      id: 9,
      name: "Savings Goals",
      enable: true,
    },
    {
      id: 10,
      name: "Round-Up Savings to Achieve Goals",
      enable: true,
    },
  ];
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          {state?.type === "edit" ? (
            <h1 className="mn_hdng">Edit Plan</h1>
          ) : (
            <h1 className="mn_hdng">Add Plan</h1>
          )}
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-subscription");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <form>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Plan Name</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="firstName"
                    variant="outlined"
                    fullWidth
                    placeholder="Plan Name"
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Plan Validity
                  </Typography>
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      fullWidth
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={receiver}
                      // multiple
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      onChange={handleReceiverChange}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      <MenuItem value="All">Yearly</MenuItem>
                      <MenuItem value="User 1">Quarterly</MenuItem>
                      <MenuItem value="User 2">Monthly</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Plan Price</Typography>
                  <TextField
                    hiddenLabel
                    type={"email"}
                    name="email"
                    variant="outlined"
                    fullWidth
                    placeholder="Plan Price"
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Select plan type
                  </Typography>

                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      fullWidth
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={receiver}
                      // multiple
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      onChange={handleReceiverChange}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      <MenuItem value="All">Food</MenuItem>
                      <MenuItem value="User 1">PhotoShoot</MenuItem>
                      <MenuItem value="User 2">Both</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Select user type
                  </Typography>

                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      fullWidth
                      className="select_div"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={receiver}
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      onChange={handleReceiverChange}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      <MenuItem value="All">Customer</MenuItem>
                      <MenuItem value="User 1">Chefs/Photographers</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}></Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Description</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    className="text_field"
                    name="firstName"
                    fullWidth
                    multiline
                    placeholder="Description"
                    minRows={4}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      component="h2"
                      sx={{
                        fontSize: "20px",
                        color: "#051140",
                        fontWeight: 700,
                      }}
                    >
                      Plan Features
                    </Typography>
                  </Box>
                </Grid>

                {/* <Grid item xs={12}>
                  <TableContainer
                    className="table_container"
                    sx={{ margin: 0 }}
                  >
                    <Box className="heading"></Box>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">S.No</TableCell>
                          <TableCell>Feature</TableCell>
                          <TableCell> Status</TableCell>
                          
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {planFeatures.map((row, i) => (
                          <TableRow key={i}>
                            <TableCell align="center">{i + 1}</TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>
                              <Switch {...label} defaultChecked size="small" />
                            </TableCell>
                
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid> */}

                <Grid container spacing={2} sx={{ padding: 2, paddingLeft: 4 }}>
                  <Grid xs={6}>
                    <p> 1 . The users will get discounts on the courses</p>
                  </Grid>
                  <Grid xs={6}>
                    <Checkbox
                      {...label}
                      checked={isCheckboxChecked}
                      onChange={handleCheckboxChange}
                    />
                  </Grid>

                  <Grid xs={6}>
                    <p>2 . Ability to view more free videos than other users</p>
                  </Grid>
                  <Grid xs={6}>
                    <Checkbox {...label} />
                  </Grid>
                </Grid>
                {isCheckboxChecked ? (
                  <Grid container spacing={2} sx={{ padding: 1 }}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography className="custom_label">Discount</Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="email"
                        variant="outlined"
                        fullWidth
                        placeholder="discount"
                      />
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
              <div className="form_btn">
                <Button size="large" type="submit" className="btn btn_primary">
                  Save
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default AddCustomerPlan;
