import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import {
  useLazyGetAllReviewsQuery,
  useLazyGetReviewsByIdQuery,
} from "../../services/main";
import "../users/Users.scss";
import {
  getFromStorage,
  Loader,
  showError,
  STORAGE_KEYS,
} from "../../constants";
import useTranslation from "../../hooks/Translation";

const RatingDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [orderDetails] = useLazyGetAllReviewsQuery();
  const [reviewById] = useLazyGetReviewsByIdQuery();
  const [orderData, setOrderData] = useState("");
  const [value, setValue] = React.useState(0);
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const translate = useTranslation();
  const getLang = getFromStorage(STORAGE_KEYS.language);

  const getReviewDetails = async (id: string | undefined) => {
    try {
      const res = await reviewById({ ratingId: id }).unwrap();
      if (res?.statusCode === 200) {
        setData(res?.data);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    getReviewDetails(id);
  }, []);

  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{}</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-review");
            }}
          >
            {translate.globals.back}
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={16} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translate.orders.userName}
                      </Typography>
                      <Typography component="p">
                        {data?.user?.fullName || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translate.orders.userEmail}
                      </Typography>
                      <Typography component="p">{data?.user?.email}</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translate.auth.phone}
                      </Typography>
                      <Typography component="p">
                        {`${data?.user?.countryCode || ""} ${
                          data?.user?.phone || "-"
                        }
                          `}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translate.course.courseName}
                      </Typography>
                      <Typography component="p">
                        {getLang === "en"
                          ? data?.course?.title
                          : getLang === "ar"
                          ? data?.course?.title_ar
                          : "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translate.orders.subTotal}
                      </Typography>
                      <Typography component="p">
                        {`${data?.order?.subTotal ? "$" : ""} ${
                          data?.order?.subTotal !== undefined
                            ? data?.order?.subTotal.toFixed(2)
                            : "Free"
                        }`}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translate.course.discount}
                      </Typography>
                      <Typography component="p">
                        {`${data?.order?.discount || "0"} %`}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translate.orders.taxAmount}
                      </Typography>
                      <Typography component="p">
                        {`${data?.order?.taxAmount ? "$" : ""} ${
                          data?.order?.taxAmount !== undefined
                            ? data?.order?.taxAmount.toFixed(2)
                            : "Free"
                        }`}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translate.orders.grandTotal}
                      </Typography>
                      <Typography component="p">
                        {`${data?.order?.grandTotal ? "$" : ""} ${
                          data?.order?.grandTotal !== undefined
                            ? data?.order?.grandTotal.toFixed(2)
                            : "Free"
                        }`}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translate.orders.review}
                      </Typography>
                      <Typography component="p">
                        {data?.review || "-"}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <h3 style={{ color: "#051140" }}>
                      {translate.orders.paymentDetails}
                    </h3>
                    <Grid container spacing={3}>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">
                            {translate.orders.paymentMode}
                          </Typography>
                          <Typography component="p">-</Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">
                            {translate.orders.paymentDate}
                          </Typography>
                          <Typography component="p">-</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </MainContainer>
  );
};

export default RatingDetails;
