import { END_POINTS } from "../constants/url";
import { Discount } from "../types/General";
// import { CommonBody, Subscription } from "../types/General";

import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  data: {
    totalPages: number;
    count: number;
  };
  statusCode: number;
  message: string;
};

type GetUserParams = {
  page: number;
  query: string;
  userType: number;
  planType: number | string;
  validity: number | string;
};

type AddSubscriptionBody = {
  description: string;
  features: any[];
  name: string;
  planType: number;
  price: string;
  userType: number;
  validity: number;
};

type updateSubscriptionParams = {
  subsId: string;
  body: {
    description: string;
    features: any[];
    name: string;
    planType: number;
    price: string;
    userType: number;
    validity: number;
  };
};

export const subscriptionApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllSubscriptions: builder.query<
      CommonResponseType & {
        data: {
          count: number;
          totalCount: number;
          data: any;
        };
      },
      GetUserParams
    >({
      query: ({ page, query, userType, planType, validity }) => ({
        url:
          END_POINTS.subscription +
          "?page=" +
          page +
          "&search=" +
          query +
          "&userType=" +
          userType +
          "&planType=" +
          planType +
          "&validity=" +
          validity,
        method: "GET",
      }),
    }),

    addSubscription: builder.mutation<
      { message: string; statusCode: number; data: any },
      AddSubscriptionBody
    >({
      query: (body) => ({
        url: END_POINTS.subscription,
        method: "POST",
        body,
      }),
    }),

    getSubscriptionById: builder.query<
      CommonResponseType & { data: Discount },
      { subId: string | undefined }
    >({
      query: ({ subId }) => ({
        url: `${END_POINTS.subscription}/${subId}`,
        method: "GET",
      }),
    }),

    changeSubscriptionStatus: builder.mutation<
      { message: string; statusCode: number },
      { subsId: string; body: { isBlocked: boolean } }
    >({
      query: ({ body, subsId }) => ({
        url: `${END_POINTS.subscription}/${subsId}`,
        method: "PUT",
        body,
      }),
    }),

    deleteSubscription: builder.mutation<
      CommonResponseType,
      { subsId: string }
    >({
      query: ({ subsId }) => ({
        url: `${END_POINTS.subscription}/${subsId}`,
        method: "DELETE",
      }),
    }),

    updateSubscription: builder.mutation<
      CommonResponseType,
      updateSubscriptionParams
    >({
      query: ({ body, subsId }) => ({
        url: `${END_POINTS.subscription}/${subsId}`,
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const {
  useLazyGetAllSubscriptionsQuery,
  useAddSubscriptionMutation,
  useLazyGetSubscriptionByIdQuery,
  useChangeSubscriptionStatusMutation,
  useDeleteSubscriptionMutation,
  useUpdateSubscriptionMutation,
} = subscriptionApi;
