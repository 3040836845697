import { Routes, Route } from "react-router-dom";
import Pages from "./pages";
import UserDetails from "./pages/users/details";
import UsersForm from "./pages/users/form/usersForm";

const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<Pages.LoginPage />} />
      <Route path="forgotpassword" element={<Pages.ForgotPasswordPage />} />
      <Route path="verifyotp" element={<Pages.VerifyOtp />} />
      <Route path="resetpassword" element={<Pages.ResetPassword />} />
      <Route path="changepassword" element={<Pages.ChangePassword />} />
      <Route path="/dashboard" element={<Pages.DashBoardPage />} />
      <Route path="/profile" element={<Pages.Profile />} />
      <Route path="/manageusers" element={<Pages.ManageUsers />} />
      <Route path="/manageusers/userdetails/:id" element={<UserDetails />} />
      <Route path="/manageusers/usersform/:id" element={<UsersForm />} />
      <Route path="/analytics" element={<Pages.Analytics />} />
      <Route path="/faq" element={<Pages.Faq />} />
      <Route path="/cms" element={<Pages.ManageCms />} />
      <Route path="/settings" element={<Pages.Settings />} />

      <Route
        path="/manage-subscription"
        element={<Pages.ManageSubscription />}
      />
      <Route
        path="/manage-subscription/add"
        element={<Pages.AddSubscriptionPlan />}
      />
      <Route
        path="/manage-subscription/edit/:id"
        element={<Pages.AddSubscriptionPlan />}
      />
      <Route
        path="/manage-subscription/add/customer"
        element={<Pages.AddCustomerPlan />}
      />
      <Route
        path="/manage-subscription/edit"
        element={<Pages.AddSubscriptionPlan />}
      />
      <Route
        path="/manage-subscription/add-discount-code/add"
        element={<Pages.AddDiscountCode />}
      />
      <Route
        path="/manage-subscription/add-discount-code/edit/:id"
        element={<Pages.AddDiscountCode />}
      />
      <Route
        path="/manage-notifications"
        element={<Pages.ManageNotifications />}
      />
      <Route
        path="/manage-notifications/add"
        element={<Pages.AddNotification />}
      />
      <Route
        path="/recieved-notifications"
        element={<Pages.RecievedNotifications />}
      />
      <Route path="/customer-support" element={<Pages.CustomerSupport />} />
      <Route path="/manage-subAdmin" element={<Pages.ManageSubAdmin />} />
      <Route path="/manage-subAdmin/add" element={<Pages.AddSubAdmin />} />
      <Route path="/manage-subAdmin/edit/:id" element={<Pages.AddSubAdmin />} />
      <Route path="/manage-categories" element={<Pages.ManageCategories />} />
      <Route path="/manage-categories/add" element={<Pages.AddCategories />} />
      <Route
        path="/manage-categories/edit/:id"
        element={<Pages.AddCategories />}
      />
      <Route
        path="/manage-categories/details"
        element={<Pages.SubCategories />}
      />
      <Route path="/manage-review" element={<Pages.ManageReview />} />
      <Route
        path="/manage-review/details/:id"
        element={<Pages.RatingDetails />}
      />
      <Route path="/manage-videos" element={<Pages.ManageVideos />} />
      <Route path="/manage-videos/add" element={<Pages.AddVideos />} />
      <Route path="/manage-videos/edit/:id" element={<Pages.AddVideos />} />
      <Route path="/manage-courses" element={<Pages.ManageCourses />} />
      <Route path="/manage-courses/add" element={<Pages.AddCourses />} />
      <Route path="/manage-courses/edit/:id" element={<Pages.AddCourses />} />
      <Route
        path="/manage-courses/coursedetails/:id"
        element={<Pages.CourseDetails />}
      />
      <Route
        path="/manage-chefs/photographers"
        element={<Pages.ManageChefs />}
      />
      <Route
        path="/manage-chefs/photographers/details/:id"
        element={<Pages.ChefAndPhotographerDetails />}
      />
      <Route
        path="/manage-chefs/photographers/add"
        element={<Pages.AddChefsAndPhotographers />}
      />
      <Route
        path="/manage-chefs/photographers/edit/:id"
        element={<Pages.AddChefsAndPhotographers />}
      />
      <Route path="/manage-orders" element={<Pages.ManageOrders />} />
      <Route
        path="/manage-orders/details/:id"
        element={<Pages.OrderDetails />}
      />

      <Route path="/promotional-videos" element={<Pages.PromotionalVideo />} />
      <Route
        path="/promotional-videos/add"
        element={<Pages.AddPromotionalVideo />}
      />
      <Route
        path="/promotional-videos/edit/:id"
        element={<Pages.AddPromotionalVideo />}
      />

      <Route
        path="/manage-discount-coupons"
        element={<Pages.DiscountCoupons />}
      />
      <Route
        path="/manage-discount-coupons/add"
        element={<Pages.AddDiscountCoupon />}
      />
      <Route
        path="/manage-discount-coupons/edit/:id"
        element={<Pages.AddDiscountCoupon />}
      />
    </Routes>
  );
};

export default Routing;
