import { Box, Card } from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useEffect, useState } from "react";
import { MainFaq } from "../../components";
import useTranslation from "../../hooks/Translation";

const Faq = () => {
  const translate = useTranslation();
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translate.sideBar.faqs}</h1>
        </div>
        <div>
          <Card className="cards">
            <Box className="custom_tabs">
              <MainFaq />
            </Box>
          </Card>
        </div>
      </div>
    </MainContainer>
  );
};

export default Faq;
