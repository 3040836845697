import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import MainContainer from "../../layout/MainContainer";
import LineChart from "../../components/LineChart";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  useLazyGetRevenueGraphQuery,
  useLazyGetUserGraphQuery,
} from "../../services/main";
import { showError } from "../../constants";
import useTranslation from "../../hooks/Translation";
ChartJS.register(ArcElement, Tooltip, Legend);

const Analytics = () => {
  const [userGraph] = useLazyGetUserGraphQuery();
  const [revenueGraph] = useLazyGetRevenueGraphQuery();
  const [userFilter, setUserFilter] = useState<string>("monthly");
  const [userNames, setUserNames] = useState<string[]>([]);
  const [userValues, setUserValues] = useState<number[]>([]);
  const [revenueFilter, setRevenueFilter] = useState<string>("monthly");
  const [revenueNames, setRevenueNames] = useState<string[]>([]);
  const [revenueValues, setRevenueValues] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);
  const translate = useTranslation();

  const handleOrderChange = (event: SelectChangeEvent) => {
    setUserFilter(event.target.value as string);
  };

  const handleFilterChange = (event: SelectChangeEvent) => {
    setRevenueFilter(event.target.value as string);
  };

  const getUserGraph = async () => {
    setLoading(true);
    try {
      const response = await userGraph({ type: userFilter }).unwrap();
      if (response?.statusCode === 200) {
        setLoading(false);
        setUserNames(Object.keys(response?.data));
        setUserValues(Object.values(response?.data));
      } else {
        setUserNames([]);
        setUserValues([]);
      }
    } catch (error: any) {
      setLoading(false);
      showError(error?.data?.message || "");
    }
  };

  const getRevenueGraph = async () => {
    try {
      const response = await revenueGraph({ type: revenueFilter }).unwrap();
      if (response?.statusCode === 200) {
        setRevenueNames(Object.keys(response?.data));
        setRevenueValues(Object.values(response?.data));
      } else {
        setRevenueNames([]);
        setRevenueValues([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  const graphRevenueData = {
    labels: revenueNames,
    datasets: [
      {
        label: "Revenue",
        data: revenueValues,
        borderColor: "#D63853",
        backgroundColor: "#D63853",
      },
    ],
  };

  const graphUsersData = {
    labels: userNames,
    datasets: [
      {
        label: "Customers",
        data: userValues,
        borderColor: "#D63853",
        backgroundColor: "#D63853",
      },
    ],
  };

  useEffect(() => {
    getUserGraph();
  }, [userFilter]);

  useEffect(() => {
    getRevenueGraph();
  }, [revenueFilter]);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translate.sideBar.reports}</h1>
        </div>
        <Grid container spacing={2} className="dashGraph">
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
                {translate.dashboard.customers}
                <FormControl>
                  <Select
                    className="select_div"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={userFilter}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    displayEmpty
                    onChange={handleOrderChange}
                  >
                    <MenuItem value="" disabled>
                      {translate?.globals?.select}
                    </MenuItem>
                    <MenuItem value="daily">
                      {translate?.dashboard?.daily}
                    </MenuItem>
                    <MenuItem value="weekly">
                      {translate?.dashboard?.weekly}
                    </MenuItem>
                    <MenuItem value="monthly">
                      {translate?.dashboard?.monthly}
                    </MenuItem>
                    <MenuItem value="yearly">
                      {translate?.dashboard?.yearly}
                    </MenuItem>
                  </Select>
                </FormControl>
              </h2>
              <LineChart data={graphUsersData} />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
                {translate.dashboard.TotalRevenue}
                <FormControl>
                  <Select
                    className="select_div"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={revenueFilter}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    displayEmpty
                    onChange={handleFilterChange}
                  >
                    <MenuItem value="" disabled>
                      {translate?.globals?.select}
                    </MenuItem>
                    <MenuItem value="daily">
                      {translate?.dashboard?.daily}
                    </MenuItem>
                    <MenuItem value="weekly">
                      {translate?.dashboard?.weekly}
                    </MenuItem>
                    <MenuItem value="monthly">
                      {translate?.dashboard?.monthly}
                    </MenuItem>
                    <MenuItem value="yearly">
                      {translate?.dashboard?.yearly}
                    </MenuItem>
                  </Select>
                </FormControl>
              </h2>
              <LineChart data={graphRevenueData} />
            </div>
          </Grid>
        </Grid>
      </div>
    </MainContainer>
  );
};

export default Analytics;
