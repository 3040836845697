import { END_POINTS } from "../constants/url";
import { Courses } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
  totalPages: number;
};

type CommonParams = {
  page: number;
  query: string;
  category: string;
  rating: string;
  price: string;
};

type AddCategoryBody = {
  title: string;
  description: string;
  coverImage: string;
  price: number;
  // merchant: "65548dad148334fa16a161a5";
  category: string;
  pricingType: number;
  videos: [];
};

type AddFaqBody = {
  question: string;
  answer: string;
};

type UpdateCategoryParams = {
  courseId: string | undefined;
  body: {
    title: string;
    description: string;
    coverImage: string;
    price: number;
    // merchant: "65548dad148334fa16a161a5";
    category: string;
    pricingType: number;
    videos: [];
  };
};

export const courseApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllCourses: builder.query<
      CommonResponseType & {
        data: {
          count: number;
          totalCount: number;
          courses: Courses[];
        };
      },
      CommonParams
    >({
      query: ({ page, query, category, rating, price }) => ({
        url:
          END_POINTS.course +
          "?page=" +
          page +
          "&search=" +
          query +
          "&category=" +
          category +
          "&ratings=" +
          rating +
          "&price=" +
          price,

        method: "GET",
      }),
    }),

    getAuthors: builder.query<
      CommonResponseType & {
        data: {
          data: any;
        };
      },
      {}
    >({
      query: () => ({
        url: END_POINTS.merchant + "?limit=100",
        method: "GET",
      }),
    }),

    addCourse: builder.mutation<
      { message: string; statusCode: number; data: any },
      AddCategoryBody
    >({
      query: (body) => ({
        url: END_POINTS.course,
        method: "POST",
        body,
      }),
    }),

    changeCategoryStatus: builder.mutation<
      { message: string; statusCode: number },
      { categoryId: string; body: { isBlocked: boolean } }
    >({
      query: ({ body, categoryId }) => ({
        url: `${END_POINTS.addCategory}/${categoryId}`,
        method: "PUT",
        body,
      }),
    }),

    deleteCategory: builder.mutation<
      CommonResponseType,
      { categoryId: string }
    >({
      query: ({ categoryId }) => ({
        url: `${END_POINTS.addCategory}/${categoryId}`,
        method: "DELETE",
      }),
    }),

    updateCourse: builder.mutation<CommonResponseType, UpdateCategoryParams>({
      query: ({ body, courseId }) => ({
        url: `${END_POINTS.course}/${courseId}`,
        method: "PUT",
        body,
      }),
    }),

    getCourseById: builder.query<
      CommonResponseType & { data: any },
      { courseId: string | undefined }
    >({
      query: ({ courseId }) => ({
        url: `${END_POINTS.course}/${courseId}`,
        method: "GET",
      }),
    }),

    getAllFaqs: builder.query<
      CommonResponseType & {
        data: {
          data: any;
        };
      },
      {}
    >({
      query: () => ({
        url: END_POINTS.faqs,
        method: "GET",
      }),
    }),

    addFaqs: builder.mutation<
      { message: string; statusCode: number; data: any },
      AddFaqBody
    >({
      query: (body) => ({
        url: END_POINTS.faqs,
        method: "POST",
        body,
      }),
    }),

    deleteFaqs: builder.mutation<CommonResponseType, { faqId: string }>({
      query: ({ faqId }) => ({
        url: `${END_POINTS.faqs}/${faqId}`,
        method: "DELETE",
      }),
    }),

    deleteCourse: builder.mutation<CommonResponseType, { courseId: string }>({
      query: ({ courseId }) => ({
        url: `${END_POINTS.course}/${courseId}`,
        method: "DELETE",
      }),
    }),

    changeCourseStatus: builder.mutation<
      { message: string; statusCode: number },
      { courseId: string; body: { isBlocked: boolean } }
    >({
      query: ({ body, courseId }) => ({
        url: `${END_POINTS.course}/${courseId}`,
        method: "PUT",
        body,
      }),
    }),
    featureCourse: builder.mutation<
      { message: string; statusCode: number },
      { courseId: string; body: { isFeatured: boolean } }
    >({
      query: ({ body, courseId }) => ({
        url: `${END_POINTS.course}/${courseId}`,
        method: "PUT",
        body,
      }),
    }),

    getCourseCsv: builder.query<
      any,
      { query: string; category: string; rating: string; price: string }
    >({
      query: ({ query, category, rating, price }) => ({
        url:
          END_POINTS.exportCourses +
          "?search=" +
          query +
          "&category=" +
          category +
          "&ratings=" +
          rating +
          "&price=" +
          price,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetAllCoursesQuery,
  useLazyGetCourseByIdQuery,
  useAddCourseMutation,
  useLazyGetAuthorsQuery,
  useUpdateCourseMutation,
  useDeleteCourseMutation,
  useChangeCourseStatusMutation,
  useLazyGetCourseCsvQuery,
  useFeatureCourseMutation,
} = courseApi;
