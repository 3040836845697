export const en = {
  globals: {
    save: "save",
    chefs: "Chefs",
    photographers: "Photographers",
    submit: "submit",
    back: "back",
    select: "Select",
    serialNo: "S.No",
    title: "Title",
    description: "Description",
    price: "Price",
    status: "Status",
    actions: "Actions",
    name: "Name",
    free: "Free",
    yes: "Yes",
    no: "No",
    found: "Found",
    edit: "Edit",
    delete: "Delete",
    add: "Add",
    view: "View",
    paid: "Paid",
    english: "(English)",
    arabic: "(Arabic)",
    searchHere: "Search here",
    image: "Image",
    export: "Export CSV",
    areYouSure: "Are you sure to delete this",
    request: "Request",
    remove: "Remove",
    asterisk: "*",
  },
  auth: {
    emailId: "Email Address",
    password: "Password",
    remember: "Remember me",
    forgotPassword: "Forgot Password",
    oldPass: "Old Password",
    confmPas: "Confirm Password",
    otpVerification: "OTP Verification",
    verify: "Verify Otp",
    resendOtp: "Resend Otp",
    login: "Sign In",
    resetPass: "Reset Password",
    editProfile: "Edit Profile",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    phone: "Phone Number",
    nickName: "Nick Name",
    myProfile: "My Profile",
    changePass: "Change Password",
    logout: "Logout",
    profileStatus: "Profile Status",
    newPass: "New Password",
  },
  dashboard: {
    customers: "Total Customers",
    totalChefs: "Total Chefs",
    totalPhoto: "Total Photographers",
    totalPurchase: "Total Purchases",
    totalCourses: "Total Courses",
    TotalRevenue: "Total Revenue",
    daily: "Daily",
    weekly: "Weekly",
    monthly: "Monthly",
    yearly: "Yearly",
    revenue: "Revenue",
  },
  main: {
    image: "Image",
    addCategory: "Add Category",
    editCategory: "Edit Category",
    catName: "Category Name",
    selectCat: "Select Category",
    bio: "Bio",
    headline: "Headline",
    address: "Address",
    profession: "Select Profession",
    acctDetails: "Account Details",
    acctNo: "A/C Number",
    holder: "Holder Name",
    swift: "Swift Code",
    bank: "Bank Name",
    addSubAdmin: "Add Sub-Admin",
    module: "Module",
    addEdit: "Add/Edit",
    accountStatus: "Account Status",
    viewUser: "View Customer",
    editCustomer: "Edit Customer",
    uploadLimit: "Manage Upload Limit",
    videoSize: "Video Size (in mb)",
    videoLength: "Video Length (in minutes)",
    tax: "Service Tax",
    commission: "Commission",
    fileFormat: "File Format",
    question: "Question",
    answer: "Answer",
    contactSupport: "Contact Support",
    privacyPolicy: "Privacy Policy",
    aboutUs: "About Us",
    terms: "Terms And Conditions",
    changeLang: "Change language",
    notification: "Notification",
    showing: "Showing",
    items: "items",
    dob: "Date of Birth",
    document: "Upload Document",
    active: "Active",
    Inactive: "Inactive",
    uploadedCourse: "Uploaded Courses",
    coupon: "Discount Coupon",
    validFrom: "Valid From",
    validTill: "Valid Till",
    subscription: "Subscription",
    videoLimit: "Free Videos Limit",
    guestUser: "Guest Users",
    nonSubUser: "Non-Subscribed Users",
    message: "Message",
    receiver: "Receiver",
    broadcast: "Broadcast",
    pending: "Pending",
    accepted: "Accepted",
    accept: "Accept",
    rejected: "Rejected",
    reject: "Reject",
    subsReq: "Subscription Required",
    atLeast1Feature: "At least one Feature must be selected",
    loginToCooklify: "Log in to Cooklify Admin",
  },
  sideBar: {
    dashboard: "Dashboard",
    subAdmin: "Manage Sub-Admins",
    customer: "Manage Customers",
    plans: "Manage Subscription Plans",
    merchants: "Manage Chefs/Photographers",
    category: "Manage Categories",
    courses: "Manage Courses",
    freeVideos: "Manage Free Videos",
    promoVideos: "Promotional Videos",
    coupons: "Manage Discount Coupons",
    orders: "Manage Orders",
    noti: "Manage Notifications",
    reports: "Reports and Analytics",
    review: "Manage Reviews",
    cms: "Manage CMS",
    faqs: "Manage FAQs",
    setting: "Settings",
  },
  course: {
    courseName: "Course Name",
    addCourse: "Add Course",
    editCourse: "Edit Course",
    courseTitle: "Course Title",
    category: "Select Category",
    type: "Pricing Type",
    price: "Price",
    merchant: "Select Author/Publisher",
    discount: "Discount (%)",
    cover: "Cover Image",
    addVideo: "Add Video",
    upload: "Upload",
    addMore: "Add More",
    clear: "Clear Filter",
    selectRating: "Select Rating",
    lowToHigh: "Low to High",
    highToLow: "High to Low",
    priceRange: "Select Price Range",
    merchantName: "Merchant Name",
    ratings: "Ratings",
    courseDetail: "Course Details",
    cat: "Category",
    videos: "Videos",
    courses: "Courses",
  },
  error: {
    required: "This field is required",
    imageSize: "Please select a video file smaller than",
    imageType: "Please select a supported video file type",
    imageValidate: "Please select a video shorter than",
    minutes: "minutes",
    validation1: "Please select a valid video",
    maxValidation: "Maximum 80 character are allowed",
    minValidation: "Minimum 3 characters are required",
    noData: "No results found",
    titlereq: "Title is required",
    descReq: "Description is required",
    lessThan: "Please select video less than",
    noFaq: "No faq's listing found",
    pleaseEnter: "Please enter",
    emailReq: "Email is required!",
    validEmail: "Enter a valid email address!",
    firstReq: "First name is required",
    nicReq: "Nick name is required",
    lastReq: "Last name is required",
    phoneReq: "Phone number is required",
    minPhone: "Phone number must be at least 6 digits",
    maxPhone: "Phone number must be at least 16 digits",
    subAdminCreated: "Sub-admin created successfully",
    subAdminUpdate: "Sub-admin updated successfully",
    userUpdate: "User updated successfully",
    statusUpdate: "Status updated successfully",
    subAdminDel: "Sub-admin deleted successfully",
    userDel: "User deleted successfully",
    max100: "Maximum 100 character are allowed",
    max250: "Maximum 250 character are allowed",
    max500: "Maximum 500 character are allowed",
    catAdd: "Category added successfully",
    catUpdate: "Category updated successfully",
    vdoSize: "Please enter video size",
    vdoLength: "Please enter video length",
    settingUpdate: "Settings updated successfully",
    frAllUser: "For all Users",
    frAllMerchant: "For all Merchants",
    faqAdd: "FAQ added successfully",
    faqDel: "FAQ deleted successfully",
    cmsUpdate: "CMS updated successfully",
    messageReq: "Message is required",
    notiAdd: "Notification added successfully",
    notiDel: "Notification deleted successfully",
    discountAdd: "Discount added successfully",
    discountUpdated: "Discount updated successfully",
    merchantDel: "Merchant deleted successfully",
    merchantAdd: "Merchant added successfully",
    merchantUpdate: "Merchant updated successfully",
    logutReq: "Are you sure, you want to logout?",
    courseUpdate: "Course updated successfully",
    courseAdd: "Course Added Successfully",
    courseDel: "Course deleted successfully",
  },
  plans: {
    planName: "Plan Name",
    planValid: "Plan Validity",
    planPrice: "Plan Price",
    planType: "Plan Type",
    userType: "Select User Type",
    feature: "Feature",
    planDetails: "Plan Details",
    quarterly: "Quarterly",
    customers: "Customers",
    chefPhoto: "Chefs/Photographers",
    food: "Food",
    photoShoot: "PhotoShoot",
    both: "Both",
    planFeature: "Plan Features",
    plans: "Plans",
  },
  orders: {
    order: "Orders",
    userName: "User Name",
    userEmail: "User Email",
    courseName: "Course Title",
    coursePrice: "Course Price",
    date: "Date",
    ratingReview: "Review & Ratings",
    ratings: "Ratings",
    chefsName: "Chefs Name",
    photoName: "Photographers Name",
    orderDetails: "Order Details",
    review: "Review",
    subTotal: "Sub Total",
    taxAmount: "Tax Amount",
    grandTotal: "Grand Total",
    paymentDetails: "Payment Details",
    paymentMode: "Payment Mode",
    paymentDate: "Payment Date",
    expiry: "Expiry Date",
  },
  videos: {
    addProVideos: "Add Promotional Video",
    vdo: "Video",
    promotional: "Promotional",
    uploadVideo: "Upload Videos",
    proUploaded: "Promotional video updated successfully",
    proAdd: "Promotional video created successfully",
    vdoAdd: "Video created successfully",
    vdoUpdated: "Video Updated successfully",
    UploadedBy: "Uploaded by",
    vdoDlt: "Video deleted successfully",
  },
};
