// @ts-nocheck
import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  useLazyGetSettingsQuery,
  useUpdateSettingsMutation,
} from "../../services/main";
import { Loader, showError, showToast } from "../../constants";
import { useNavigate } from "react-router-dom";
import { isNumber } from "../../utils/validations";
import useAuth from "../../hooks/useAuth";
import useTranslation from "../../hooks/Translation";

const Settings = () => {
  const userData = useAuth();
  const navigate = useNavigate();
  const translate = useTranslation();
  const [getSettingsQuery, { isLoading }] = useLazyGetSettingsQuery();
  const [updateSetting] = useUpdateSettingsMutation();
  const [settingData, setSettingData] = useState<any>();
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });
  const [loading, setLoading] = useState(false);
  const [planFeatures, setPlanFeatures] = useState([]);
  const [enableSubs, setEnableSubs] = useState([]);
  console.log(enableSubs);
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const getSettings = async () => {
    setLoading(true);
    try {
      const response = await getSettingsQuery({}).unwrap();
      if (response?.statusCode === 200) {
        setLoading(false);
        setSettingData(response?.data[0]);

        const newArr = [
          {
            id: 1,
            name: "MP4",
            enable: response.data[0]?.isMp4Supported,
            editable: true,
          },
          {
            id: 2,
            name: "WebM",
            enable: response.data[0]?.isWebMSupported,
            editable: true,
          },
          {
            id: 3,
            name: "AVI",
            enable: response.data[0]?.isAVISupported,
            editable: true,
          },
        ];

        const subsArr = [
          {
            id: 1,
            name: translate.error.frAllUser,
            enable: response.data[0]?.isUserSubscriptionEnabled,
            editable: true,
          },
          {
            id: 2,
            name: translate.error.frAllMerchant,
            enable: response.data[0]?.isMerchantSubscriptionEnabled,
            editable: true,
          },
        ];
        // planFeatures?.push(newArr[0],newArr[1],newArr[2])

        setPlanFeatures(newArr);
        setEnableSubs(subsArr);
      } else {
        setSettingData([]);
      }
    } catch (error: any) {
      console.log(error?.data?.message || "");
      setSettingData([]);
      setLoading(false);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      transactionFee: settingData?.maxVideoSize || "",
      length: settingData?.maxVideoDuration || "",
      tax: settingData?.tax || "",
      commission: settingData?.adminCommision || "",
      guestUsers: settingData?.maxGuestUserVideos || "",
      nonSub: settingData?.maxNonSubscribedUserVideos || "",
    },

    validationSchema: Yup.object({
      transactionFee: Yup.string()
        .required(translate.error.vdoSize)
        .max(80, "Maximum 80 digits are allowed")
        .min(2, "Minimum 2 digits are required"),
      length: Yup.string()
        .required(translate.error.vdoLength)
        // .max(500, "Maximum 500 character are allowed")
        .min(2, "Minimum 2 digits are required"),
      tax: Yup.string()
        .required("Please enter service tax")
        // .max(500, "Maximum 500 character are allowed")
        .min(2, "Minimum 2 digits are required"),
      commission: Yup.string()
        .required("Please enter service tax")
        // .max(500, "Maximum 500 character are allowed")
        .min(2, "Minimum 2 digits are required"),
      guestUsers: Yup.string()
        .required("Please enter Limit")
        .max(80, "Maximum 80 digits are allowed"),

      nonSub: Yup.string()
        .required("Please enter Limit")
        .max(80, "Maximum 80 digits are allowed"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      if (!planFeatures.some((feature) => feature.enable)) {
        showError("Please select at least one file format");
        formik.setSubmitting(false);
        return;
      }

      const body = {
        maxVideoSize: values?.transactionFee,
        maxVideoDuration: values?.length,
        isMp4Supported: planFeatures[0]?.enable,
        isWebMSupported: planFeatures[1]?.enable,
        isAVISupported: planFeatures[2]?.enable,
        tax: values?.tax,
        adminCommision: values?.commission,
        isMerchantSubscriptionEnabled: enableSubs[1]?.enable,
        isUserSubscriptionEnabled: enableSubs[0]?.enable,
        maxGuestUserVideos: values.guestUsers,
        maxNonSubscribedUserVideos: values.nonSub,
      };

      console.log(body, "add course");

      try {
        const response = await updateSetting({
          body,
        }).unwrap();
        if (response?.statusCode === 200) {
          showToast(translate.error.settingUpdate);
          getSettings();
          formik.resetForm();
          navigate("/settings");
        } else {
          showError(response?.message);
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error);
      }
    },
  });

  const handleSwitchChange = (featureId) => {
    const updatedFeatures = planFeatures.map((feature) => {
      if (feature.id === featureId) {
        return {
          ...feature,
          enable: !feature.enable, // Toggle the enable property
        };
      }
      return feature;
    });
    // Update the planFeatures state with the modified array
    setPlanFeatures(updatedFeatures);
  };

  const handleSubSwitchChange = (subId) => {
    const newSubFeature = enableSubs.map((item) => {
      if (item.id === subId) {
        return {
          ...item,
          enable: !item.enable, // Toggle the enable property
        };
      }
      return item;
    });
    // Update the planFeatures state with the modified array
    setEnableSubs(newSubFeature);
  };

  const checkPermission = () => {
    const permissions = userData?.permission;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex((ele: any) => ele?.name === "Settings");

      if (idx != -1) {
        let data = {
          isAdd: permissions[idx]?.isAdd,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };

  useEffect(() => {
    getSettings();
  }, []);

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);

  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translate.sideBar.setting}</h1>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Typography className="sub_headings" sx={{ paddingBottom: 1 }}>
                {translate.main.uploadLimit}
              </Typography>
              <Grid container spacing={2}>
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  sx={{ paddingBottom: "10px" }}
                >
                  <Typography
                    className="custom_label"
                    sx={{ paddingTop: "10px" }}
                  >
                    {translate.main.videoSize}
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="transactionFee"
                    variant="outlined"
                    fullWidth
                    className="text_field"
                    placeholder={translate.main.videoSize}
                    onChange={(val) => {
                      const inputValue = val.target.value;

                      // Check if the input is a valid number with up to 2 decimals
                      const isValidInput = /^(\d{1,10}(\.\d{0,2})?)?$/.test(
                        inputValue
                      );

                      if (isValidInput) {
                        // Update the form field only if the input is valid
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.transactionFee}
                    helperText={
                      formik.touched.transactionFee &&
                      formik.errors.transactionFee
                    }
                  />
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  sx={{ paddingBottom: "10px" }}
                >
                  <Typography
                    className="custom_label"
                    sx={{ paddingTop: "10px" }}
                  >
                    {translate.main.videoLength}
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="length"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    placeholder={translate.main.videoLength}
                    onChange={(val) => {
                      const inputValue = val.target.value;

                      // Check if the input is a valid number with up to 2 decimals
                      const isValidInput = /^(\d{1,10}(\.\d{0,2})?)?$/.test(
                        inputValue
                      );

                      if (isValidInput) {
                        // Update the form field only if the input is valid
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.length}
                    helperText={formik.touched.length && formik.errors.length}
                  />
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  sx={{ paddingBottom: "10px" }}
                />

                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Typography className="sub_headings">
                    {translate.main.videoLimit}
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  sx={{ paddingBottom: "10px" }}
                >
                  <Typography className="custom_label">
                    {translate.main.guestUser}
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="guestUsers"
                    variant="outlined"
                    fullWidth
                    className="text_field"
                    placeholder="Limit"
                    onChange={(val) => {
                      const inputValue = val.target.value;
                      if (isNumber(inputValue) && inputValue.length <= 10) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.guestUsers}
                    helperText={
                      formik.touched.guestUsers && formik.errors.guestUsers
                    }
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {translate.main.nonSubUser}
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="nonSub"
                    className="text_field"
                    variant="outlined"
                    fullWidth
                    placeholder="Limit"
                    onChange={(val) => {
                      const inputValue = val.target.value;
                      if (isNumber(inputValue) && inputValue.length <= 10) {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.nonSub}
                    helperText={formik.touched.nonSub && formik.errors.nonSub}
                  />
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  sx={{ paddingBottom: "10px" }}
                ></Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  sx={{ paddingBottom: "10px" }}
                >
                  <Typography
                    className="sub_headings"
                    sx={{ paddingBottom: 1 }}
                  >
                    {translate.main.tax}
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="tax"
                    variant="outlined"
                    // className="text_field"
                    fullWidth
                    placeholder={translate.main.tax}
                    onChange={(val) => {
                      const inputValue = val.target.value;

                      // Check if the input is a valid number with up to 2 decimals and a maximum of 10 digits
                      const isValidInput = /^(\d{1,2}(\.\d{0,2})?)?$/.test(
                        inputValue
                      );

                      if (isValidInput) {
                        // Update the form field only if the input is valid
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.tax}
                    helperText={formik.touched.tax && formik.errors.tax}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment className="eye_btn" position="end">
                          %
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  sx={{ paddingBottom: "10px" }}
                >
                  <Typography
                    className="sub_headings"
                    sx={{ paddingBottom: 1 }}
                  >
                    {translate.main.commission}
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="commission"
                    variant="outlined"
                    // className="text_field"
                    fullWidth
                    placeholder={translate.main.commission}
                    onChange={(val) => {
                      const inputValue = val.target.value;

                      // Check if the input is a valid number with up to 2 decimals and a maximum of 10 digits
                      const isValidInput = /^(\d{1,2}(\.\d{0,2})?)?$/.test(
                        inputValue
                      );

                      if (isValidInput) {
                        // Update the form field only if the input is valid
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.commission}
                    helperText={
                      formik.touched.commission && formik.errors.commission
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment className="eye_btn" position="end">
                          %
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  sx={{ paddingBottom: "10px" }}
                ></Grid>
                <Grid
                  item
                  lg={8}
                  md={8}
                  sm={8}
                  xs={12}
                  sx={{ paddingBottom: "10px" }}
                >
                  <Typography
                    className="sub_headings"
                    sx={{ paddingBottom: 2 }}
                  >
                    {translate.main.subscription}
                  </Typography>

                  <TableContainer
                    className="table_container"
                    sx={{ margin: 0 }}
                  >
                    <Box className="heading"></Box>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>{translate.main.subscription}</TableCell>
                          <TableCell>{translate.globals.status}</TableCell>
                          {/* <TableCell>Actions</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {enableSubs.map((row, i) => (
                          <TableRow key={i}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>
                              <Switch
                                {...label}
                                checked={true}
                                size="small"
                                checked={row.enable}
                                onChange={() => handleSubSwitchChange(row?.id)}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    className="sub_headings"
                    sx={{ paddingBottom: 2 }}
                  >
                    {translate.main.fileFormat}
                  </Typography>
                  <TableContainer
                    className="table_container"
                    sx={{ margin: 0 }}
                  >
                    <Box className="heading"></Box>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>{translate.plans.feature}</TableCell>
                          <TableCell>{translate.globals.status}</TableCell>
                          {/* <TableCell>Actions</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {planFeatures.map((row, i) => (
                          <TableRow key={i}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>
                              <Switch
                                {...label}
                                checked={row.enable}
                                onChange={() => handleSwitchChange(row?.id)}
                                size="small"
                              />
                            </TableCell>
                            {/* <TableCell>
                              <Box className="table_actions">
                                <IconButton>
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            </TableCell> */}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
              {hidePermission?.isAdd ? (
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    {translate.globals.save}
                  </Button>
                </div>
              ) : null}
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default Settings;
