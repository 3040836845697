// @ts-nocheck
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  Grid,
  Input,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  useAddCourseMutation,
  useLazyGetAuthorsQuery,
  useLazyGetCourseByIdQuery,
  useUpdateCourseMutation,
} from "../../services/courses";
import {
  useLazyGetAllCategoryQuery,
  useLazyGetSettingsQuery,
} from "../../services/main";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { ChangeEvent, useEffect, useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  getFromStorage,
  Loader,
  showError,
  showToast,
  STORAGE_KEYS,
} from "../../constants";
import { UploadMedia, UploadVideo } from "../../utils/mediaUpload";
import { isNumber, isString } from "../../utils/validations";
import useTranslation from "../../hooks/Translation";

interface Item {
  id: string;
  image: string;
  courseName: string;
  description: string;
}

const CourseAdd = () => {
  const { id } = useParams();
  const translation = useTranslation();
  const getLang = getFromStorage(STORAGE_KEYS.language);
  const [addCourseMutation] = useAddCourseMutation();
  const [allCategory] = useLazyGetAllCategoryQuery();
  const [getAuthorsQuery] = useLazyGetAuthorsQuery();
  const [updateCourse] = useUpdateCourseMutation();
  const [getCourseDetails] = useLazyGetCourseByIdQuery();
  const [error2, setError2] = useState(false);
  const [fileName, setFileName] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [authData, setAuthData] = useState<string>("");
  const [courseData, setCourseData] = useState<any>();

  const [items, setItems] = useState<any>([]);
  console.log(items, "LLLLLL");
  const [priceType, setPriceType] = useState<string>("");
  const [categories, setCategories] = useState<any>();
  const [error, setError] = useState<boolean>(false);
  const [video, setVideo] = useState<string | null>(null);
  const [videoTitle, setVideoTitle] = useState<string>("");
  const [videoTitleAr, setVideoTitleAr] = useState<string>("");
  const [vdoDescription, setVdoDescription] = useState<string>("");
  const [authors, setAuthors] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [thumbnail, setThumbnail] = useState<string>("");
  const [settingQuery] = useLazyGetSettingsQuery();
  const [setting, setSetting] = useState<any>();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      courseTitle: courseData?.title || "",
      courseTitle_ar: courseData?.title_ar || "",
      price: courseData?.price || "0",
      courseDes: courseData?.description || "",

      coverImg: courseData?.coverImage || "",
      discount: courseData?.discount || "0",
    },

    validationSchema: Yup.object({
      courseTitle: Yup.string()
        .required(translation.error.required)
        .max(80, translation.error.maxValidation)
        .min(3, translation.error.minValidation),
      courseTitle_ar: Yup.string()
        .required(translation.error.required)
        .max(80, translation.error.maxValidation)
        .min(3, translation.error.minValidation),
      courseDes: Yup.string()
        .required(translation.error.required)
        .max(500, translation.error.max500)
        .min(3, translation.error.minValidation),

      price: Yup.string().required(translation.error.required),
      // .min(2, "Minimum 2 digits are required"),
      discount: Yup.string(),
      // .min(2, "Minimum 2 digits are required"),
      coverImg: Yup.string().required(translation.error.required),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      const body = {
        title: values?.courseTitle,
        title_ar: values?.courseTitle_ar,
        description: values?.courseDes,

        coverImage: values?.coverImg,
        price: priceType == "1" ? 0 : Number(values?.price),
        merchant: authData,
        category: category,
        pricingType: Number(priceType),
        videos: items,
        discount: priceType == "1" ? 0 : values?.discount || 0,
        language: getLang === "en" ? 1 : 2,
      };

      console.log(body, "add course");

      if (id && id !== "add") {
        try {
          const response = await updateCourse({
            courseId: id,
            body,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast(translation.error.courseUpdate);
            formik.resetForm();
            navigate("/manage-courses");
          } else {
            showError(response?.message);
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      } else {
        try {
          const response = await addCourseMutation(body).unwrap();
          if (response?.statusCode === 200) {
            showToast(translation.error.courseAdd);
            formik.resetForm();
            navigate("/manage-courses");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });
  const [videoDuration, setVideoDuration] = useState<any>(null);
  console.log("videoDuration: ", videoDuration);

  const convertDataUrltoImgUrl = async (base64Image: any) => {
    const byteCharacters = atob(base64Image.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/jpeg" });

    // Create an image URL from the Blob
    const imageUrl = URL.createObjectURL(blob);
    // Now, 'imageUrl' contains a regular image URL that you can use in your application
    const response = await UploadMedia(blob);
    if (response?.statusCode === 200) {
      // formik.setFieldValue("coverImg", response?.data);
      setThumbnail(response?.data);
    } else {
      showError(response?.message);
    }
  };

  const handleVideoUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    console.log(file);

    if (file) {
      const fileSizeLimit = setting?.maxVideoSize * 1024 * 1024; // 6 MB in bytes

      if (file.size > fileSizeLimit) {
        showError(
          `Please select a video file smaller than ${setting?.maxVideoSize} MB`
        );
        event.target.value = "";
        return;
      }

      const isWebMSupported = setting?.isWebMSupported ?? false;
      const isMp4Supported = setting?.isMp4Supported ?? false;
      const isAVISupported = setting?.isAVISupported ?? false;

      // Check if the selected file type is supported
      const supportedFormats = [];
      if (isWebMSupported) {
        supportedFormats.push("WebM");
      }
      if (isMp4Supported) {
        supportedFormats.push("MP4");
      }
      if (isAVISupported) {
        supportedFormats.push("AVI");
      }

      const allowedTypes = [];
      if (isWebMSupported) {
        allowedTypes.push("video/webm");
      }
      if (isMp4Supported) {
        allowedTypes.push("video/mp4");
      }
      if (isAVISupported) {
        allowedTypes.push("video/x-msvideo");
      }

      if (allowedTypes.length === 0 || !allowedTypes.includes(file.type)) {
        showError(`Please upload ${supportedFormats.join(", ")} formats only`);
        event.target.value = "";
        console.log("cleared");
        return;
      }

      setLoading(true);
      // Create a video element
      const video = document.createElement("video");
      video.preload = "metadata";
      // Set the video source
      video.src = URL.createObjectURL(file);

      // Listen for the loadedmetadata event
      video.addEventListener("loadeddata", async () => {
        const duration = video.duration;
        console.log(duration, "sdfdsfdfsdf");
        const reqDuration = setting?.maxVideoDuration * 60;
        console.log(reqDuration);

        if (duration > reqDuration) {
          showError(
            `Please select a video shorter than ${setting?.maxVideoDuration} minutes`
          );
          setLoading(false);
          event.target.value = "";
          video.remove();
          return;
        }

        setVideoDuration(Math.ceil(duration));
        // Set the currentTime to a suitable position for capturing a thumbnail
        video.currentTime = Math.min(1, duration); // Capture thumbnail after 1 second

        // Wait for the seeked event
        video.addEventListener("seeked", async () => {
          // Create a canvas element
          const canvas = document.createElement("canvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;

          // Draw the current frame onto the canvas
          const context = canvas.getContext("2d");
          context?.drawImage(video, 0, 0, canvas.width, canvas.height);

          // Convert the canvas to a data URL representing the thumbnail
          const base64Image = canvas.toDataURL("image/jpeg");
          convertDataUrltoImgUrl(base64Image);

          if (file.type.startsWith("video/")) {
            const res = await UploadVideo(file);
            if (res?.statusCode === 200) {
              setVideo(res?.data);
              setLoading(false);
            } else {
              setLoading(false);
              showError("Invalid file format : only .mp4 files are allowed");
            }
          } else {
            // Display an error message for non-image files
            showError("Please select a valid video");
          }

          // Clean up: remove the video and canvas elements
          video.remove();
          canvas.remove();
        });
      });

      // Append the video element to the document (necessary for triggering events)
      document.body.appendChild(video);
    }
  };

  const handleAddClick = () => {
    if (!video || !videoTitle || !videoTitleAr || !vdoDescription) {
      setError2(true);
    }
    if (
      videoTitle.trim() !== "" &&
      videoTitleAr.trim() !== "" &&
      vdoDescription.trim() !== ""
    ) {
      const newItem = {
        // _id: Date.now().toString(),
        title: videoTitle,
        title_ar: videoTitleAr,
        description: vdoDescription,
        link: video,
        videoType: 1,
        duration: videoDuration,
        thumbnail: thumbnail,
      };

      setItems([newItem, ...items]);

      // Clear the form fields after adding the item
      setVideo("");
      setVideoTitle("");
      setVideoTitleAr("");
      setVdoDescription("");

      setError2(false);
    }
  };

  const handleDeleteClick = (_id: string) => {
    const updatedItems = items.filter((item: any) => item._id !== _id);
    setItems(updatedItems);
  };

  const navigate = useNavigate();

  const handleClose = () => {
    setShowAlert(false);
  };

  //for image
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    // Check if a file is selected
    if (file) {
      // Check if the selected file is an image
      if (file.type.startsWith("image/")) {
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          formik.setFieldValue("coverImg", res?.data);
        } else {
          showError(res?.message);
        }
      } else {
        // Display an error message for non-image files
        showError("Please select a valid image file (png or jpeg).");
      }
    }
  };

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };

  const handleAuthorsChange = (event: SelectChangeEvent) => {
    setAuthData(event.target.value as string);
  };

  const handlePricingChange = (event: SelectChangeEvent) => {
    setPriceType(event.target.value as string);
  };

  const getAllCate = async () => {
    try {
      const response = await allCategory({
        query: "",
        page: 1,
      }).unwrap();
      if (response?.statusCode === 200) {
        setCategories(response?.data?.req || []);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const getAllAuthors = async () => {
    try {
      const response = await getAuthorsQuery({}).unwrap();
      if (response?.statusCode === 200) {
        setAuthors(response?.data?.data || []);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const getCoursesById = async () => {
    try {
      const response = await getCourseDetails({
        courseId: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setCourseData(response?.data);
        setItems(response?.data?.videos);
        setAuthData(response?.data?.merchant?._id);
        setPriceType(response?.data?.pricingType);
        setCategory(response?.data?.category?._id);
      } else {
        setCourseData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const getAllSettings = async () => {
    try {
      const response = await settingQuery({}).unwrap();
      if (response?.statusCode === 200) {
        setSetting(response?.data[0]);
      } else {
        setSetting("");
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getAllCate();
  }, []);

  useEffect(() => {
    getAllAuthors();
  }, []);

  useEffect(() => {
    if (id && id !== "add") {
      getCoursesById();
    }
  }, []);

  useEffect(() => {
    getAllSettings();
  }, []);

  return (
    <>
      <MainContainer>
        <Loader isLoad={loading} />
        <div className="main_loyout">
          <div className="dashboard">
            <h1>
              {id
                ? translation?.course?.editCourse
                : translation?.course?.addCourse}
            </h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-courses");
              }}
            >
              {translation?.globals?.back}
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">
                      {`${translation?.course?.courseTitle} ${translation.globals.english}`}
                      <span className="asterisk">
                        {translation.globals.asterisk}
                      </span>
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="courseTitle"
                      variant="outlined"
                      className="text_field"
                      fullWidth
                      placeholder={`${translation?.course?.courseTitle} ${translation.globals.english}`}
                      // onChange={(val) => {
                      //   if (
                      //     val.target.value === " " ||
                      //     val.target.value === "."
                      //   ) {
                      //   } else if (isString(val.target.value)) {
                      //     formik.handleChange(val);
                      //   }
                      // }}
                      onChange={(val) => {
                        if (val.target.value) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.courseTitle}
                      helperText={
                        formik.touched.courseTitle && formik.errors.courseTitle
                      }
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">
                      {translation?.course?.courseTitle}{" "}
                      {translation.globals.arabic}
                      <span className="asterisk">
                        {translation.globals.asterisk}
                      </span>
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="courseTitle_ar"
                      variant="outlined"
                      className="text_field"
                      fullWidth
                      placeholder={`${translation?.course?.courseTitle} ${translation.globals.arabic}`}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onChange={(val) => {
                        if (val.target.value) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.courseTitle_ar}
                      helperText={
                        formik.touched.courseTitle_ar &&
                        formik.errors.courseTitle_ar
                      }
                    ></TextField>
                  </Grid>

                  <Grid item lg={6} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation?.course?.category}
                      <span className="asterisk">
                        {translation.globals.asterisk}
                      </span>
                    </Typography>
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        className="select_div"
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={category}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handleCategoryChange}
                      >
                        <MenuItem value="" disabled>
                          {translation.globals.select}
                        </MenuItem>
                        {categories?.length
                          ? categories?.map((item: any) => (
                              <MenuItem key={item?._id} value={item?._id}>
                                {item?.name || ""}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                      {error && !courseData?.category?._id && !category ? (
                        <h6 className="err_msg">Please select category</h6>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation?.course?.type}
                      <span className="asterisk">
                        {translation.globals.asterisk}
                      </span>
                    </Typography>
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        className="select_div"
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={priceType}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handlePricingChange}
                      >
                        <MenuItem value="" disabled>
                          {translation?.globals?.select}
                        </MenuItem>
                        <MenuItem value="1">
                          {translation?.globals?.free}
                        </MenuItem>
                        <MenuItem value="2">
                          {translation?.globals?.paid}
                        </MenuItem>
                      </Select>
                      {error && !courseData?.pricingType && !priceType ? (
                        <h6 className="err_msg">Please select pricing type</h6>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">
                      {translation?.course?.price}
                      <span className="asterisk">
                        {translation.globals.asterisk}
                      </span>
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="price"
                      variant="outlined"
                      fullWidth
                      placeholder={translation?.course?.price}
                      disabled={priceType == "1"}
                      className="text_field"
                      onChange={(event) => {
                        const inputValue = event.target.value;

                        // Check if the input is a valid number with up to 2 decimals and a maximum of 10 digits
                        const isValidInput = /^(\d{1,10}(\.\d{0,2})?)?$/.test(
                          inputValue
                        );

                        if (isValidInput && parseFloat(inputValue) !== 0) {
                          // Update the form field only if the input is valid and not zero
                          formik.handleChange(event);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={priceType == "1" ? "0" : formik.values.price}
                      helperText={
                        priceType === "1"
                          ? null
                          : formik.touched.price && formik.errors.price
                      }
                    />
                  </Grid>

                  <Grid item lg={6} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translation?.course?.merchant}
                      <span className="asterisk">
                        {translation.globals.asterisk}
                      </span>
                    </Typography>
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        className="select_div"
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={authData}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handleAuthorsChange}
                      >
                        <MenuItem value="" disabled>
                          {translation?.globals?.select}
                        </MenuItem>
                        {authors?.length
                          ? authors?.map((item: any) => (
                              <MenuItem key={item?._id} value={item?._id}>
                                {`${item?.firstName} ( ${item?.nickName} )`}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                      {error && !courseData?.merchant && !authData ? (
                        <h6 className="err_msg">
                          Please select author/publisher
                        </h6>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">
                      {translation?.course?.discount}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="discount"
                      variant="outlined"
                      fullWidth
                      disabled={priceType == "1"}
                      placeholder={translation?.course?.discount}
                      className="text_field"
                      onChange={(event) => {
                        const inputValue = event.target.value;

                        // Check if the input is a valid number with up to 2 decimals and a maximum of 10 digits
                        const isValidInput = /^(\d{1,2}(\.\d{0,2})?)?$/.test(
                          inputValue
                        );

                        if (isValidInput) {
                          // Update the form field only if the input is valid
                          formik.handleChange(event);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={priceType === "1" ? "0" : formik.values.discount}
                      helperText={
                        priceType === "1"
                          ? null
                          : formik.touched.discount && formik.errors.discount
                      }
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={4} sm={4} xs={12}></Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {`${translation?.globals?.description} `}
                      <span className="asterisk">
                        {translation.globals.asterisk}
                      </span>
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type="text"
                      placeholder={`${translation?.globals?.description}`}
                      fullWidth
                      multiline
                      minRows={4}
                      maxRows={4}
                      name="courseDes"
                      variant="outlined"
                      id="description"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.courseDes}
                      helperText={
                        formik.touched.courseDes && formik.errors.courseDes
                      }
                    />
                  </Grid>

                  <Grid item lg={2} md={2} sm={2} xs={12} />
                  <Grid item lg={5} md={4} sm={4} xs={4}>
                    <Typography className="custom_label">
                      {translation?.course?.cover}
                      <span className="asterisk">
                        {translation.globals.asterisk}
                      </span>
                    </Typography>
                    {formik.values.coverImg ? (
                      <div
                        className="upload_image_preview2"
                        style={{ height: "158px" }}
                      >
                        <CardMedia
                          component="img"
                          image={formik.values.coverImg}
                          alt="photo"
                        />
                        <CancelIcon
                          onClick={() => {
                            formik.setFieldValue("coverImg", "");
                          }}
                        />
                      </div>
                    ) : (
                      <Box
                        className="upload_image_bnr"
                        style={{ height: "158px" }}
                      >
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn">
                            {/* <img
                                src={
                                  image
                                    ? image
                                    : "/static/images/user_placeholder.png"
                                }
                                alt=""
                              /> */}
                            <AddIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                    {error && !formik.values.coverImg ? (
                      <h6 className="err_msg">This field is required</h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>

                <h1
                  className="mn_hdng"
                  style={{ fontSize: "20px", marginTop: 30 }}
                >
                  {translation?.course?.addVideo}
                </h1>

                <div className="card_main">
                  {items &&
                    items.map((item: any, i: number) => (
                      <div key={item?._id} className="card">
                        <Button onClick={() => handleDeleteClick(item?._id)}>
                          <DeleteIcon />
                        </Button>

                        {item?.link && (
                          <figure>
                            <video controls width="100%" height="100%">
                              <source src={item?.link} type="video/mp4" />
                            </video>
                          </figure>
                        )}
                        <div className="desc">
                          <h4>
                            {getLang === "en" ? item?.title : item?.title_ar}{" "}
                          </h4>
                          <p>{item?.description || ""} </p>
                          {/* <p className="time">Oct 22, 2023</p> */}
                        </div>
                      </div>
                    ))}
                </div>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={4} sm={4} xs={12}>
                    <Typography className="custom_label">
                      {translation?.course?.upload}
                      <span className="asterisk">
                        {translation.globals.asterisk}
                      </span>
                    </Typography>

                    {video ? (
                      <div className="upload_image_preview2">
                        {/* <CardMedia component="img" ={video} alt="photo" /> */}
                        <video controls width="100%" height="100%">
                          <source src={video} type="video/mp4" />
                        </video>
                        <CancelIcon onClick={() => setVideo("")} />
                      </div>
                    ) : (
                      <Box className="upload_image_bnr">
                        <label htmlFor="icon-button-file-vdo">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file-vdo"
                            type="file"
                            inputProps={{
                              accept: "video/*,video/webm",
                            }}
                            onChange={handleVideoUpload}
                          />
                          <Button component="span" className="upload_image_btn">
                            <AddIcon />
                          </Button>
                        </label>
                      </Box>
                    )}

                    {error2 && !video ? (
                      <h6 className="err_msg">This field is compulsory.</h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}></Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {`${translation?.globals?.title} ${translation.globals.english}`}
                      <span className="asterisk">
                        {translation.globals.asterisk}
                      </span>
                    </Typography>
                    <TextField
                      hiddenLabel
                      type="text"
                      name="courseName"
                      variant="outlined"
                      fullWidth
                      placeholder={`${translation?.globals?.title} ${translation.globals.english}`}
                      value={videoTitle}
                      onChange={(e) => setVideoTitle(e.target.value)}
                    />
                    {error2 && !videoTitle ? (
                      <h6 className="err_msg">This field is compulsory.</h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {`${translation?.globals?.title} ${translation.globals.arabic}`}
                      <span className="asterisk">
                        {translation.globals.asterisk}
                      </span>
                    </Typography>
                    <TextField
                      hiddenLabel
                      type="text"
                      name="courseName"
                      variant="outlined"
                      fullWidth
                      placeholder={`${translation?.globals?.title} ${translation.globals.arabic}`}
                      value={videoTitleAr}
                      onChange={(e) => setVideoTitleAr(e.target.value)}
                    />
                    {error2 && !videoTitle ? (
                      <h6 className="err_msg">This field is compulsory.</h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <div style={{ marginTop: 20 }} />
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {`${translation?.globals?.description} `}
                      <span className="asterisk">
                        {translation.globals.asterisk}
                      </span>
                    </Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type="text"
                      placeholder={`${translation?.globals?.description}`}
                      fullWidth
                      multiline
                      minRows={4}
                      maxRows={4}
                      name="description"
                      variant="outlined"
                      id="description"
                      value={vdoDescription}
                      onChange={(e) => setVdoDescription(e.target.value)}
                    />
                    {error2 && !vdoDescription ? (
                      <h6 className="err_msg">This field is compulsory.</h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>

                <Grid item lg={8} md={8} sm={8} xs={8} />

                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{
                    paddingTop: 2,
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {items?.length < 100 ? (
                    <div
                      className="btn-like-div"
                      onClick={handleAddClick}
                      color="primary"
                    >
                      {translation?.course?.addMore}
                    </div>
                  ) : null}
                </Grid>

                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary dsabl_btn"
                    onClick={() => setError(true)}
                    disabled={items?.length == 0}
                  >
                    {translation.globals.save}
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default CourseAdd;
