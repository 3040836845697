import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useLazyGetAllOrdersQuery } from "../../services/main";
import "../users/Users.scss";
import { Loader, showError } from "../../constants";
import { LazyLoadImage } from "react-lazy-load-image-component";
import useTranslation from "../../hooks/Translation";

const OrderDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const translate = useTranslation();
  const [orderDetails] = useLazyGetAllOrdersQuery();
  const [orderData, setOrderData] = useState("");
  const [value, setValue] = React.useState(0);
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);

  const getOrderById = async () => {
    setLoading(true);
    try {
      const response = await orderDetails({
        page: 1,
        query: "",
        startDate: "",
        endDate: "",
      }).unwrap();
      if (response?.statusCode === 200) {
        setLoading(false);
        setOrderData(response?.data?.orders);
        const obj = response?.data?.orders?.find(
          (item: any) => item?._id === id
        );
        setData(obj);
      } else {
        setOrderData("");
      }
    } catch (error: any) {
      setLoading(false);
      showError(error?.data?.message || "");
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    getOrderById();
  }, []);

  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translate.orders.orderDetails}</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-orders");
            }}
          >
            {translate.globals.back}
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2}>
              <Grid item lg={2} md={2} sm={6} xs={12} sx={{ paddingBottom: 5 }}>
                <LazyLoadImage
                  alt="image"
                  style={{
                    height: 200,
                    borderRadius: 8,
                    width: 300,
                  }}
                  src={data?.course?.coverImage}
                  effect="blur"
                  // scrollPosition={scrollPosition}
                />
              </Grid>
              <Grid item xs={16} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translate.orders.userName}
                      </Typography>
                      <Typography component="p">
                        {`${data?.user?.firstName || "-"} ${
                          data?.user?.lastName || ""
                        }
                          `}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translate.orders.chefsName}
                      </Typography>
                      <Typography component="p">
                        {`${data?.merchant?.firstName || "-"}`}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translate.orders.courseName}
                      </Typography>
                      <Typography component="p">
                        {data?.course?.title || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translate.orders.subTotal}
                      </Typography>
                      <Typography component="p">
                        {`${
                          data?.subTotal -
                          (data?.subTotal * data?.discount) / 100
                            ? "$"
                            : ""
                        } ${
                          data?.subTotal -
                            (data?.subTotal * data?.discount) / 100 || "Free"
                        }`}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translate.main.coupon}
                      </Typography>
                      <Typography component="p">
                        {`$ ${
                          data?.discountCoupon?.discountPercentage / 100 || "0"
                        } `}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translate.orders.taxAmount}
                      </Typography>
                      <Typography component="p">
                        {`${data?.taxAmount ? "$" : ""} ${
                          data?.taxAmount > 0
                            ? data?.taxAmount.toFixed(2)
                            : "Free"
                        }`}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translate.orders.grandTotal}
                      </Typography>
                      <Typography component="p">
                        {`${data?.grandTotal ? "$" : ""} ${
                          data?.grandTotal > 0
                            ? data?.grandTotal.toFixed(2)
                            : "Free"
                        }`}
                      </Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <h3 style={{ color: "#051140" }}>
                      {translate.orders.paymentDetails}
                    </h3>
                    <Grid container spacing={3}>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">
                            {translate.orders.paymentMode}
                          </Typography>
                          <Typography component="p">-</Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">
                            {translate.orders.paymentDate}
                          </Typography>
                          <Typography component="p">-</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </MainContainer>
  );
};

export default OrderDetails;
