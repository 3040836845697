import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  NativeSelect,
  Rating,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import SearchBar from "../../../components/SearchBar";
import DeleteIcon from "@mui/icons-material/Delete";
import MainContainer from "../../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";
import { isValidInput } from "../../../utils/validations";
import {
  useAddPlanDiscountMutation,
  useLazyGetDiscountByIdQuery,
  useUpdatePlanDiscountMutation,
} from "../../../services/discount";
import { useLazyGetAllSubscriptionsQuery } from "../../../services/subscription";
import { Loader, showError, showToast } from "../../../constants";
import { Discount } from "../../../types/General";
import Pagination from "../../../components/Pagination";
import useTranslation from "../../../hooks/Translation";

const AddDiscountCode = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const translate = useTranslation();
  const [getSubscriptionsData] = useLazyGetAllSubscriptionsQuery();
  const [couponById] = useLazyGetDiscountByIdQuery();
  const [addDiscount] = useAddPlanDiscountMutation();
  const [updateDiscount] = useUpdatePlanDiscountMutation();
  const [receiver, setReceiver] = useState<string>("1");
  const [couponData, setCouponData] = useState<Discount>();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [subsData, setSubsData] = useState<any>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedCourses, setSelectedCourses] = useState<string[]>([]);
  console.log(moment(couponData?.valideFrom).format("yyyy-MM-DD"));

  const handleCheckboxChange = (courseId: string) => {
    if (selectedCourses.includes(courseId)) {
      setSelectedCourses((prevSelected) =>
        prevSelected.filter((id) => id !== courseId)
      );
    } else {
      setSelectedCourses((prevSelected) => [...prevSelected, courseId]);
    }
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  let totalPages = Math.ceil(totalCount / 10);

  const getSubscriptions = async () => {
    setLoading(true);
    try {
      const response = await getSubscriptionsData({
        query: debouncedSearchTerm.trim(),
        page: page,
        userType: Number(receiver),
        planType: "",
        validity: "",
      }).unwrap();
      if (response?.statusCode === 200) {
        setLoading(false);
        setSubsData(response?.data?.data);
        setTotalCount(response?.data?.count);
      } else {
        setSubsData([]);
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      showError(error?.data?.message || "");
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: couponData?.code || "",
      discount: couponData?.discountPercentage || "",
      validFrom: moment(couponData?.valideFrom).format("yyyy-MM-DD") || "",
      validTill: moment(couponData?.valideTill).format("yyyy-MM-DD") || "",
    },
    validationSchema: Yup.object({
      code: Yup.string()
        .required("This field is required")
        .max(20, "Maximum 20 character are allowed")
        .min(3, "Minimum 2 characters are required"),
      discount: Yup.string()
        .required("This field is required")
        .max(20, "Maximum 20 character are allowed"),

      validFrom: Yup.string().required("This field is required"),
      validTill: Yup.string().required("This field is required"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      if (selectedCourses.length === 0) {
        showError("Please select at least one course");
        formik.setSubmitting(false);
        return;
      }

      const body = {
        code: values.code,
        subscriptions: selectedCourses,
        discountPercentage: values.discount,
        discountType: 2,
        valideFrom: values.validFrom,
        valideTill: values.validTill,
      };

      console.log(body);

      if (id && id !== "add") {
        try {
          const response = await updateDiscount({
            discountId: id,
            body,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast("Discount updated successfully");
            navigate("/manage-subscription", { state: "discount" });
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      } else {
        try {
          const response = await addDiscount(body).unwrap();
          if (response?.statusCode === 200) {
            showToast("Discount Added Successfully");
            formik.resetForm();
            navigate("/manage-subscription", { state: "discount" });
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  const getCouponDetail = async (id: string | undefined) => {
    try {
      const res = await couponById({ discountId: id }).unwrap();
      if (res?.statusCode === 200) {
        setCouponData(res?.data);
        setSelectedCourses(res?.data?.subscriptions);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const handleReceiverChange = (event: SelectChangeEvent) => {
    setReceiver(event.target.value as string);
  };

  useEffect(() => {
    getSubscriptions();
  }, [receiver, page, debouncedSearchTerm]);

  useEffect(() => {
    if (id && id !== "add") {
      getCouponDetail(id);
    }
  }, []);

  return (
    <>
      <MainContainer>
        <Loader isLoad={loading} />
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">
              {id ? translate.globals.edit : translate.globals.add}{" "}
              {translate.main.coupon}
            </h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-subscription", { state: "discount" });
              }}
            >
              {translate.globals.back}
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translate.main.coupon}
                      <span className="asterisk">
                        {translate.globals.asterisk}
                      </span>
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="code"
                      variant="outlined"
                      className="text_field"
                      fullWidth
                      placeholder={translate.main.coupon}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.code}
                      helperText={formik.touched.code && formik.errors.code}
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translate.course.discount}
                      <span className="asterisk">
                        {translate.globals.asterisk}
                      </span>
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="discount"
                      className="text_field"
                      variant="outlined"
                      fullWidth
                      placeholder="Discount"
                      onChange={(event) => {
                        const inputValue = event.target.value;

                        // Check if the input is a valid number with up to 2 decimals and a maximum of 10 digits
                        const isValidInput =
                          /^([1-9]\d{0,1}(\.\d{0,2})?)?$/.test(inputValue);

                        if (isValidInput) {
                          // Update the form field only if the input is valid
                          formik.handleChange(event);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.discount}
                      helperText={
                        formik.touched.discount && formik.errors.discount
                      }
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translate.main.validFrom}
                      <span className="asterisk">
                        {translate.globals.asterisk}
                      </span>
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"date"}
                      name="validFrom"
                      className="text_field"
                      variant="outlined"
                      fullWidth
                      placeholder={translate.main.validFrom}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.validFrom}
                      helperText={
                        formik.touched.validFrom && formik.errors.validFrom
                      }
                      inputProps={{
                        min: new Date().toISOString().split("T")[0],
                      }}
                      onKeyDown={(e) => e.preventDefault()} // Prevent keyboard input
                      InputProps={{ disableUnderline: true }}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translate.main.validTill}
                      <span className="asterisk">
                        {translate.globals.asterisk}
                      </span>
                    </Typography>

                    <TextField
                      hiddenLabel
                      type={"date"}
                      name="validTill"
                      className="text_field"
                      variant="outlined"
                      disabled={!formik.values.validFrom}
                      fullWidth
                      placeholder={translate.main.validTill}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.validTill}
                      helperText={
                        formik.touched.validTill && formik.errors.validTill
                      }
                      inputProps={{
                        min: formik.values.validFrom,
                      }}
                      onKeyDown={(e) => e.preventDefault()} // Prevent keyboard input
                      InputProps={{ disableUnderline: true }}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translate.plans.planType}
                      <span className="asterisk">
                        {translate.globals.asterisk}
                      </span>
                    </Typography>
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        fullWidth
                        className="select_div"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={receiver}
                        // multiple
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handleReceiverChange}
                      >
                        <MenuItem value="" disabled>
                          {translate.globals.select}
                        </MenuItem>
                        <MenuItem value="1">
                          {translate.plans.customers}
                        </MenuItem>
                        <MenuItem value="2">
                          {translate.plans.chefPhoto}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <div style={{ paddingTop: 8 }}>
                  <h3>{translate.plans.plans}</h3>
                </div>

                <Box className="cards_header">
                  <SearchBar
                    value={searchTerm}
                    searchTerm={searchTerm}
                    onCross={() => setSearchTerm("")}
                    setDebouncedSearchTerm={setDebouncedSearchTerm}
                    onChange={(val: any) => {
                      if (isValidInput(val.target.value)) {
                        setSearchTerm(val.target.value);
                      }
                    }}
                  />
                </Box>

                <TableContainer className="table_container">
                  <Box className="heading"></Box>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          {translate.globals.serialNo}
                        </TableCell>
                        <TableCell>{translate.plans.planName}</TableCell>
                        <TableCell>{translate.plans.planValid}</TableCell>
                        <TableCell>{translate.plans.planPrice}</TableCell>
                        <TableCell>{translate.plans.planType}</TableCell>
                        <TableCell>{translate.globals.select}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {subsData?.length > 0
                        ? subsData?.map((row: any, i: any) => (
                            <TableRow>
                              <TableCell align="center">{i + 1}</TableCell>
                              <TableCell>{row.name}</TableCell>
                              <TableCell>
                                {row?.validity === 1
                                  ? translate.dashboard.yearly
                                  : row?.validity === 2
                                  ? translate.plans.quarterly
                                  : translate.dashboard.monthly}
                              </TableCell>
                              <TableCell>$ {row?.price || "-"}</TableCell>
                              <TableCell>
                                {row?.planType === 1
                                  ? translate.plans.food
                                  : row?.planType === 2
                                  ? translate.plans.photoShoot
                                  : translate.plans.both}
                              </TableCell>
                              <TableCell align="center">
                                <Checkbox
                                  checked={selectedCourses.includes(row._id)}
                                  onChange={() => handleCheckboxChange(row._id)}
                                />
                              </TableCell>
                            </TableRow>
                          ))
                        : ""}
                    </TableBody>
                  </Table>
                </TableContainer>

                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    {translate.globals.save}
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
          {subsData?.length > 0 ? (
            <Pagination
              setPage={setPage}
              module={subsData}
              page={page}
              onPageChange={onPageChange}
              totalPages={totalPages}
            />
          ) : (
            ""
          )}
        </div>
      </MainContainer>
    </>
  );
};

export default AddDiscountCode;
