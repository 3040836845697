import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Rating,
  Typography,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useLazyGetCourseByIdQuery } from "../../services/courses";
import "../users/Users.scss";
import {
  getFromStorage,
  Loader,
  showError,
  STORAGE_KEYS,
} from "../../constants";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import useTranslation from "../../hooks/Translation";

const CourseDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const translate = useTranslation();
  const [getCourseDetails, { isLoading }] = useLazyGetCourseByIdQuery();
  const [courseData, setCourseData] = useState<any>();
  const getLang = getFromStorage(STORAGE_KEYS.language);
  console.log(courseData, "----");
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getCoursesById = async () => {
    try {
      const response = await getCourseDetails({
        courseId: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setCourseData(response?.data);
      } else {
        setCourseData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getCoursesById();
  }, []);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translate?.course?.courseDetail}</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-courses");
            }}
          >
            {translate?.globals?.back}
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid item lg={2} md={2} sm={6} xs={12}>
              <LazyLoadImage
                alt="profile"
                style={{
                  height: 200,
                  borderRadius: 8,
                  width: 300,
                }}
                src={courseData?.coverImage}
                effect="blur"
                // scrollPosition={scrollPosition}
              />
            </Grid>
            <Grid container spacing={2} sx={{ paddingTop: 2 }}>
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translate?.globals?.title}
                      </Typography>
                      <Typography component="p">
                        {courseData?.title || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translate?.globals?.price}
                      </Typography>
                      <Typography component="p">
                        {`${courseData?.price ? "$" : ""} ${
                          courseData?.price || "Free"
                        }`}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translate?.course?.ratings}
                      </Typography>

                      <Rating
                        name="read-only"
                        value={courseData?.ratings || 0}
                        readOnly
                      />
                    </Box>
                  </Grid>

                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        {translate?.course?.cat}
                      </Typography>
                      <Typography component="p">
                        {courseData?.category?.name || "-"}
                      </Typography>
                    </Box>
                  </Grid>
                  {/* <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        Author/Publisher Name
                      </Typography>
                      <Typography component="p">John</Typography>
                    </Box>
                  </Grid> */}
                  <Grid item mt={3} xs={12} className="view_box_list">
                    <Grid container spacing={3}>
                      <Grid item lg={8} md={8} sm={8} xs={12}>
                        <Box>
                          <Typography component="h5">
                            {translate?.globals?.description}
                          </Typography>
                          <Typography component="p">
                            {courseData?.description || "-"}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item mt={3} xs={12}>
                    <h3>{translate?.course?.videos}</h3>
                  </Grid>
                  <div
                    className="card_main"
                    style={{ width: "100%", margin: "0px 20px 20px 20px " }}
                  >
                    {courseData?.videos &&
                      courseData?.videos.map((item: any, i: number) => (
                        <div key={item?._id} className="card">
                          {item?.link && (
                            <figure>
                              <video controls width="100%" height="100%">
                                <source src={item?.link} type="video/mp4" />
                              </video>
                            </figure>
                          )}
                          <div className="desc">
                            <h4>{item?.title || "-"}</h4>
                            <p>{item?.description || "-"}</p>
                            {/* <p className="time">Oct 22, 2023</p> */}
                          </div>
                        </div>
                      ))}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </MainContainer>
  );
};

export default CourseDetails;
