import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { isValidInput } from "../../utils/validations";
import useAuth from "../../hooks/useAuth";
import useTranslation from "../../hooks/Translation";
import {
  useDeleteNotificationMutation,
  useLazyGetNotificationsQuery,
} from "../../services/main";
import { Loader, showError, showToast } from "../../constants";
import WarnModal from "../../components/WarnModal";
import Pagination from "../../components/Pagination";
import moment from "moment";

const ManageNotifications = () => {
  const userData = useAuth();
  const navigate = useNavigate();
  const translate = useTranslation();
  const [getNotification] = useLazyGetNotificationsQuery();
  const [deleteNotification] = useDeleteNotificationMutation();
  const [notificationData, setNotificationData] = useState<any>();
  const [selectedId, setSelectedId] = useState<string>("");
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState<boolean>(false);
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });
  const [loading, setLoading] = useState(false);

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  function createData(name: string, title: string, message: string) {
    return { name, title, message };
  }

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  let totalPages = Math.ceil(totalCount / 10);

  const checkPermission = () => {
    const permissions = userData?.permission;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.name === "Manage Notifications"
      );

      if (idx != -1) {
        let data = {
          isAdd: permissions[idx]?.isAdd,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteNotification({
        notificationId: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        showToast(translate.error.notiDel);
        getNotifications();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const getNotifications = async () => {
    setLoading(true);
    try {
      const response = await getNotification({
        query: debouncedSearchTerm.trim(),
        page: page,
      }).unwrap();
      if (response?.statusCode === 200) {
        setLoading(false);
        setNotificationData(response?.data?.data || []);
        setTotalCount(response?.data?.count);
      } else {
        setNotificationData([]);
      }
    } catch (error: any) {
      setLoading(false);
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);

  useEffect(() => {
    getNotifications();
  }, [page, debouncedSearchTerm]);

  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translate.sideBar.noti}</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />

            {hidePermission?.isAdd ? (
              <Box className="cards_header_right">
                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("/manage-notifications/add")}
                >
                  {`${translate.globals.add} ${translate.main.notification}`}
                </Button>
              </Box>
            ) : null}
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {translate.globals.serialNo}
                  </TableCell>

                  <TableCell>{translate.globals.title}</TableCell>
                  <TableCell>{translate.main.message}</TableCell>
                  <TableCell>{translate.orders.date}</TableCell>
                  <TableCell align="center">
                    {translate.globals.delete}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notificationData?.length > 0 ? (
                  notificationData?.map((row: any, i: any) => (
                    <TableRow>
                      <TableCell align="center">{i + 1}</TableCell>

                      <TableCell>{row?.title}</TableCell>
                      <TableCell>{row?.message}</TableCell>
                      <TableCell>
                        {moment(row?.createdAt).format("MM-D-YY / h:mm A")}
                      </TableCell>
                      <TableCell align="center">
                        <Box className="table_actions">
                          <Tooltip title="Delete" placement="top">
                            <IconButton
                              onClick={() => {
                                setOpen(true);
                                setSelectedId(row?._id);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      {translate.error.noData}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        {notificationData?.length > 0 ? (
          <Pagination
            setPage={setPage}
            module={notificationData}
            page={page}
            onPageChange={onPageChange}
            totalPages={totalPages}
          />
        ) : (
          ""
        )}
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="course"
      />
    </MainContainer>
  );
};

export default ManageNotifications;
