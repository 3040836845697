import { END_POINTS } from "../constants/url";
import { AllVideo } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
  totalPages: number;
};

type CommonParams = {
  page: number;
  query: string;
  videoType: number;
};

type AddCategoryBody = {
  title: string;
  description: string;
  thumbnail: string;
  duration: string;
  videoType: number;
};

type AddFaqBody = {
  question: string;
  answer: string;
};

type VideoParams = {
  page: number;
  query: string;
  videoType: number;
};

type UpdateCategoryParams = {
  videoId: string | undefined;
  body: {
    title: string;
    description: string;
    thumbnail: string;
    duration: string;
    videoType: number;
  };
};

export const videoApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllVideos: builder.query<
      CommonResponseType & {
        data: {
          count: number;
          totalCount: number;
          data: AllVideo[];
        };
      },
      CommonParams
    >({
      query: ({ page, query, videoType }) => ({
        url:
          END_POINTS.video +
          "?page=" +
          page +
          "&search=" +
          query +
          "&videoType=" +
          videoType,
        method: "GET",
      }),
    }),

    deleteVideos: builder.mutation<CommonResponseType, { videoId: string }>({
      query: ({ videoId }) => ({
        url: `${END_POINTS.video}/${videoId}`,
        method: "DELETE",
      }),
    }),

    updateVideo: builder.mutation<CommonResponseType, UpdateCategoryParams>({
      query: ({ body, videoId }) => ({
        url: `${END_POINTS.video}/${videoId}`,
        method: "PUT",
        body,
      }),
    }),

    changeVideoStatus: builder.mutation<
      { message: string; statusCode: number },
      { videoId: string; body: { isBlocked: boolean } }
    >({
      query: ({ body, videoId }) => ({
        url: `${END_POINTS.video}/${videoId}`,
        method: "PUT",
        body,
      }),
    }),

    addVideo: builder.mutation<
      { message: string; statusCode: number; data: any },
      AddCategoryBody
    >({
      query: (body) => ({
        url: END_POINTS.video,
        method: "POST",
        body,
      }),
    }),

    getVideoById: builder.query<
      CommonResponseType & { data: any },
      { videoId: string | undefined }
    >({
      query: ({ videoId }) => ({
        url: `${END_POINTS.video}/${videoId}`,
        method: "GET",
      }),
    }),

    getPromotionalVideos: builder.query<
      CommonResponseType & {
        data: {
          count: number;
          totalCount: number;
          data: any;
        };
      },
      VideoParams
    >({
      query: ({ page, query, videoType }) => ({
        url:
          END_POINTS.video +
          "?page=" +
          page +
          "&videoType=" +
          videoType +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetAllVideosQuery,
  useDeleteVideosMutation,
  useUpdateVideoMutation,
  useChangeVideoStatusMutation,
  useAddVideoMutation,
  useLazyGetVideoByIdQuery,
  useLazyGetPromotionalVideosQuery,
} = videoApi;
