import { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MainContainer from "../../layout/MainContainer";
import PhoneInput from "react-phone-input-2";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

import { showError, showToast } from "../../constants";
import { isNumber, isString } from "../../utils/validations";
import { UploadMedia, UploadVideo } from "../../utils/mediaUpload";
import useTranslation from "../../hooks/Translation";
import { Merchants } from "../../types/General";
import {
  useAddMerchantMutation,
  useUpdateMerchantMutation,
  useLazyGetMerchantByIdQuery,
} from "../../services/merchant";

const AddChefsAndPhotographers = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const translate = useTranslation();
  const [addMerchant] = useAddMerchantMutation();
  const [getMerchantById] = useLazyGetMerchantByIdQuery();
  const [updateMerchant] = useUpdateMerchantMutation();

  const [category, setCategory] = useState<string>("");
  const [error, setError] = useState(false);
  const [myImages, setMyImages] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [merchantData, setMerchantData] = useState<Merchants>();

  const handleChangePhone = (phone: any, country: any) => {
    console.log("country: ", country);
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    formik.setFieldValue(
      "countryCode",
      country?.dialCode.includes("+") ? "" : "+" + country?.dialCode
    );
    formik.setFieldValue("countryName", country?.countryCode.toUpperCase());
  };

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };

  const handleCrossClick = async (e: any, i: any) => {
    e.stopPropagation();
    if (myImages.includes(i)) {
      let arr = [];
      arr = myImages.filter((name: any) => name !== i);
      setMyImages(arr);
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
      const invalidFiles = Array.from(files).filter(
        (file) => !allowedTypes.includes(file.type)
      );
      if (invalidFiles.length > 0) {
        showError("Invalid file type. Please upload only images.");
        return;
      }
      if (myImages.length + files.length > 10) {
        showError("Cannot upload more than 10 images");
        return;
      }
      const uploadedImages = Array.from(files).map((file) => ({
        file,
        url: URL.createObjectURL(file),
      }));
      setMyImages((prevImages: any) => [...prevImages, ...uploadedImages]);
    }
  };

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";

    // Check if a file is selected
    if (file) {
      console.log("file: ", file);
      // Check if the selected file is an image
      if (file.type.startsWith("image/")) {
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          if (key === "profile") {
            formik.setFieldValue("image", res?.data);
          }
        } else {
          showError(res?.message);
        }
      } else {
        // Display an error message for non-image files
        showError("Please select a valid image file (png or jpeg).");
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: merchantData?.email || "",
      firstName: merchantData?.firstName || "",
      lastName: merchantData?.lastName || "",
      phone: merchantData?.phone || "",
      countryCode: merchantData?.countryCode || "",
      countryName: merchantData?.countryName || "",
      dob: merchantData?.dob || "",
      bio: merchantData?.bio || "",
      headline: merchantData?.headline || "",
      address: merchantData?.address || "",
      accountNumber: merchantData?.accountNumber || "",
      holderName: merchantData?.holderName || "",
      swiftCode: merchantData?.swiftCode || "",
      bankName: merchantData?.bankName || "",
      image: merchantData?.image || "",
      nickName: merchantData?.nickName || "",
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .required(translate.error.required)
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          translate.error.emailReq
        ),
      firstName: Yup.string()

        .min(2, translate.error.minValidation)
        .max(100, translate.error.max100),
      lastName: Yup.string()

        .max(100, translate.error.max100)
        .min(2, translate.error.minValidation),
      nickName: Yup.string()
        .required(translate.error.nicReq)
        .max(100, translate.error.max100)
        .min(2, translate.error.minValidation),

      phone: Yup.string()
        .required(translate.error.phoneReq)
        .min(6, translate.error.minPhone)
        .max(16, translate.error.maxPhone),
      countryCode: Yup.string().required(translate.error.phoneReq),

      headline: Yup.string()

        .min(2, translate.error.minValidation)
        .max(500, translate.error.max500),
      bio: Yup.string()

        .min(2, translate.error.minValidation)
        .max(500, translate.error.max500),
      address: Yup.string()

        .min(2, translate.error.minValidation)
        .max(100, translate.error.max100),
      // profession: Yup.string().required("Choose your Profession"),
      accountNumber: Yup.string()

        .max(100, translate.error.max100)
        .min(2, translate.error.minValidation),
      holderName: Yup.string()

        .max(100, translate.error.max100)
        .min(2, translate.error.minValidation),
      swiftCode: Yup.string()

        .max(100, translate.error.max100)
        .min(2, translate.error.minValidation),
      bankName: Yup.string()

        .max(100, translate.error.max100)
        .min(2, translate.error.minValidation),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      setLoading(true);
      let images: string[] = [];
      if (myImages?.length) {
        setLoading(true);
        await Promise.all(
          myImages?.map(async (item: any) => {
            if (typeof item?.file === "string") {
              images?.push(item?.url);
              return;
            } else {
              const res = await UploadMedia(item?.file);
              if (res?.statusCode === 200 && res?.data?.length) {
                images?.push(res?.data);
              }
            }
          })
        );
      }
      setLoading(false);
      // if (!images?.length) {
      //   setError(true);
      //   return;
      // }
      setError(false);

      const body = {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        nickName: values.nickName,
        phone: values.phone,
        countryCode: values.countryCode,
        countryName: values.countryName,
        dob: values.dob,
        bio: values.bio,
        headline: values.headline,
        address: values.address,
        profession: merchantData?.profession
          ? merchantData?.profession
          : Number(category), // 1 -> chef, 2-> photographer
        accountNumber: values.accountNumber,
        holderName: values.holderName,
        swiftCode: values.swiftCode,
        bankName: values.bankName,
        image: values.image,
        documents: images,
        isProfileComplete: true,
        isVerified: true,
      };

      console.log(body, "add course");

      if (id && id !== "add") {
        try {
          const response = await updateMerchant({
            merchantId: id,
            body,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast(translate.error.merchantUpdate);
            formik.resetForm();
            navigate("/manage-chefs/photographers", {
              state: merchantData?.profession === 1 ? "chef" : "photo",
            });
          } else {
            showError(response?.message);
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      } else {
        try {
          const response = await addMerchant(body).unwrap();
          if (response?.statusCode === 200) {
            showToast(translate.error.merchantAdd);
            formik.resetForm();
            navigate("/manage-chefs/photographers");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  const getMerchants = async () => {
    try {
      const response = await getMerchantById({
        merchantId: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setMerchantData(response?.data);
        // setItems(response?.data[0]?.videos);
      } else {
        console.log("Error");
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  useEffect(() => {
    if (merchantData && merchantData.documents) {
      const documentImages = merchantData.documents.map((documentUrl: any) => ({
        url: documentUrl,
      }));
      setMyImages((prevImages: any) => [...prevImages, ...documentImages]);
    }
  }, [merchantData]);

  useEffect(() => {
    if (merchantData) {
      setCategory(merchantData?.profession);
      if (merchantData?.documents?.length) {
        if (merchantData?.documents?.length && myImages?.length === 0) {
          let newObj = merchantData?.documents?.map((item: string) => {
            return {
              file: "File" as string,
              url: item as string,
            };
          });
          setMyImages([...myImages, ...newObj]);
        }
      }
    }
  }, [merchantData]);

  useEffect(() => {
    if (id && id !== "add") {
      getMerchants();
    }
  }, []);

  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>{id ? "Edit Chef/Photographer" : "Add Chef/Photographer"}</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-chefs/photographers");
              }}
            >
              {translate.globals.back}
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">
                      {translate.globals.image}
                    </Typography>
                    {formik.values.image ? (
                      <div className="upload_image_preview">
                        <CardMedia
                          component="img"
                          image={formik.values.image}
                          alt="photo"
                        />
                        <CancelIcon
                          onClick={() => {
                            formik.setFieldValue("image", "");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: ".png, .jpg, .jpeg, .svg",
                            }}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => handleImageUpload(e, "profile")}
                          />

                          <Button component="span" className="upload_image_btn">
                            <img
                              src="/static/images/user_placeholder.png"
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>

                  <Grid container spacing={2} mt={1}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography className="custom_label">
                        {`${translate?.auth?.firstName}`}
                      </Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="firstName"
                        variant="outlined"
                        className="text_field"
                        fullWidth
                        placeholder={`${translate?.auth?.firstName}`}
                        inputProps={{ maxLength: 100 }}
                        id="firstName"
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else {
                            formik.handleChange(val);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.firstName}
                        helperText={
                          formik.touched.firstName && formik.errors.firstName
                        }
                      />
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography className="custom_label">
                        {`${translate?.auth?.lastName} `}
                      </Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="lastName"
                        variant="outlined"
                        className="text_field"
                        fullWidth
                        placeholder={`${translate?.auth?.lastName}`}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else {
                            formik.handleChange(val);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.lastName}
                        helperText={
                          formik.touched.lastName && formik.errors.lastName
                        }
                      />
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography className="custom_label">
                        {translate.auth.nickName}
                        <span className="asterisk">
                          {translate.globals.asterisk}
                        </span>
                      </Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="nickName"
                        variant="outlined"
                        className="text_field"
                        fullWidth
                        placeholder={translate.auth.nickName}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else {
                            formik.handleChange(val);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.nickName}
                        helperText={
                          formik.touched.nickName && formik.errors.nickName
                        }
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography className="custom_label">
                        {translate.auth.email}
                        <span className="asterisk">
                          {translate.globals.asterisk}
                        </span>
                      </Typography>
                      <TextField
                        hiddenLabel
                        type={"email"}
                        name="email"
                        variant="outlined"
                        fullWidth
                        placeholder={translate.auth.email}
                        className="text_field"
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else {
                            formik.handleChange(val);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography className="custom_label">
                        {translate.auth.phone}
                        <span className="asterisk">
                          {translate.globals.asterisk}
                        </span>
                      </Typography>
                      <PhoneInput
                        value={formik.values.countryCode + formik.values.phone}
                        country={"kw"}
                        placeholder="0 (000) 000-000"
                        enableSearch={true}
                        inputStyle={{ width: "100%" }}
                        onChange={(phone, country) =>
                          handleChangePhone(phone, country)
                        }
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.phone && formik.errors.phone ? (
                        <h6 className="err_msg">
                          {formik.touched.phone && formik.errors.phone}
                        </h6>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography className="custom_label">
                        {translate.main.dob}
                      </Typography>
                      <TextField
                        hiddenLabel
                        type={"date"}
                        name="dob"
                        className="text_field"
                        variant="outlined"
                        fullWidth
                        placeholder="Email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.dob}
                        helperText={formik.touched.dob && formik.errors.dob}
                        inputProps={{
                          // max: new Date().toISOString().split("T")[0],
                          max: moment()
                            .subtract(18, "years")
                            .format("YYYY-MM-DD"),
                        }}
                        onKeyDown={(e) => e.preventDefault()} // Prevent keyboard input
                        InputProps={{ disableUnderline: true }}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography className="custom_label">{`${translate?.main?.bio}`}</Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="bio"
                        className="text_field"
                        variant="outlined"
                        fullWidth
                        placeholder={`${translate?.main?.bio}`}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else {
                            formik.handleChange(val);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.bio}
                        helperText={formik.touched.bio && formik.errors.bio}
                      />
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography className="custom_label">{`${translate?.main?.headline} `}</Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="headline"
                        className="text_field"
                        variant="outlined"
                        fullWidth
                        placeholder={`${translate?.main?.headline}`}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else {
                            formik.handleChange(val);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.headline}
                        helperText={
                          formik.touched.headline && formik.errors.headline
                        }
                      />
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography className="custom_label">{`${translate?.main?.address} `}</Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="address"
                        className="text_field"
                        variant="outlined"
                        fullWidth
                        placeholder={`${translate?.main?.address} `}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else {
                            formik.handleChange(val);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.address}
                        helperText={
                          formik.touched.address && formik.errors.address
                        }
                      />
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography className="custom_label">
                        {translate.main.profession}
                        <span className="asterisk">
                          {translate.globals.asterisk}
                        </span>
                      </Typography>

                      <FormControl sx={{ width: "100%" }}>
                        <Select
                          fullWidth
                          className="select_div"
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={
                            merchantData?.profession
                              ? merchantData?.profession
                              : category
                          }
                          // multiple
                          inputProps={{
                            "aria-label": "Without label",
                          }}
                          displayEmpty
                          onChange={handleCategoryChange}
                        >
                          <MenuItem value="" disabled>
                            {translate.globals.select}
                          </MenuItem>
                          <MenuItem value={1}>
                            {translate.globals.chefs}
                          </MenuItem>
                          <MenuItem value={2}>
                            {translate.globals.photographers}
                          </MenuItem>
                        </Select>
                        {error && !merchantData?.profession && !category ? (
                          <h6 className="err_msg">Please select category</h6>
                        ) : (
                          ""
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <div style={{ paddingTop: "20px", paddingBottom: "10px" }}>
                    <Typography fontSize={18} sx={{ fontWeight: "bold" }}>
                      {translate.main.acctDetails}
                    </Typography>
                  </div>
                  <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography className="custom_label">
                        {translate.main.acctNo}
                      </Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="accountNumber"
                        variant="outlined"
                        className="text_field"
                        fullWidth
                        placeholder={translate.main.acctNo}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else if (isNumber(val.target.value)) {
                            formik.handleChange(val);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.accountNumber}
                        helperText={
                          formik.touched.accountNumber &&
                          formik.errors.accountNumber
                        }
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography className="custom_label">
                        {`${translate?.main?.holder} `}
                      </Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="holderName"
                        className="text_field"
                        variant="outlined"
                        fullWidth
                        placeholder={`${translate?.main?.holder}`}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else {
                            formik.handleChange(val);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.holderName}
                        helperText={
                          formik.touched.holderName && formik.errors.holderName
                        }
                      />
                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography className="custom_label">
                        {translate.main.swift}
                      </Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        className="text_field"
                        name="swiftCode"
                        variant="outlined"
                        fullWidth
                        placeholder={translate.main.swift}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else {
                            formik.handleChange(val);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.swiftCode}
                        helperText={
                          formik.touched.swiftCode && formik.errors.swiftCode
                        }
                      />
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Typography className="custom_label">
                        {`${translate?.main?.bank} `}
                      </Typography>
                      <TextField
                        hiddenLabel
                        type={"text"}
                        name="bankName"
                        variant="outlined"
                        className="text_field"
                        fullWidth
                        placeholder={`${translate?.main?.bank} `}
                        onChange={(val) => {
                          if (
                            val.target.value === " " ||
                            val.target.value === "."
                          ) {
                          } else {
                            formik.handleChange(val);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.bankName}
                        helperText={
                          formik.touched.bankName && formik.errors.bankName
                        }
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} className="heading">
                    <Typography className="custom_label" variant="h5">
                      {translate.main.document}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2} className="form_control">
                      <Grid
                        item
                        lg={4}
                        md={4}
                        sm={6}
                        xs={12}
                        className="upload_document v2 w_50"
                      >
                        <label htmlFor="icon-button-file-certi">
                          <div className="inner">
                            <AddIcon />
                            <Input
                              id="icon-button-file-certi"
                              type="file"
                              inputProps={{
                                accept: ".png, .jpg, .jpeg, .svg",
                                multiple: true,
                              }}
                              onChange={handleFileUpload}
                            />
                          </div>
                        </label>
                        {/* {error && !myImages?.length ? (
                          <h6 className="err_msg">This field is required.</h6>
                        ) : (
                          ""
                        )} */}
                      </Grid>

                      {myImages?.length ? (
                        <>
                          {myImages?.map((item: any, i: any) => {
                            return (
                              <Grid
                                item
                                lg={4}
                                md={4}
                                sm={6}
                                xs={12}
                                className="upload_document w_50"
                                key={i}
                              >
                                <div className="inner">
                                  <div className="cross_icn">
                                    <CloseIcon
                                      onClick={(e) => handleCrossClick(e, item)}
                                    />
                                  </div>

                                  <figure>
                                    <img src={item?.url} alt="upload" />
                                  </figure>
                                </div>
                              </Grid>
                            );
                          })}
                        </>
                      ) : null}
                    </Grid>
                  </Grid>
                </Grid>

                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    onClick={() => setError(true)}
                  >
                    {translate.globals.save}
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddChefsAndPhotographers;
