import LoginPage from "./auth/login";
import DashBoardPage from "./dashBoard";
import ManageUsers from "./users";
import ForgotPasswordPage from "./auth/forgotPassword";
import VerifyOtp from "./auth/otp";
import Analytics from "./analytics";
import Faq from "./faq";
import Settings from "./settings";
import ResetPassword from "./auth/resetPassword";
import Profile from "./profile";
import ManageCms from "./manageCms";
import ManageNotifications from "./manageNotification";
import AddNotification from "./manageNotification/add";
import CustomerSupport from "./customerSupport";
import ManageSubAdmin from "./manageSubAdmin";
import AddSubAdmin from "./manageSubAdmin/add";
import ManageCategories from "./manageCategories";
import AddCategories from "./manageCategories/add";
import RecievedNotifications from "./manageNotification/receivedNotification";
import ManageSubscription from "./manageSubscription";
import AddSubscriptionPlan from "./manageSubscription/add";
import SubCategories from "./manageCategories/details";
import ChangePassword from "./auth/changePassword";
import ManageReview from "./manageReview";
import ManageCourses from "./manageCourses";
import AddCourses from "./manageCourses/add";
import CourseDetails from "./manageCourses/details";
import ManageChefs from "./manageChefsAndPhotographers.tsx";
import ManageVideos from "./manageVideos";
import AddVideos from "./manageVideos/add";
import AddChefsAndPhotographers from "./manageChefsAndPhotographers.tsx/add";
import ChefAndPhotographerDetails from "./manageChefsAndPhotographers.tsx/details";
import ManageOrders from "./manageOrders";
import OrderDetails from "./manageOrders/details";
import PromotionalVideo from "./promotionalVideo";
import AddPromotionalVideo from "./promotionalVideo/add";
import AddCustomerPlan from "./manageSubscription/addCustomerPlan";
import RatingDetails from "./manageReview/details";
import DiscountCoupons from "./discountCoupon";
import AddDiscountCoupon from "./discountCoupon/add";
import AddDiscountCode from "./manageSubscription/discountCode/add";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  LoginPage,
  DashBoardPage,
  ManageUsers,
  ForgotPasswordPage,
  VerifyOtp,
  Analytics,
  Faq,
  ResetPassword,
  Profile,
  ManageCms,
  Settings,
  ManageNotifications,
  AddNotification,
  CustomerSupport,
  ManageSubAdmin,
  AddSubAdmin,
  ManageCategories,
  AddCategories,
  RecievedNotifications,
  ManageSubscription,
  AddSubscriptionPlan,
  SubCategories,
  ChangePassword,
  ManageReview,
  ManageCourses,
  AddCourses,
  CourseDetails,
  ManageChefs,
  ManageVideos,
  AddVideos,
  AddChefsAndPhotographers,
  ChefAndPhotographerDetails,
  ManageOrders,
  OrderDetails,
  PromotionalVideo,
  AddPromotionalVideo,
  AddCustomerPlan,
  RatingDetails,
  DiscountCoupons,
  AddDiscountCoupon,
  AddDiscountCode,
};
