import { END_POINTS } from "../constants/url";
import { Cms } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
  totalPages: number;
};

type CommonParams = {
  page: number;
  query: string;
};

type AddCategoryBody = {
  title: string;
  description: string;
  coverImage: string;
  price: number;
  // merchant: "65548dad148334fa16a161a5";
  category: string;
  pricingType: number;
  videos: [];
};

type AddFaqBody = {
  question: string;
  answer: string;
};

type UpdateCategoryParams = {
  courseId: string | undefined;
  body: {
    title: string;
    description: string;
    coverImage: string;
    price: number;
    // merchant: "65548dad148334fa16a161a5";
    category: string;
    pricingType: number;
    videos: [];
  };
};

type PostCmsResponse = {
  message: string;
  statusCode: number;
  data: {
    createdAt: string;
    email: string;
    isDeleted: boolean;
    legal: string;
    phone: string;
    privacyPolicy: string;
    support: string;
    updatedAt: string;
    _id: string;
  }[];
};

type PostCmsBody = {
  email: string;
  phone: string;
  privacyPolicy: string;
  legal: string;
};

export const cmsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCms: builder.query<CommonResponseType & { data: Cms[] }, {}>({
      query: () => ({
        url: END_POINTS.cms,
        method: "GET",
      }),
    }),

    addCourse: builder.mutation<
      { message: string; statusCode: number; data: any },
      AddCategoryBody
    >({
      query: (body) => ({
        url: END_POINTS.course,
        method: "POST",
        body,
      }),
    }),

    postCms: builder.mutation<PostCmsResponse, { body: PostCmsBody }>({
      query: ({ body }) => ({
        url: `${END_POINTS.cms}`,
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const { useLazyGetCmsQuery, usePostCmsMutation } = cmsApi;
