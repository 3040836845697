// @ts-nocheck
import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import MainContainer from "../../layout/MainContainer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import { ChangeEvent, useEffect, useState } from "react";
import {
  useAddCategoryMutation,
  useLazyGetCategoryByIdQuery,
  useUpdateCategoryMutation,
} from "../../services/main";
import {
  getFromStorage,
  showError,
  showToast,
  STORAGE_KEYS,
} from "../../constants";
import CloseIcon from "@mui/icons-material/Close";
import { UploadMedia } from "../../utils/mediaUpload";
import { isString } from "../../utils/validations";
import useTranslation from "../../hooks/Translation";

const AddCategories = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { state } = location;
  const getLang = getFromStorage(STORAGE_KEYS.language);
  const translation = useTranslation();
  const [addCategoryMutation] = useAddCategoryMutation();
  const [getCategoryDetails] = useLazyGetCategoryByIdQuery();
  const [updateCategory] = useUpdateCategoryMutation();

  const [category, setCategory] = useState<string>("");

  const [image, setImage] = useState<string>("");

  const [categoryById, setCategoryById] = useState<any>();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: categoryById?.name || "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required(translation.error.required)
        .max(20, translation.error.maxValidation)
        .min(3, translation.error.minValidation),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      const body = {
        name: formik?.values?.name,
        image: image,
        language: getLang === "en" ? 1 : 2,
      };

      console.log(body);

      if (id && id !== "add") {
        try {
          const response = await updateCategory({
            categoryId: id,
            body,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast(translation.error.catUpdate);
            navigate("/manage-categories");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      } else {
        try {
          const response = await addCategoryMutation(body).unwrap();
          if (response?.statusCode === 200) {
            showToast(translation.error.catAdd);
            formik.resetForm();
            navigate("/manage-categories");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    // Check if a file is selected
    if (file) {
      // Check if the selected file is an image
      if (file.type.startsWith("image/")) {
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          setImage(res?.data);
        } else {
          showError(res?.message);
        }
      } else {
        // Display an error message for non-image files
        showError("Please select a valid image file (png or jpeg).");
      }
    }
  };

  const getCategoryDetail = async (id: string | undefined) => {
    try {
      const res = await getCategoryDetails({ categoryId: id }).unwrap();
      if (res?.statusCode === 200) {
        setCategoryById(res?.data);
        setImage(res?.data?.image);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value as string);
  };

  useEffect(() => {
    if (id && id !== "add") {
      getCategoryDetail(id);
    }
  }, []);

  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>
              {id
                ? translation?.main?.editCategory
                : translation?.main?.addCategory}
            </h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-categories");
              }}
            >
              {translation?.globals?.back}
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="custom_label">
                      {translation?.main?.image}
                    </Typography>
                    {image ? (
                      <div className="upload_image_preview">
                        <CardMedia component="img" image={image} alt="photo" />
                        <CancelIcon
                          onClick={() => {
                            setImage("");
                          }}
                        />
                      </div>
                    ) : (
                      <Box className="upload_image">
                        <label htmlFor="icon-button-file">
                          <Input
                            sx={{ display: "none" }}
                            id="icon-button-file"
                            type="file"
                            inputProps={{
                              accept: "image/png,image/jpeg",
                            }}
                            onChange={handleImageUpload}
                          />
                          <Button component="span" className="upload_image_btn">
                            <img
                              src="/static/images/user_placeholder.png"
                              alt=""
                            />
                            <CameraAltIcon />
                          </Button>
                        </label>
                      </Box>
                    )}
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {`${translation?.main?.catName} `}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="name"
                      variant="outlined"
                      className="text_field"
                      fullWidth
                      placeholder={`${translation?.main?.catName}`}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      helperText={formik.touched.name && formik.errors.name}
                    />
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    {translation?.globals?.save}
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddCategories;
