import { END_POINTS } from "../constants/url";
import { Dashboard, SubAdmin } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
  totalPages: number;
};

type CommonParams = {
  page: number;
  query: string;
};

type OrderParams = {
  page: number;
  query: string;
  startDate: string;
  endDate: string;
};

type AddCategoryBody = {
  name: string;
  image: string;
};

type AddFaqBody = {
  question: string;
  answer: string;
};

type UpdateCategoryParams = {
  categoryId: string | undefined;
  body: {
    name: string;
    image: string;
  };
};

type GetSubAdminResById = {
  statusCode: number;
  message: string;
  data: {
    subAdmin: SubAdmin;
  };
};

type GetOrderResById = {
  statusCode: number;
  message: string;
  data: any;
};

type SubAdminBody = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  countryCode: string;

  image?: string | undefined;
  permission: {
    label?: string | undefined;
    isView?: boolean;
    isAdd?: boolean;
    isDelete?: boolean;
  }[];
};

type UpdateSettingsParams = {
  body: {
    maxVideoSize: string;
    maxVideoDuration: string;
    isMp4Supported: boolean;
    isWebMSupported: boolean;
    isAVISupported: boolean;
  };
};

type GetSubAdminRes = {
  statusCode: number;
  message: string;
  data: {
    subAdmin: SubAdmin[];
    subAdminCount: number;
  };
};

type NotificationBody = {
  body: {
    title: string;
    pushType: number;
    message: string;
  };
};

type NotificationResponse = {
  data: {
    notification: never[];
    totalCount: number;
    count: number;
    notificationCount: number;
    data: any;
  };
  statusCode: number;
  message: string;
};

export const mainApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllCategory: builder.query<
      CommonResponseType & {
        data: {
          count: number;
          totalCount: number;
          req: any;
        };
      },
      CommonParams
    >({
      query: ({ page, query }) => ({
        url: END_POINTS.categories + "?page=" + page + "&search=" + query,
        method: "GET",
      }),
    }),

    addCategory: builder.mutation<
      { message: string; statusCode: number; data: any },
      AddCategoryBody
    >({
      query: (body) => ({
        url: END_POINTS.addCategory,
        method: "POST",
        body,
      }),
    }),

    changeCategoryStatus: builder.mutation<
      { message: string; statusCode: number },
      { categoryId: string; body: { isBlocked: boolean } }
    >({
      query: ({ body, categoryId }) => ({
        url: `${END_POINTS.addCategory}/${categoryId}`,
        method: "PUT",
        body,
      }),
    }),

    deleteCategory: builder.mutation<
      CommonResponseType,
      { categoryId: string }
    >({
      query: ({ categoryId }) => ({
        url: `${END_POINTS.addCategory}/${categoryId}`,
        method: "DELETE",
      }),
    }),

    updateCategory: builder.mutation<CommonResponseType, UpdateCategoryParams>({
      query: ({ body, categoryId }) => ({
        url: `${END_POINTS.addCategory}/${categoryId}`,
        method: "PUT",
        body,
      }),
    }),

    getCategoryById: builder.query<
      CommonResponseType & { data: any },
      { categoryId: string | undefined }
    >({
      query: ({ categoryId }) => ({
        url: `${END_POINTS.addCategory}/${categoryId}`,
        method: "GET",
      }),
    }),

    getAllFaqs: builder.query<
      CommonResponseType & {
        data: {
          data: any;
        };
      },
      {}
    >({
      query: () => ({
        url: END_POINTS.faqs,
        method: "GET",
      }),
    }),

    addFaqs: builder.mutation<
      { message: string; statusCode: number; data: any },
      AddFaqBody
    >({
      query: (body) => ({
        url: END_POINTS.faqs,
        method: "POST",
        body,
      }),
    }),

    deleteFaqs: builder.mutation<CommonResponseType, { faqId: string }>({
      query: ({ faqId }) => ({
        url: `${END_POINTS.faqs}/${faqId}`,
        method: "DELETE",
      }),
    }),

    getSettings: builder.query<CommonResponseType & { data: any }, {}>({
      query: () => ({
        url: `${END_POINTS.settings}`,
        method: "GET",
      }),
    }),

    updateSettings: builder.mutation<CommonResponseType, UpdateSettingsParams>({
      query: ({ body }) => ({
        url: `${END_POINTS.settings}`,
        method: "PUT",
        body,
      }),
    }),

    getAllSubAdmins: builder.query<
      CommonResponseType & {
        data: {
          count: number;
          subAdmin: SubAdmin[];
        };
      },
      CommonParams
    >({
      query: ({ page, query }) => ({
        url: END_POINTS.subadmin + "?page=" + page + "&search=" + query,
        method: "GET",
      }),
    }),

    changeSubAdminStatus: builder.mutation<
      { message: string; statusCode: number },
      { subAdminId: string; body: { isBlocked: boolean } }
    >({
      query: ({ body, subAdminId }) => ({
        url: `${END_POINTS.subadmin}${subAdminId}`,
        method: "PUT",
        body,
      }),
    }),

    deleteSubAdmin: builder.mutation<
      CommonResponseType,
      { subAdminId: string }
    >({
      query: ({ subAdminId }) => ({
        url: `${END_POINTS.subadmin}${subAdminId}`,
        method: "DELETE",
      }),
    }),

    addSubAdmin: builder.mutation<GetSubAdminRes, SubAdminBody>({
      query: (body) => ({
        url: END_POINTS.subadmin,
        method: "POST",
        body,
      }),
    }),

    updateSubAdminById: builder.mutation<
      GetSubAdminResById,
      { subAmin_id: string; body: SubAdminBody }
    >({
      query: ({ subAmin_id, body }) => ({
        url: `${END_POINTS.subadmin}/${subAmin_id}/`,
        method: "PUT",
        body,
      }),
    }),

    getSubAdminById: builder.query<
      GetSubAdminResById,
      { subAmin_id: string | undefined }
    >({
      query: ({ subAmin_id }) => ({
        url: `${END_POINTS.subadmin}/${subAmin_id}`,
        method: "GET",
      }),
    }),

    getAllOrders: builder.query<
      CommonResponseType & {
        data: {
          count: number;
          orders: any;
        };
      },
      OrderParams
    >({
      query: ({ page, query, startDate, endDate }) => ({
        url:
          END_POINTS.orders +
          "?page=" +
          page +
          "&search=" +
          query +
          "&startDate=" +
          startDate +
          "&endDate=" +
          endDate,
        method: "GET",
      }),
    }),

    getOrdersById: builder.query<
      GetOrderResById,
      { orderId: string | undefined }
    >({
      query: ({ orderId }) => ({
        url: `${END_POINTS.orders}/${orderId}`,
        method: "GET",
      }),
    }),

    getAllReviews: builder.query<
      CommonResponseType & {
        data: {
          count: number;
          rating: any;
        };
      },
      CommonParams
    >({
      query: ({ page, query }) => ({
        url: END_POINTS.ratings + "?page=" + page + "&search=" + query,
        method: "GET",
      }),
    }),

    deleteReview: builder.mutation<CommonResponseType, { reviewId: string }>({
      query: ({ reviewId }) => ({
        url: `${END_POINTS.ratings}/${reviewId}`,
        method: "DELETE",
      }),
    }),

    getReviewsById: builder.query<
      GetOrderResById,
      { ratingId: string | undefined }
    >({
      query: ({ ratingId }) => ({
        url: `${END_POINTS.ratings}/${ratingId}`,
        method: "GET",
      }),
    }),

    getDashboard: builder.query<
      CommonResponseType & {
        data: Dashboard;
      },
      {}
    >({
      query: () => ({
        url: END_POINTS.dashboard,
        method: "GET",
      }),
    }),

    getUserGraph: builder.query<
      CommonResponseType & {
        data: any;
      },
      { type: string }
    >({
      query: ({ type }) => ({
        url: END_POINTS.usersGraph + "?type=" + type,
        method: "GET",
      }),
    }),

    getRevenueGraph: builder.query<
      CommonResponseType & {
        data: any;
      },
      { type: string }
    >({
      query: ({ type }) => ({
        url: END_POINTS.revenueGraph + "?type=" + type,
        method: "GET",
      }),
    }),

    getMerchantGraph: builder.query<
      CommonResponseType & {
        data: any;
      },
      { profession: number; type: string }
    >({
      query: ({ type, profession }) => ({
        url:
          END_POINTS.merchantGraph +
          "?profession=" +
          profession +
          "&type=" +
          type,
        method: "GET",
      }),
    }),

    postAddNotification: builder.mutation<
      { message: string; statusCode: number },
      NotificationBody
    >({
      query: ({ body }) => ({
        url: END_POINTS.notification,
        method: "POST",
        body,
      }),
    }),

    getNotifications: builder.query<NotificationResponse, CommonParams>({
      query: ({ page, query }) => ({
        url: END_POINTS.notification + "?search=" + query + "&page=" + page,

        method: "GET",
      }),
    }),

    deleteNotification: builder.mutation<
      CommonResponseType,
      { notificationId: string }
    >({
      query: ({ notificationId }) => ({
        url: `${END_POINTS.notification}/${notificationId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLazyGetAllCategoryQuery,
  useAddCategoryMutation,
  useChangeCategoryStatusMutation,
  useDeleteCategoryMutation,
  useUpdateCategoryMutation,
  useLazyGetCategoryByIdQuery,
  useLazyGetAllFaqsQuery,
  useAddFaqsMutation,
  useDeleteFaqsMutation,
  useLazyGetSettingsQuery,
  useUpdateSettingsMutation,
  useLazyGetAllSubAdminsQuery,
  useChangeSubAdminStatusMutation,
  useDeleteSubAdminMutation,
  useAddSubAdminMutation,
  useUpdateSubAdminByIdMutation,
  useLazyGetSubAdminByIdQuery,
  useLazyGetAllOrdersQuery,
  useLazyGetOrdersByIdQuery,
  useLazyGetAllReviewsQuery,
  useLazyGetDashboardQuery,
  useLazyGetUserGraphQuery,
  useLazyGetRevenueGraphQuery,
  useLazyGetMerchantGraphQuery,
  useLazyGetReviewsByIdQuery,
  useDeleteReviewMutation,
  usePostAddNotificationMutation,
  useLazyGetNotificationsQuery,
  useDeleteNotificationMutation,
} = mainApi;
