import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  NativeSelect,
  Rating,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import SearchBar from "../../components/SearchBar";
import DeleteIcon from "@mui/icons-material/Delete";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";
import { isValidInput } from "../../utils/validations";
import { useLazyGetAllCoursesQuery } from "../../services/courses";
import { Courses, Discount } from "../../types/General";
import Pagination from "../../components/Pagination";
import {
  useAddDiscountMutation,
  useLazyGetDiscountByIdQuery,
  useUpdateDiscountMutation,
} from "../../services/discount";
import {
  getFromStorage,
  Loader,
  showError,
  showToast,
  STORAGE_KEYS,
} from "../../constants";
import useTranslation from "../../hooks/Translation";

const AddDiscountCoupon = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const translate = useTranslation();
  const [getAllCourses, { isLoading }] = useLazyGetAllCoursesQuery();
  const [couponById] = useLazyGetDiscountByIdQuery();
  const [addDiscount] = useAddDiscountMutation();
  const [updateDiscount] = useUpdateDiscountMutation();
  const [receiver, setReceiver] = useState<string>("All");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [courseData, setCourseData] = useState<Courses[]>([]);
  const [couponData, setCouponData] = useState<Discount>();
  const getLang = getFromStorage(STORAGE_KEYS.language);
  const [page, setPage] = useState(1);
  const [filterOption, setFilterOption] = useState<string>("");

  const handleFilterChange = (event: SelectChangeEvent) => {
    setFilterOption(event.target.value);
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  let totalPages = Math.ceil(totalCount / 10);

  const [selectedCourses, setSelectedCourses] = useState<string[]>([]);
  console.log(selectedCourses);

  const handleCheckboxChange = (courseId: string) => {
    if (selectedCourses.includes(courseId)) {
      setSelectedCourses((prevSelected) =>
        prevSelected.filter((id) => id !== courseId)
      );
    } else {
      setSelectedCourses((prevSelected) => [...prevSelected, courseId]);
    }
  };

  const getCourses = async () => {
    try {
      const response = await getAllCourses({
        query: debouncedSearchTerm.trim(),
        page: page,
        category: "",
        rating: "",
        price: "",
      }).unwrap();
      if (response?.statusCode === 200) {
        const allCourses = response?.data?.courses || [];
        setCourseData(allCourses);
        setTotalCount(response?.data?.count);

        const filteredCourses =
          filterOption === "selected"
            ? allCourses.filter((course) =>
                selectedCourses.includes(course._id)
              )
            : filterOption === "non-selected"
            ? allCourses.filter(
                (course) => !selectedCourses.includes(course._id)
              )
            : allCourses;

        setCourseData(filteredCourses);
      } else {
        setCourseData([]);
      }
    } catch (error: any) {
      console.log(error?.data?.message || "");
      setCourseData([]);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      code: couponData?.code || "",
      discount: couponData?.discountPercentage || "",
      validFrom: moment(couponData?.valideFrom).format("yyyy-MM-DD") || "",
      validTill: moment(couponData?.valideTill).format("yyyy-MM-DD") || "",
    },
    validationSchema: Yup.object({
      code: Yup.string()
        .required(translate.error.required)
        .max(20, translate.error.maxValidation)
        .min(3, translate.error.minValidation),
      discount: Yup.string()
        .required(translate.error.required)
        .max(20, translate.error.maxValidation),

      validFrom: Yup.string().required(translate.error.required),
      validTill: Yup.string().required(translate.error.required),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      if (selectedCourses.length === 0) {
        showError("Please select at least one course");
        formik.setSubmitting(false);
        return;
      }

      const body = {
        code: values.code,
        courses: selectedCourses,
        discountPercentage: values.discount,
        discountType: 1,
        valideFrom: values.validFrom,
        valideTill: values.validTill,
      };

      console.log(body);

      if (id && id !== "add") {
        try {
          const response = await updateDiscount({
            discountId: id,
            body,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast(translate.error.discountUpdated);
            navigate("/manage-discount-coupons");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      } else {
        try {
          const response = await addDiscount(body).unwrap();
          if (response?.statusCode === 200) {
            showToast(translate.error.discountAdd);
            formik.resetForm();
            navigate("/manage-discount-coupons");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
          console.log(error);
        }
      }
    },
  });

  const getCouponDetail = async (id: string | undefined) => {
    try {
      const res = await couponById({ discountId: id }).unwrap();
      if (res?.statusCode === 200) {
        setCouponData(res?.data);
        setSelectedCourses(res?.data?.courses);
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const handleReceiverChange = (event: SelectChangeEvent) => {
    setReceiver(event.target.value as string);
  };

  useEffect(() => {
    getCourses();
  }, [page, debouncedSearchTerm, filterOption]);

  useEffect(() => {
    if (id && id !== "add") {
      getCouponDetail(id);
    }
  }, []);

  return (
    <>
      <MainContainer>
        <Loader isLoad={isLoading} />
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">
              {id ? translate.globals.edit : translate.globals.add}{" "}
              {translate.main.coupon}
            </h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-discount-coupons");
              }}
            >
              {translate.globals.back}
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translate.main.coupon}
                      <span className="asterisk">
                        {translate.globals.asterisk}
                      </span>
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="code"
                      variant="outlined"
                      className="text_field"
                      fullWidth
                      placeholder={translate.main.coupon}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.code}
                      helperText={formik.touched.code && formik.errors.code}
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translate.course.discount}
                      <span className="asterisk">
                        {translate.globals.asterisk}
                      </span>
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="discount"
                      className="text_field"
                      variant="outlined"
                      fullWidth
                      placeholder={translate.course.discount}
                      onChange={(event) => {
                        const inputValue = event.target.value;

                        // Check if the input is a valid number with up to 2 decimals and a maximum of 10 digits
                        const isValidInput =
                          /^([1-9]\d{0,1}(\.\d{0,2})?)?$/.test(inputValue);

                        if (isValidInput) {
                          // Update the form field only if the input is valid
                          formik.handleChange(event);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.discount}
                      helperText={
                        formik.touched.discount && formik.errors.discount
                      }
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translate.main.validFrom}
                      <span className="asterisk">
                        {translate.globals.asterisk}
                      </span>
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"date"}
                      name="validFrom"
                      className="text_field"
                      variant="outlined"
                      fullWidth
                      placeholder={translate.main.validFrom}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.validFrom}
                      helperText={
                        formik.touched.validFrom && formik.errors.validFrom
                      }
                      inputProps={{
                        min: new Date().toISOString().split("T")[0],
                      }}
                      onKeyDown={(e) => e.preventDefault()} // Prevent keyboard input
                      InputProps={{ disableUnderline: true }}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {translate.main.validTill}
                      <span className="asterisk">
                        {translate.globals.asterisk}
                      </span>
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"date"}
                      name="validTill"
                      className="text_field"
                      variant="outlined"
                      disabled={!formik.values.validFrom}
                      fullWidth
                      placeholder={translate.main.validTill}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.validTill}
                      helperText={
                        formik.touched.validTill && formik.errors.validTill
                      }
                      inputProps={{
                        min: formik.values.validFrom,
                      }}
                      onKeyDown={(e) => e.preventDefault()} // Prevent keyboard input
                      InputProps={{ disableUnderline: true }}
                    />
                  </Grid>
                </Grid>

                <div style={{ paddingTop: 8 }}>
                  <h3>{translate.course.courses}</h3>
                </div>

                <Grid container spacing={2} sx={{ paddingTop: 1 }}>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <SearchBar
                      style={{ width: "100%" }}
                      value={searchTerm}
                      searchTerm={searchTerm}
                      onCross={() => setSearchTerm("")}
                      setDebouncedSearchTerm={setDebouncedSearchTerm}
                      onChange={(val: any) => {
                        if (isValidInput(val.target.value)) {
                          setSearchTerm(val.target.value);
                        }
                      }}
                    />
                  </Grid>

                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        className="select_div"
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        value={filterOption}
                        onChange={handleFilterChange}
                        label={"Filter"}
                      >
                        <MenuItem value="" disabled>
                          Filter
                        </MenuItem>
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="selected">Selected</MenuItem>
                        <MenuItem value="non-selected">Non selected</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <TableContainer className="table_container">
                  <Box className="heading"></Box>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          {translate.globals.serialNo}
                        </TableCell>
                        <TableCell>{translate.course.courseName}</TableCell>
                        <TableCell>{translate.course.price}</TableCell>
                        <TableCell>{translate.course.merchantName}</TableCell>
                        <TableCell>{translate.course.ratings}</TableCell>
                        <TableCell>{translate.globals.select}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {courseData?.length > 0 ? (
                        courseData?.map((row, i) => (
                          <TableRow>
                            <TableCell align="center">{i + 1}</TableCell>
                            <TableCell>{row?.title || "-"}</TableCell>
                            <TableCell>
                              {`${row?.price ? "$" : ""} ${
                                row?.price || "Free"
                              }`}
                            </TableCell>

                            <TableCell>
                              {row?.merchant?.firstName || "-"}
                            </TableCell>

                            <TableCell>
                              <Box className="table_actions">
                                <Rating
                                  name="read-only"
                                  value={
                                    row?.ratings !== null
                                      ? row.ratings.toFixed(2)
                                      : 0
                                  }
                                  readOnly
                                />
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              <Checkbox
                                checked={selectedCourses.includes(row._id)}
                                onChange={() => handleCheckboxChange(row._id)}
                              />
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan={9} align="center">
                            {translate.error.noData}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>

                {courseData?.length > 0 ? (
                  <Pagination
                    setPage={setPage}
                    module={courseData}
                    page={page}
                    onPageChange={onPageChange}
                    totalPages={totalPages}
                  />
                ) : (
                  ""
                )}

                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    {translate.globals.save}
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddDiscountCoupon;
