import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  NativeSelect,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import MainContainer from "../../layout/MainContainer";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import useTranslation from "../../hooks/Translation";
import { showError, showToast } from "../../constants";
import { usePostAddNotificationMutation } from "../../services/main";

const AddNotification = () => {
  const navigate = useNavigate();
  const translate = useTranslation();
  const [addNotification] = usePostAddNotificationMutation();
  const [receiver, setReceiver] = useState<string>("1");

  const handleReceiverChange = (event: SelectChangeEvent) => {
    setReceiver(event.target.value as string);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      message: "",
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required(translate.error.titlereq)
        .max(250, translate.error.max250)
        .min(2, translate.error.minValidation),
      message: Yup.string()
        .required(translate.error.messageReq)
        .max(500, translate.error.max500)
        .min(2, translate.error.minValidation),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      const body = {
        title: values?.title,
        pushType: Number(receiver),
        message: values?.message,
      };

      try {
        const response = await addNotification({ body }).unwrap();
        if (response?.statusCode === 200) {
          showToast(translate.error.notiAdd);
          formik.resetForm();
          navigate("/manage-notifications");
        } else {
          showError(response?.message || "");
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
      }
    },
  });

  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1 className="mn_hdng">{`${translate.globals.add} ${translate.main.notification}`}</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-notifications");
              }}
            >
              {translate.globals.back}
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {`${translate.main.notification} ${translate.globals.title}`}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="title"
                      variant="outlined"
                      className="text_field"
                      fullWidth
                      placeholder={translate.globals.title}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.title}
                      helperText={formik.touched.title && formik.errors.title}
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {`${translate.main.notification} ${translate.main.message}`}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="message"
                      className="text_field"
                      variant="outlined"
                      fullWidth
                      placeholder={translate.main.message}
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.message}
                      helperText={
                        formik.touched.message && formik.errors.message
                      }
                    ></TextField>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {`${translate.globals.select} ${translate.main.receiver}`}
                    </Typography>

                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        fullWidth
                        className="select_div"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={receiver}
                        // multiple
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        onChange={handleReceiverChange}
                      >
                        <MenuItem value="" disabled>
                          {translate.globals.select}
                        </MenuItem>
                        <MenuItem value="1">
                          {translate.main.broadcast}
                        </MenuItem>
                        <MenuItem value="2">
                          {translate.plans.customers}
                        </MenuItem>
                        <MenuItem value="3">
                          {translate.plans.chefPhoto}
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    {translate.globals.save}
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddNotification;
