import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";

import moment from "moment";
import SearchBar from "../../components/SearchBar";

import {
  useLazyGetAllVideosQuery,
  useChangeVideoStatusMutation,
  useDeleteVideosMutation,
} from "../../services/videos";
import { Loader, showError, showToast } from "../../constants";
import { AllVideo } from "../../types/General";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";
import { isValidInput } from "../../utils/validations";
import useAuth from "../../hooks/useAuth";
import useTranslation from "../../hooks/Translation";

const ManageVideo = () => {
  const userData = useAuth();
  const navigate = useNavigate();
  const translate = useTranslation();
  const [getVideos, { isLoading }] = useLazyGetAllVideosQuery();
  const [deleteById] = useDeleteVideosMutation();
  const [updateStatusMutation] = useChangeVideoStatusMutation();

  const [page, setPage] = useState(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [videoRes, setVideosRes] = useState<AllVideo[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });

  let totalPages = Math.ceil(totalCount / 10);

  const label = { inputProps: { "aria-label": "Switch demo" } };
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getAllVideos = async () => {
    try {
      const response = await getVideos({
        page: page,
        query: debouncedSearchTerm.trim(),
        videoType: 2,
      }).unwrap();
      if (response?.statusCode === 200) {
        setVideosRes(response?.data?.data || []);
        setTotalCount(response?.data?.count);
      } else {
        setVideosRes([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const updateStatus = async (id: string, status: boolean) => {
    const response = await updateStatusMutation({
      videoId: id,
      body: {
        isBlocked: status,
      },
    }).unwrap();
    if (response?.statusCode === 200) {
      const updatedData = videoRes.map((x) => {
        if (x._id === id) {
          return {
            ...x,
            isBlocked: status,
          };
        }
        return x;
      });
      setVideosRes(updatedData);
      showToast(translate.error.statusUpdate);
    } else {
      showError("Please try again");
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteById({ videoId: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast(translate.videos.vdoDlt);
        getAllVideos();
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const checkPermission = () => {
    const permissions = userData?.permission;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex((ele: any) => ele?.name === "Manage Videos");

      if (idx != -1) {
        let data = {
          isAdd: permissions[idx]?.isAdd,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };

  useEffect(() => {
    getAllVideos();
  }, [debouncedSearchTerm, page]);

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translate.sideBar.freeVideos}</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-videos/add")}
              >
                {`${translate.globals.add} ${translate.videos.vdo}`}
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {translate.globals.serialNo}
                  </TableCell>
                  <TableCell>{translate.globals.title}</TableCell>
                  <TableCell>{translate.globals.description}</TableCell>
                  <TableCell>{translate.globals.status}</TableCell>
                  <TableCell>{translate.globals.actions}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {videoRes?.length ? (
                  videoRes.map((row, i) => (
                    <TableRow key={row?._id}>
                      <TableCell align="center">
                        {(page - 1) * 10 + i + 1}
                      </TableCell>
                      <TableCell>{row?.title || "-"}</TableCell>
                      <TableCell
                        title={
                          row?.description?.length > 70 ? row?.description : ""
                        }
                      >
                        {row?.description?.length > 70
                          ? row?.description?.slice(0, 70) + "..."
                          : row?.description || "-"}
                      </TableCell>
                      <TableCell>
                        <Switch
                          {...label}
                          checked={!row?.isBlocked}
                          onChange={() =>
                            updateStatus(row?._id, !row?.isBlocked)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          {/* <IconButton
                          onClick={() => navigate("/manage-courses/view")}
                        >
                          <VisibilityIcon />
                        </IconButton> */}
                          {hidePermission?.isAdd ? (
                            <Tooltip title="Edit" placement="top">
                              <IconButton
                                onClick={() =>
                                  navigate(`/manage-videos/edit/${row?._id}`)
                                }
                              >
                                <ModeEditIcon />
                              </IconButton>
                            </Tooltip>
                          ) : null}

                          {hidePermission?.isDelete ? (
                            <Tooltip title="Delete" placement="top">
                              <IconButton
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedId(row?._id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell align="center" colSpan={5}>
                      {translate.error.noData}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="video"
      />
      {videoRes?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={videoRes}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      ) : (
        ""
      )}
    </MainContainer>
  );
};

export default ManageVideo;
