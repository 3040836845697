import { END_POINTS } from "../constants/url";
import { AllUser } from "../types/General";

import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  data: {
    totalPages: number;
    count: number;
    data: AllUser[];
  };
  statusCode: number;
  message: string;
};

type GetUserParams = {
  page: number;
  query: string;
};

type UpdateUserParams = {
  userId: string | undefined;
  body: {
    firstName: string;
    lastName: string;
    phone: string;
    countryCode: string;
    email: string;
    image: string;
  };
};

export const userApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllUsers: builder.query<CommonResponseType, GetUserParams>({
      query: ({ page, query }) => ({
        url: END_POINTS.customers + "?search=" + query + "&page=" + page,
        method: "GET",
      }),
    }),

    getUserById: builder.query<
      CommonResponseType & { data: AllUser },
      { user_id: string | undefined }
    >({
      query: ({ user_id }) => ({
        url: `${END_POINTS.customers}/${user_id}`,
        method: "GET",
      }),
    }),

    changeUserStatus: builder.mutation<
      { message: string; statusCode: number },
      { userId: string; body: { isBlocked: boolean } }
    >({
      query: ({ body, userId }) => ({
        url: `${END_POINTS.customers}/${userId}`,
        method: "PUT",
        body,
      }),
    }),

    deleteUser: builder.mutation<CommonResponseType, { userId: string }>({
      query: ({ userId }) => ({
        url: `${END_POINTS.customers}/${userId}`,
        method: "DELETE",
      }),
    }),

    updateUser: builder.mutation<CommonResponseType, UpdateUserParams>({
      query: ({ body, userId }) => ({
        url: `${END_POINTS.customers}/${userId}`,
        method: "PUT",
        body,
      }),
    }),

    getUserCsv: builder.query<any, { query: string }>({
      query: ({ query }) => ({
        url: END_POINTS.exportUsers + "?search=" + query,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetAllUsersQuery,
  useLazyGetUserByIdQuery,
  useChangeUserStatusMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useLazyGetUserCsvQuery,
} = userApi;
