import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./App.scss";
import { getFromStorage, STORAGE_KEYS } from "./constants";
import LanguageContextProvider from "./context/Language";
import Routing from "./Routes";

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    if (token && window?.location?.pathname === "/") {
      window?.location?.replace("/dashboard");
    } else if (!token && window?.location?.pathname === "/") {
      navigate("/", { replace: true });
    } else if (token) {
      navigate(window?.location?.pathname);
    } else {
      navigate("/", { replace: true });
    }
  }, []);

  return (
    <LanguageContextProvider>
      <Routing />
    </LanguageContextProvider>
  );
}

export default App;
