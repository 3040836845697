import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Rating,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import {
  useLazyGetMerchantByIdQuery,
  useLazyGetMerchantCoursesQuery,
} from "../../services/merchant";
import MainContainer from "../../layout/MainContainer";
import "../users/Users.scss";
import { getFromStorage, showError, STORAGE_KEYS } from "../../constants";
import Pagination from "../../components/Pagination";
import useTranslation from "../../hooks/Translation";
import SearchBar from "../../components/SearchBar";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ChefAndPhotographerDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [getMerchantById] = useLazyGetMerchantByIdQuery();
  const [getMerchantCourse] = useLazyGetMerchantCoursesQuery();
  const translate = useTranslation();

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [merchantData, setMerchantData] = useState<any>();
  const [courseData, setCourseData] = useState<any>();
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  let totalPages = Math.ceil(totalCount / 10);

  const getMerchantsById = async () => {
    try {
      const response = await getMerchantById({
        merchantId: id,
      }).unwrap();
      if (response?.statusCode === 200) {
        setMerchantData(response?.data);
      } else {
        setMerchantData("");
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const merchantCourses = async () => {
    try {
      const response = await getMerchantCourse({
        merchantId: id,
        page: page,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        setCourseData(response?.data?.courses);
        setTotalCount(response?.data?.count);
      } else {
        setMerchantData("");
      }
    } catch (error: any) {
      console.log(error?.data?.message || "");
      setCourseData([]);
    }
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    getMerchantsById();
  }, []);

  useEffect(() => {
    merchantCourses();
  }, [page, debouncedSearchTerm]);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-chefs/photographers");
            }}
          >
            {translate.globals.back}
          </Button>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Personal Details" {...a11yProps(0)} />
              <Tab label="Uploaded Courses" {...a11yProps(1)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <Grid item lg={2} md={2} sm={6} xs={12} sx={{ paddingBottom: 5 }}>
                <img
                  src={
                    merchantData?.image
                      ? merchantData?.image
                      : "/static/images/user_placeholder.png"
                  }
                  alt=""
                  style={{ width: "150px", height: "150px" }}
                />
              </Grid>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2} className="view_box">
                  <Grid item xs={10} className="view_box_list">
                    <Grid container spacing={3}>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">
                            {translate.auth.firstName}
                          </Typography>
                          <Typography component="p">
                            {merchantData?.firstName || "-"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">
                            {translate.auth.lastName}
                          </Typography>
                          <Typography component="p">
                            {merchantData?.lastName || "-"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">
                            {translate.auth.phone}
                          </Typography>
                          <Typography component="p">
                            {merchantData?.countryCode +
                              " " +
                              merchantData?.phone || "-"}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">
                            {translate.auth.email}
                          </Typography>
                          <Typography component="p">
                            {merchantData?.email || "-"}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">
                            {translate.main.accountStatus}
                          </Typography>
                          <Typography component="p">
                            {merchantData?.isBlocked == false
                              ? translate.main.active
                              : translate.main.Inactive}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item mt={3} xs={12} className="view_box_list">
                        <Typography
                          className="detail_title mn_hdng"
                          component="h2"
                          mb={3}
                        >
                          {translate.plans.planDetails}
                        </Typography>
                        <Grid container spacing={3}>
                          <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box>
                              <Typography component="h5">
                                {translate.plans.planName}
                              </Typography>
                              <Typography component="p">
                                {merchantData?.subscription?.name || "-"}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={3}>
                            <Box>
                              <Typography component="h5">
                                {translate.plans.planType}
                              </Typography>
                              <Typography component="p">
                                {merchantData?.subscription?.planType === 1
                                  ? "Food"
                                  : "Photoshoot"}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box>
                              <Typography component="h5">
                                {translate.plans.planPrice}
                              </Typography>
                              <Typography component="p">
                                $ {merchantData?.subscription?.price || "-"}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid item mt={3} xs={12} className="view_box_list">
                        <Typography
                          className="detail_title mn_hdng"
                          component="h2"
                          mb={3}
                        >
                          {translate.main.acctDetails}
                        </Typography>
                        <Grid container spacing={3}>
                          <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box>
                              <Typography component="h5">
                                {translate.main.acctNo}
                              </Typography>
                              <Typography component="p">
                                {merchantData?.accountNumber || "-"}
                              </Typography>
                            </Box>
                          </Grid>
                          {/* <Grid item xs={3}>
                        <Box>
                          <Typography component="h5">Plan Type</Typography>
                          <Typography component="p">1 Month</Typography>
                        </Box>
                      </Grid> */}
                          <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box>
                              <Typography component="h5">
                                {translate.main.holder}
                              </Typography>
                              <Typography component="p">
                                {merchantData?.holderName || "-"}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box>
                              <Typography component="h5">
                                {translate.main.swift}
                              </Typography>
                              <Typography component="p">
                                {merchantData?.swiftCode || "-"}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item lg={3} md={3} sm={6} xs={12}>
                            <Box>
                              <Typography component="h5">
                                {translate.main.bank}
                              </Typography>
                              <Typography component="p">
                                {merchantData?.bankName || "-"}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item mt={3} xs={12} className="view_box_list">
                        <Typography
                          className="detail_title mn_hdng"
                          component="h2"
                          mb={3}
                        >
                          {translate.main.document}
                        </Typography>
                        {merchantData?.documents
                          ? merchantData?.documents?.map((item: string) => (
                              <img
                                src={item}
                                width={300}
                                height={200}
                                style={{ marginRight: 20 }}
                              />
                            ))
                          : ""}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2} className="view_box">
                  <Grid item xs={12}>
                    <Typography
                      className="detail_title mn_hdng"
                      component="h2"
                      mb={3}
                    >
                      {translate.main.uploadedCourse}
                    </Typography>
                    <Box className="cards_header" sx={{ pb: 3 }}>
                      <SearchBar
                        value={searchTerm}
                        searchTerm={searchTerm}
                        onCross={() => setSearchTerm("")}
                        setDebouncedSearchTerm={setDebouncedSearchTerm}
                        onChange={(val: any) => {
                          setSearchTerm(val.target.value);
                        }}
                      />
                    </Box>
                    <TableContainer
                      className="table_container"
                      sx={{ margin: 0 }}
                    >
                      <Box className="heading"></Box>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell align="center">
                              {translate.globals.serialNo}
                            </TableCell>
                            <TableCell>{translate.globals.name}</TableCell>
                            <TableCell> {translate.globals.price}</TableCell>

                            <TableCell>{translate.course.ratings}</TableCell>
                            {/* <TableCell>Actions</TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {courseData?.length > 0 ? (
                            courseData?.map((row: any, i: any) => (
                              <TableRow key={i}>
                                <TableCell align="center">{i + 1}</TableCell>
                                <TableCell>{row?.title || "-"}</TableCell>
                                <TableCell>$ {row?.price}</TableCell>

                                <TableCell>
                                  <Rating
                                    name="read-only"
                                    value={row?.ratings}
                                    readOnly
                                  />
                                </TableCell>

                                {/* <TableCell>
                              <Box className="table_actions">
                                <IconButton>
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            </TableCell> */}
                              </TableRow>
                            ))
                          ) : (
                            <TableRow>
                              <TableCell colSpan={9} align="center">
                                {translate.error.noData}
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    {courseData?.length > 0 ? (
                      <Pagination
                        setPage={setPage}
                        module={courseData}
                        page={page}
                        onPageChange={onPageChange}
                        totalPages={totalPages}
                      />
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ChefAndPhotographerDetails;
