import { string } from "yup";
import { END_POINTS } from "../constants/url";
import { Merchants } from "../types/General";

import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
  totalPages: number;
};

type getMerchantParams = {
  merchantType: number;
  page: number;
  query: string;
};

type CommonParams = {
  page: number;
  query: string;
  merchantId: string | undefined;
};

type UpdateRequestParams = {
  merchantId: string | undefined;
  body: {
    profileStatus: number;
  };
};

type AddMerchantBody = {
  firstName: string;
  lastName: string;
  email: string;
  countryCode: string;
  phone: string;
  dob: string;
  bio: string;
  headline: string;
  address: string;
  profession: number;
  accountNumber: string;
  holderName: string;
  swiftCode: string;
  bankName: string;
  documents: any;
};

type updateMerchantParams = {
  merchantId: string;
  body: {
    firstName: string;
    lastName: string;
    email: string;
    countryCode: string;
    phone: string;
    dob: string;
    bio: string;
    headline: string;
    address: string;
    profession: number;
    accountNumber: string;
    holderName: string;
    swiftCode: string;
    bankName: string;
    documents: any;
  };
};

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getMerchants: builder.query<
      CommonResponseType & { data: { data: Merchants[]; count: number } },
      getMerchantParams
    >({
      query: ({ merchantType, page, query }) => ({
        url: `${END_POINTS.merchant}?merchantType=${merchantType}&search=${query}&page=${page}`,
        method: "GET",
      }),
    }),

    changeMerchantStatus: builder.mutation<
      { message: string; statusCode: number },
      { merchantId: string; body: { isBlocked: boolean } }
    >({
      query: ({ body, merchantId }) => ({
        url: `${END_POINTS.merchant}/${merchantId}`,
        method: "PUT",
        body,
      }),
    }),

    changeSubscription: builder.mutation<
      { message: string; statusCode: number },
      { merchantId: string; body: { isSubscriptionRequired: boolean } }
    >({
      query: ({ body, merchantId }) => ({
        url: `${END_POINTS.merchant}/${merchantId}`,
        method: "PUT",
        body,
      }),
    }),

    updateMerchantRequest: builder.mutation<
      CommonResponseType,
      UpdateRequestParams
    >({
      query: ({ body, merchantId }) => ({
        url: `${END_POINTS.merchant}/${merchantId}`,
        method: "PUT",
        body,
      }),
    }),

    deleteMerchant: builder.mutation<
      CommonResponseType,
      { merchantId: string }
    >({
      query: ({ merchantId }) => ({
        url: `${END_POINTS.merchant}/${merchantId}`,
        method: "DELETE",
      }),
    }),

    addMerchant: builder.mutation<
      { message: string; statusCode: number; data: any },
      AddMerchantBody
    >({
      query: (body) => ({
        url: END_POINTS.merchant,
        method: "POST",
        body,
      }),
    }),

    updateMerchant: builder.mutation<CommonResponseType, updateMerchantParams>({
      query: ({ body, merchantId }) => ({
        url: `${END_POINTS.merchant}/${merchantId}`,
        method: "PUT",
        body,
      }),
    }),

    getMerchantById: builder.query<
      CommonResponseType & { data: any },
      { merchantId: string | undefined }
    >({
      query: ({ merchantId }) => ({
        url: `${END_POINTS.merchant}/${merchantId}`,
        method: "GET",
      }),
    }),

    getMerchantCourses: builder.query<
      CommonResponseType & {
        data: {
          count: number;
          totalCount: number;
          courses: any;
        };
      },
      CommonParams
    >({
      query: ({ page, query, merchantId }) => ({
        url:
          END_POINTS.course +
          "?merchant=" +
          merchantId +
          "&page=" +
          page +
          "&search=" +
          query,
        method: "GET",
      }),
    }),

    getMerchantCsv: builder.query<any, { query: string }>({
      query: ({ query }) => ({
        url: END_POINTS.exportMerchants + "?search=" + query,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetMerchantsQuery,
  useChangeMerchantStatusMutation,
  useUpdateMerchantRequestMutation,
  useDeleteMerchantMutation,
  useAddMerchantMutation,
  useUpdateMerchantMutation,
  useLazyGetMerchantByIdQuery,
  useLazyGetMerchantCoursesQuery,
  useLazyGetMerchantCsvQuery,
  useChangeSubscriptionMutation,
} = authApi;
