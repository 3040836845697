import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Merchants, RequestStatus } from "../../types/General";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Select,
  SelectChangeEvent,
  MenuItem,
  Tabs,
  Tab,
  Typography,
  Modal,
  FormControl,
  TextField,
  Checkbox,
  Tooltip,
} from "@mui/material";
import {
  useLazyGetMerchantsQuery,
  useChangeMerchantStatusMutation,
  useUpdateMerchantRequestMutation,
  useDeleteMerchantMutation,
  useLazyGetMerchantCsvQuery,
  useChangeSubscriptionMutation,
} from "../../services/merchant";
import { useLocation, useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import {
  getFromStorage,
  Loader,
  showError,
  showToast,
  STORAGE_KEYS,
} from "../../constants";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";
import { isValidInput } from "../../utils/validations";
import useAuth from "../../hooks/useAuth";
import useTranslation from "../../hooks/Translation";

const ManageChefs = () => {
  const location = useLocation();
  const { state } = location;
  const getLang = getFromStorage(STORAGE_KEYS.language);
  const userData = useAuth();
  const translation = useTranslation();
  const navigate = useNavigate();
  const [getMerchantQuery] = useLazyGetMerchantsQuery();
  const [merchantStatus] = useChangeMerchantStatusMutation();
  const [updateRequest] = useUpdateMerchantRequestMutation();
  const [deleteMerchant] = useDeleteMerchantMutation();
  const [changeSubscription] = useChangeSubscriptionMutation();
  const [merchantCsv] = useLazyGetMerchantCsvQuery();
  const [merchantData, setMerchantData] = useState<Merchants[]>([]);
  const [value, setValue] = useState<number>(0);
  const [isRejectModalOpen, setRejectModalOpen] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string>("");
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });
  const [loading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  console.log(selectedRows);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  let totalPages = Math.ceil(totalCount / 10);

  const getMerchant = async () => {
    setLoading(true);
    try {
      const response = await getMerchantQuery({
        merchantType: value == 0 ? 1 : 2,
        query: debouncedSearchTerm.trim(),
        page: page,
      }).unwrap();
      if (response?.statusCode === 200) {
        setLoading(false);
        setMerchantData(response?.data?.data || []);
        setTotalCount(response?.data?.count);
      } else {
        setMerchantData([]);
      }
    } catch (error: any) {
      setLoading(false);
      showError(error?.data?.message || "");
    }
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setPage(1);
    setSearchTerm("");
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const handleRejectModalClose = () => {
    // Close the modal
    setRejectModalOpen(false);
  };

  const handleRequestStatus = async (event: SelectChangeEvent, id: string) => {
    const body = {
      profileStatus: Number(event.target.value),
    };

    console.log(body);

    try {
      const response = await updateRequest({ merchantId: id, body }).unwrap();
      if (response?.statusCode === 200) {
        showToast(response?.message);
        getMerchant();
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const updateStatus = async (id: string, isBlocked: boolean | undefined) => {
    const body = {
      isBlocked: isBlocked == true ? false : true,
    };
    try {
      const response = await merchantStatus({ merchantId: id, body }).unwrap();
      if (response?.statusCode === 200) {
        showToast(translation.error.statusUpdate);
        getMerchant();
      } else {
        showError("Please try again");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateSubscriptionStatus = async (
    id: string,
    isSubscriptionRequired: boolean | undefined
  ) => {
    const body = {
      isSubscriptionRequired: isSubscriptionRequired == true ? false : true,
    };
    try {
      const response = await changeSubscription({
        merchantId: id,
        body,
      }).unwrap();
      if (response?.statusCode === 200) {
        showToast(translation.error.statusUpdate);
        getMerchant();
      } else {
        showError("Please try again");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteMerchant({ merchantId: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast(translation.error.merchantDel);
        getMerchant();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const checkPermission = () => {
    const permissions = userData?.permission;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.name === "Manage Chefs/Photographers"
      );

      if (idx != -1) {
        let data = {
          isAdd: permissions[idx]?.isAdd,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };

  const getMerchantCsv = async () => {
    try {
      const response = await merchantCsv({
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        window?.open(response?.data);
      } else {
        console.log("error");
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getMerchant();
  }, [value, debouncedSearchTerm, page]);

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);

  useEffect(() => {
    if (state == "photo") {
      setValue(1);
    }
  }, []);

  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translation?.sideBar?.merchants}</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label={translation?.globals?.chefs} {...a11yProps(0)} />
              <Tab
                label={translation?.globals?.photographers}
                {...a11yProps(1)}
              />
            </Tabs>
            <Box className="cards_header">
              <SearchBar
                value={searchTerm}
                searchTerm={searchTerm}
                onCross={() => setSearchTerm("")}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <Button className="btn btn_primary" onClick={getMerchantCsv}>
                  <FileDownloadIcon /> {translation?.globals?.export}
                </Button>
                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("/manage-chefs/photographers/add")}
                >
                  {translation?.globals?.add}
                </Button>
              </Box>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        {translation?.globals?.serialNo}
                      </TableCell>

                      <TableCell>{translation?.globals?.name}</TableCell>
                      <TableCell>{translation.auth.nickName}</TableCell>
                      <TableCell>{translation?.auth?.phone}</TableCell>
                      <TableCell>{translation?.auth?.email}</TableCell>
                      <TableCell>{translation?.auth?.profileStatus}</TableCell>
                      <TableCell>{translation?.main.subsReq}</TableCell>
                      <TableCell>{translation?.globals?.request}</TableCell>

                      <TableCell>{translation?.globals?.actions}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {merchantData?.length > 0 ? (
                      merchantData.map((row, i) => (
                        <TableRow key={row?._id}>
                          {/* <TableCell>
                            <Checkbox
                              checked={selectedRows.includes(row._id)}
                              onChange={() => handleCheckboxChange(row._id)}
                            />
                          </TableCell> */}
                          <TableCell align="center">{i + 1}</TableCell>

                          <TableCell>{`${row.firstName || "-"} ${
                            row?.lastName
                          }`}</TableCell>
                          <TableCell>{row?.nickName || "-"}</TableCell>

                          <TableCell>{`${row?.countryCode || ""} ${
                            row.phone || "-"
                          }`}</TableCell>
                          <TableCell>{row.email || "-"}</TableCell>

                          <TableCell>
                            <Switch
                              {...label}
                              defaultChecked
                              checked={!row?.isBlocked}
                              onChange={() =>
                                updateStatus(row?._id, row?.isBlocked)
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <Switch
                              {...label}
                              defaultChecked
                              checked={row?.isSubscriptionRequired}
                              onChange={() =>
                                updateSubscriptionStatus(
                                  row?._id,
                                  row?.isSubscriptionRequired
                                )
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <FormControl sx={{ width: "100%" }}>
                              <Select
                                className="select_div"
                                fullWidth
                                disabled={row?.profileStatus === 2}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={
                                  row?.profileStatus === 1
                                    ? "1"
                                    : row?.profileStatus === 2
                                    ? "2"
                                    : "3"
                                }
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                                displayEmpty
                                onChange={(val: any) =>
                                  handleRequestStatus(val, row?._id)
                                }
                              >
                                <MenuItem value="1" disabled>
                                  {translation.main.pending}
                                </MenuItem>

                                <MenuItem value="2">
                                  {row?.profileStatus === 2
                                    ? translation.main.accepted
                                    : translation.main.accept}
                                </MenuItem>
                                <MenuItem value="3">
                                  {row?.profileStatus === 3
                                    ? translation.main.rejected
                                    : translation.main.reject}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <Box className="table_actions">
                              <Tooltip
                                title={translation.globals.view}
                                placement="top"
                              >
                                <IconButton
                                  onClick={() =>
                                    navigate(
                                      `/manage-chefs/photographers/details/${row?._id}`
                                    )
                                  }
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              </Tooltip>

                              {hidePermission?.isAdd ? (
                                <Tooltip
                                  title={translation.globals.edit}
                                  placement="top"
                                >
                                  <IconButton
                                    onClick={() =>
                                      navigate(
                                        `/manage-chefs/photographers/edit/${row?._id}`
                                      )
                                    }
                                  >
                                    <ModeEditIcon />
                                  </IconButton>
                                </Tooltip>
                              ) : null}

                              {hidePermission?.isDelete ? (
                                <Tooltip
                                  title={translation.globals.delete}
                                  placement="top"
                                >
                                  <IconButton
                                    onClick={() => {
                                      setOpen(true);
                                      setSelectedId(row?._id);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              ) : null}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={9} align="center">
                          {translation?.error?.noData}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">
                        {translation?.globals?.serialNo}
                      </TableCell>

                      <TableCell>{translation?.globals?.name}</TableCell>
                      <TableCell>{translation.auth.nickName}</TableCell>
                      <TableCell>{translation?.auth?.phone}</TableCell>
                      <TableCell>{translation?.auth?.email}</TableCell>
                      <TableCell>{translation?.auth?.profileStatus}</TableCell>
                      <TableCell>{translation?.main.subsReq}</TableCell>
                      <TableCell>{translation?.globals?.request}</TableCell>

                      <TableCell>{translation?.globals?.actions}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {merchantData?.length > 0 ? (
                      merchantData.map((row: any, i: any) => (
                        <TableRow key={row.name}>
                          <TableCell align="center">{i + 1}</TableCell>

                          <TableCell>{`${row.firstName_ar || "-"}`}</TableCell>
                          <TableCell>{row?.nickName || "-"}</TableCell>

                          <TableCell>{`${row?.countryCode || ""} ${
                            row.phone || "-"
                          }`}</TableCell>
                          <TableCell>{row.email || "-"}</TableCell>

                          <TableCell>
                            <Switch
                              {...label}
                              defaultChecked
                              checked={!row?.isBlocked}
                              onChange={() =>
                                updateStatus(row?._id, row?.isBlocked)
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <Switch
                              {...label}
                              defaultChecked
                              checked={row?.isSubscriptionRequired}
                              onChange={() =>
                                updateSubscriptionStatus(
                                  row?._id,
                                  row?.isSubscriptionRequired
                                )
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <FormControl sx={{ width: "100%" }}>
                              <Select
                                className="select_div"
                                fullWidth
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                disabled={row?.profileStatus === 2}
                                value={
                                  row?.profileStatus === 1
                                    ? "1"
                                    : row?.profileStatus === 2
                                    ? "2"
                                    : "3"
                                }
                                inputProps={{
                                  "aria-label": "Without label",
                                }}
                                displayEmpty
                                onChange={(val: any) =>
                                  handleRequestStatus(val, row?._id)
                                }
                              >
                                <MenuItem value="1" disabled>
                                  {translation.main.pending}
                                </MenuItem>

                                <MenuItem value="2">
                                  {row?.profileStatus === 2
                                    ? translation.main.accepted
                                    : translation.main.accept}
                                </MenuItem>
                                <MenuItem value="3">
                                  {row?.profileStatus === 3
                                    ? translation.main.rejected
                                    : translation.main.reject}
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            <Box className="table_actions">
                              <Tooltip
                                title={translation.globals.view}
                                placement="top"
                              >
                                <IconButton
                                  onClick={() =>
                                    navigate(
                                      `/manage-chefs/photographers/details/${row?._id}`
                                    )
                                  }
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              </Tooltip>

                              {hidePermission?.isAdd ? (
                                <Tooltip
                                  title={translation.globals.edit}
                                  placement="top"
                                >
                                  <IconButton
                                    onClick={() =>
                                      navigate(
                                        `/manage-chefs/photographers/edit/${row?._id}`
                                      )
                                    }
                                  >
                                    <ModeEditIcon />
                                  </IconButton>
                                </Tooltip>
                              ) : null}
                              {hidePermission?.isDelete ? (
                                <Tooltip
                                  title={translation.globals.delete}
                                  placement="top"
                                >
                                  <IconButton
                                    onClick={() => {
                                      setOpen(true);
                                      setSelectedId(row?._id);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              ) : null}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={9} align="center">
                          {translation?.error?.noData}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
          </Box>
        </Card>
        {merchantData?.length > 0 ? (
          <Pagination
            setPage={setPage}
            module={merchantData}
            page={page}
            onPageChange={onPageChange}
            totalPages={totalPages}
          />
        ) : (
          ""
        )}
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="Merchant"
      />
      <Modal
        open={isRejectModalOpen}
        onClose={handleRejectModalClose}
        aria-labelledby="reject-modal-title"
        aria-describedby="reject-modal-description"
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <div
          style={{
            width: "40%",
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
            height: "30%",
            borderRadius: 12,
            paddingTop: 50,
          }}
        >
          <form>
            <FormControl fullWidth>
              <TextField
                hiddenLabel
                className="text_field"
                autoFocus
                variant="outlined"
                name="message"
                type="text"
                placeholder="Cancellation reason"
                multiline
                minRows={3}
                fullWidth
                sx={{ width: 400 }}
              />
            </FormControl>
            <Box
              className="form_btn"
              sx={{ display: "flex", justifyContent: "center", gap: "10px" }}
            >
              <Button onClick={handleRejectModalClose}>Cancel</Button>
              <Button className="btn btn_primary" type="submit">
                Send
              </Button>
            </Box>
          </form>
        </div>
      </Modal>
    </MainContainer>
  );
};

export default ManageChefs;
