import React, { useEffect, useState } from "react";
import MainContainer from "../../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import Box from "@mui/material/Box";
import SearchBar from "../../../components/SearchBar";
import DeleteIcon from "@mui/icons-material/Delete";
import { isValidInput } from "../../../utils/validations";
import { useNavigate } from "react-router-dom";
import {
  useDeleteDiscountMutation,
  useLazyGetDiscountCouponsQuery,
} from "../../../services/discount";
import { Discount } from "../../../types/General";
import { showError, showToast } from "../../../constants";
import moment from "moment";
import WarnModal from "../../../components/WarnModal";
import Pagination from "../../../components/Pagination";
import useTranslation from "../../../hooks/Translation";

const DiscountCode = () => {
  const navigate = useNavigate();
  const translate = useTranslation();
  const [discountQuery, { isLoading }] = useLazyGetDiscountCouponsQuery();
  const [deleteDiscount] = useDeleteDiscountMutation();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [selectedId, setSelectedId] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [discountData, setDiscountData] = useState<Discount[]>([]);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  let totalPages = Math.ceil(totalCount / 10);

  const getDiscounts = async () => {
    try {
      const response = await discountQuery({
        page: page,
        query: debouncedSearchTerm.trim(),
        type: 2,
      }).unwrap();
      if (response?.statusCode === 200) {
        setDiscountData(response?.data?.data || []);
        setTotalCount(response?.data?.count);
      } else {
        setDiscountData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteDiscount({ discountId: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast("Discount deleted successfully");
        getDiscounts();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    getDiscounts();
  }, [page, debouncedSearchTerm]);

  return (
    <div className="main_loyout">
      <Box className="cards_header">
        <SearchBar
          value={searchTerm}
          searchTerm={searchTerm}
          onCross={() => setSearchTerm("")}
          setDebouncedSearchTerm={setDebouncedSearchTerm}
          onChange={(val: any) => {
            if (isValidInput(val.target.value)) {
              setSearchTerm(val.target.value);
            }
          }}
        />
        <Box className="cards_header_right">
          <Button
            className="btn btn_primary"
            onClick={() =>
              navigate("/manage-subscription/add-discount-code/add")
            }
          >
            {`${translate.globals.add} ${translate.main.coupon}`}
          </Button>
        </Box>
      </Box>
      <TableContainer className="table_container">
        <Box className="heading"></Box>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">{translate.globals.serialNo}</TableCell>
              <TableCell>{translate.main.coupon}</TableCell>
              <TableCell>{translate.main.validFrom}</TableCell>
              <TableCell>{translate.main.validTill}</TableCell>

              <TableCell>{translate.globals.actions}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {discountData?.length > 0 ? (
              discountData.map((row: any, i: any) => (
                <TableRow>
                  <TableCell align="center">{i + 1}</TableCell>
                  {/* <TableCell>Premium</TableCell>
                  <TableCell>Customer</TableCell> */}
                  <TableCell>{row?.code || "-"}</TableCell>
                  <TableCell>
                    {moment(row?.valideFrom).format("MM-D-YY")}
                  </TableCell>
                  <TableCell>
                    {moment(row?.valideTill).format("MM-D-YY")}
                  </TableCell>

                  <TableCell>
                    <Box className="table_actions">
                      <Tooltip placement="top" title={translate.globals.edit}>
                        <IconButton
                          onClick={() =>
                            navigate(
                              `/manage-subscription/add-discount-code/edit/${row?._id}`
                            )
                          }
                        >
                          <ModeEditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip placement="top" title={translate.globals.delete}>
                        <IconButton
                          onClick={() => {
                            setOpen(true);
                            setSelectedId(row?._id);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={9} align="center">
                  {translate.error.noData}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {discountData?.length > 0 ? (
        <Pagination
          setPage={setPage}
          module={discountData}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      ) : (
        ""
      )}
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="Coupon"
      />
    </div>
  );
};

export default DiscountCode;
