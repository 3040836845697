import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Tooltip,
} from "@mui/material";
import {
  useChangeUserStatusMutation,
  useLazyGetAllUsersQuery,
  useDeleteUserMutation,
  useLazyGetUserCsvQuery,
} from "../../services/users";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import { isValidInput } from "../../utils/validations";
import { Loader, showError, showToast } from "../../constants";
import { AllUser } from "../../types/General";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/WarnModal";
import { Permissions } from "../../types/General";
import useAuth from "../../hooks/useAuth";
import useTranslation from "../../hooks/Translation";

const ManageUsers = () => {
  const userData = useAuth();
  const translate = useTranslation();
  const navigate = useNavigate();
  const [getAllUserData] = useLazyGetAllUsersQuery();
  const [userCsv] = useLazyGetUserCsvQuery();
  const [userStatus] = useChangeUserStatusMutation();
  const [deleteUser] = useDeleteUserMutation();
  const [loading, setLoading] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [allUsers, setAllUsers] = useState<AllUser[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedId, setSelectedId] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [approvedUsers, setApprovedUsers] = useState<any>();
  const [users, setUsers] = useState<any>();
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });

  const getUsers = async () => {
    setLoading(true);
    try {
      const response = await getAllUserData({
        query: debouncedSearchTerm.trim(),
        page: page,
      }).unwrap();
      if (response?.statusCode === 200) {
        setLoading(false);
        setAllUsers(response?.data?.data || []);
        setTotalCount(response?.data?.count);
      } else {
        setAllUsers([]);
        setLoading(false);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
      setLoading(false);
    }
  };

  const getUserCsv = async () => {
    try {
      const response = await userCsv({
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (response?.statusCode === 200) {
        window?.open(response?.data);
      } else {
        console.log("error");
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  let totalPages = Math.ceil(totalCount / 10);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const updateStatus = async (id: string, state: boolean | undefined | any) => {
    const response = await userStatus({
      userId: id,
      body: {
        isBlocked: !state,
      },
    }).unwrap();
    if (response?.statusCode === 200) {
      const updatedData = allUsers.map((x) => {
        if (x._id === id) {
          return {
            ...x,
            isBlocked: !state,
          };
        }
        return x;
      });
      setAllUsers(updatedData);
      showToast(translate.error.statusUpdate);
    } else {
      showError("Please try again");
    }
  };

  const handleDelete = async (id: string) => {
    try {
      const response = await deleteUser({ userId: id }).unwrap();
      if (response?.statusCode === 200) {
        showToast(translate.error.userDel);
        getUsers();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const checkPermission = () => {
    const permissions = userData?.permission;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex(
        (ele: any) => ele?.name === "Manage Customers"
      );

      if (idx != -1) {
        let data = {
          isAdd: permissions[idx]?.isAdd,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };

  useEffect(() => {
    getUsers();
  }, [debouncedSearchTerm, page]);

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);

  return (
    <MainContainer>
      <Loader isLoad={loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">{translate?.sideBar?.customer}</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              value={searchTerm}
              searchTerm={searchTerm}
              onCross={() => setSearchTerm("")}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              <Button className="btn btn_primary" onClick={getUserCsv}>
                <FileDownloadIcon /> {translate?.globals?.export}
              </Button>
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    {translate?.globals?.serialNo}
                  </TableCell>

                  <TableCell>{translate?.globals?.name}</TableCell>
                  <TableCell>{translate?.auth?.nickName}</TableCell>
                  <TableCell>{translate?.auth?.phone}</TableCell>
                  <TableCell>{translate?.auth?.email}</TableCell>
                  <TableCell>{translate?.plans?.planName}</TableCell>
                  <TableCell>{translate?.plans?.planPrice}</TableCell>
                  <TableCell>{translate?.globals?.status}</TableCell>
                  <TableCell>{translate?.globals?.actions}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allUsers?.length > 0 ? (
                  allUsers?.map((row, i) => (
                    <TableRow key={row.name}>
                      <TableCell align="center">{i + 1}</TableCell>

                      <TableCell>{`${row?.firstName || "-"} ${
                        row?.lastName
                      }`}</TableCell>
                      <TableCell>{row?.nickName || "-"}</TableCell>
                      <TableCell>
                        {row?.countryCode + " " + row?.phone || "-"}
                      </TableCell>
                      <TableCell>{row?.email || "-"}</TableCell>
                      <TableCell>{"-"}</TableCell>
                      <TableCell>{"-"}</TableCell>
                      <TableCell>
                        <Switch
                          {...label}
                          defaultChecked
                          checked={!row?.isBlocked}
                          onChange={() =>
                            updateStatus(row?._id, row?.isBlocked)
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          <Tooltip
                            title={translate.globals.view}
                            placement="top"
                          >
                            <IconButton
                              onClick={() =>
                                navigate(`/manageusers/userdetails/${row?._id}`)
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                          {hidePermission?.isAdd ? (
                            <Tooltip
                              title={translate.globals.edit}
                              placement="top"
                            >
                              <IconButton
                                onClick={() =>
                                  navigate(`/manageusers/usersform/${row?._id}`)
                                }
                              >
                                <ModeEditIcon />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                          {hidePermission?.isDelete ? (
                            <Tooltip
                              title={translate.globals.delete}
                              placement="top"
                            >
                              <IconButton
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedId(row?._id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={9} align="center">
                      {translate?.error?.noData}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        {allUsers?.length > 0 ? (
          <Pagination
            setPage={setPage}
            module={allUsers}
            page={page}
            onPageChange={onPageChange}
            totalPages={totalPages}
          />
        ) : (
          ""
        )}
      </div>
      <WarnModal
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(selectedId)}
        name="User"
      />
    </MainContainer>
  );
};

export default ManageUsers;
