import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
import {
  getFromStorage,
  Loader,
  showError,
  showToast,
  STORAGE_KEYS,
} from "../../constants";
import {
  useLazyGetVideoByIdQuery,
  useUpdateVideoMutation,
  useAddVideoMutation,
} from "../../services/videos";
import { isString } from "../../utils/validations";
import { UploadMedia, UploadVideo } from "../../utils/mediaUpload";
import { AllVideo } from "../../types/General";
import { useLazyGetSettingsQuery } from "../../services/main";
import useTranslation from "../../hooks/Translation";

const AddPromotionalVideo = () => {
  const { id } = useParams();
  const translate = useTranslation();
  const getLang = getFromStorage(STORAGE_KEYS.language);
  const [addVideo, { isLoading }] = useAddVideoMutation();
  const [videoById] = useLazyGetVideoByIdQuery();
  const [updateVideoMutation, updateVideoData] = useUpdateVideoMutation();
  const [settingQuery] = useLazyGetSettingsQuery();
  const [setting, setSetting] = useState<any>();

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const [getVideo, setGetVideo] = useState<AllVideo | null>(null);
  const [videoDuration, setVideoDuration] = useState<any>(null);
  const [thumbnail, setThumbnail] = useState<string>("");

  const navigate = useNavigate();

  const convertDataUrltoImgUrl = async (base64Image: any) => {
    const byteCharacters = atob(base64Image.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: "image/jpeg" });

    // Create an image URL from the Blob
    const imageUrl = URL.createObjectURL(blob);
    // Now, 'imageUrl' contains a regular image URL that you can use in your application
    const response = await UploadMedia(blob);
    if (response?.statusCode === 200) {
      // formik.setFieldValue("coverImg", response?.data);
      setThumbnail(response?.data);
    } else {
      showError(response?.message);
    }
  };

  const getAllSettings = async () => {
    try {
      const response = await settingQuery({}).unwrap();
      if (response?.statusCode === 200) {
        setSetting(response?.data[0]);
      } else {
        setSetting("");
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const handleVideoUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];

    if (file) {
      const fileSizeLimit = setting?.maxVideoSize * 1024 * 1024; // 6 MB in bytes

      if (file.size > fileSizeLimit) {
        showError(
          `Please select a video file smaller than ${setting?.maxVideoSize} MB`
        );
        event.target.value = "";
        setThumbnail("");
        return;
      }

      const isWebMSupported = setting?.isWebMSupported ?? false;
      const isMp4Supported = setting?.isMp4Supported ?? false;
      const isAVISupported = setting?.isAVISupported ?? false;

      const supportedFormats = [];
      if (isWebMSupported) {
        supportedFormats.push("WebM");
      }
      if (isMp4Supported) {
        supportedFormats.push("MP4");
      }
      if (isAVISupported) {
        supportedFormats.push("AVI");
      }

      // Check if the selected file type is supported
      const allowedTypes = [];
      if (isWebMSupported) {
        allowedTypes.push("video/webm");
      }
      if (isMp4Supported) {
        allowedTypes.push("video/mp4");
      }
      if (isAVISupported) {
        allowedTypes.push("video/x-msvideo");
      }

      if (allowedTypes.length === 0 || !allowedTypes.includes(file.type)) {
        showError(`Please upload ${supportedFormats.join(", ")} formats only`);
        event.target.value = "";
        return;
      }

      setLoading(true);
      // Create a video element
      const video = document.createElement("video");
      video.preload = "metadata";
      // Set the video source
      video.src = URL.createObjectURL(file);

      // Listen for the loadedmetadata event
      video.addEventListener("loadeddata", async () => {
        const duration = video.duration;
        console.log(duration, "sdfdsfdfsdf");
        const reqDuration = setting?.maxVideoDuration * 60;
        console.log(reqDuration);

        if (duration > reqDuration) {
          showError(
            `Please select a video shorter than ${setting?.maxVideoDuration} minutes`
          );
          setLoading(false);
          event.target.value = "";
          video.remove();
          return;
        }

        setVideoDuration(Math.ceil(duration));
        // Set the currentTime to a suitable position for capturing a thumbnail
        video.currentTime = Math.min(1, duration); // Capture thumbnail after 1 second

        // Wait for the seeked event
        video.addEventListener("seeked", async () => {
          // Create a canvas element
          const canvas = document.createElement("canvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;

          // Draw the current frame onto the canvas
          const context = canvas.getContext("2d");
          context?.drawImage(video, 0, 0, canvas.width, canvas.height);

          // Convert the canvas to a data URL representing the thumbnail
          const base64Image = canvas.toDataURL("image/jpeg");
          convertDataUrltoImgUrl(base64Image);

          if (file.type.startsWith("video/")) {
            const res = await UploadVideo(file);
            if (res?.statusCode === 200) {
              formik.setFieldValue("videoLink", res?.data);
              setLoading(false);
            } else {
              setLoading(false);
              showError("Invalid file format : only .mp4 files are allowed");
            }
          } else {
            // Display an error message for non-image files
            showError("Please select a valid video");
          }

          // Clean up: remove the video and canvas elements
          video.remove();
          canvas.remove();
        });
      });

      // Append the video element to the document (necessary for triggering events)
      document.body.appendChild(video);
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: getVideo?.title || "",
      title_ar: getVideo?.title_ar || "",
      description: getVideo?.description || "",
      videoLink: getVideo?.link || "",
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required(translate.error.titlereq)
        .max(70, "Maximum 70 character are allowed")
        .min(3, "Minimum 2 characters are required"),
      title_ar: Yup.string()
        .required(translate.error.titlereq)
        .max(70, "Maximum 70 character are allowed")
        .min(3, "Minimum 2 characters are required"),

      description: Yup.string()
        .required(translate.error.descReq)
        .max(800, "Maximum 800 character are allowed")
        .min(3, "Minimum 2 characters are required"),

      videoLink: Yup.string().required("This field is required"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);

      if (!values?.videoLink) {
        setError(true);
      }

      const body = {
        title: values?.title,
        title_ar: values?.title_ar,
        description: values?.description,

        link: values?.videoLink,
        videoType: 3,
        duration: videoDuration,
        thumbnail: thumbnail,
        language: getLang === "en" ? 1 : 2,
      };

      if (id && id !== "add") {
        try {
          const response = await updateVideoMutation({
            videoId: id,
            body,
          }).unwrap();
          if (response?.statusCode === 200) {
            showToast("Video Updated successfully");
            formik.resetForm();
            navigate("/promotional-videos");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
      } else {
        try {
          const response = await addVideo(body).unwrap();
          if (response?.statusCode === 200) {
            showToast("Video added successfully");
            formik.resetForm();
            navigate("/promotional-videos");
          } else {
            showError(response?.message || "");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
      }
    },
  });

  const getVideoById = async (id: string) => {
    try {
      const response = await videoById({ videoId: id }).unwrap();
      if (response?.statusCode === 200) {
        setGetVideo(response?.data);
        setThumbnail(response?.data?.thumbnail || "");
        setVideoDuration(response?.data?.duration || "");
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getVideoById(id);
    }
  }, []);

  useEffect(() => {
    getAllSettings();
  }, []);

  return (
    <MainContainer>
      <Loader isLoad={isLoading || loading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">
            {id ? translate.globals.edit : translate.globals.add}{" "}
            {translate.videos.vdo}
          </h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/promotional-videos");
            }}
          >
            {translate.globals.back}
          </Button>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={4} sm={4} xs={12}>
                  <Typography className="custom_label">
                    {translate.videos.uploadVideo}
                  </Typography>
                  {formik?.values?.videoLink ? (
                    <div className="upload_image_preview2">
                      <video controls width="100%" height="100%">
                        <source
                          src={formik?.values?.videoLink}
                          type="video/mp4"
                        />
                      </video>
                      <CancelIcon
                        onClick={() => formik.setFieldValue("videoLink", "")}
                      />
                    </div>
                  ) : (
                    <Box className="upload_image_bnr">
                      <label htmlFor="icon-button-file-vdo">
                        <Input
                          sx={{ display: "none" }}
                          id="icon-button-file-vdo"
                          type="file"
                          inputProps={{
                            accept: "video/*",
                          }}
                          onChange={handleVideoUpload}
                        />
                        <Button component="span" className="upload_image_btn">
                          <AddIcon />
                        </Button>
                      </label>
                    </Box>
                  )}
                  {error && !formik.values.videoLink ? (
                    <h6 className="err_msg">{translate.error.required}</h6>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}></Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {`${translate?.globals?.title} ${translate?.globals?.english}`}
                  </Typography>
                  <TextField
                    hiddenLabel
                    type="text"
                    name="title"
                    variant="outlined"
                    fullWidth
                    placeholder={`${translate?.globals?.title} ${translate?.globals?.english}`}
                    id="title"
                    className="text_field"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.title}
                    helperText={formik.touched.title && formik.errors.title}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    {`${translate?.globals?.title} ${translate.globals.arabic}`}
                  </Typography>
                  <TextField
                    hiddenLabel
                    type="text"
                    name="title_ar"
                    variant="outlined"
                    fullWidth
                    placeholder={`${translate?.globals?.title} ${translate.globals.arabic}`}
                    id="title"
                    className="text_field"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.title_ar}
                    helperText={
                      formik.touched.title_ar && formik.errors.title_ar
                    }
                  />
                </Grid>
                <div style={{ marginTop: 20 }} />
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">{`${translate?.globals?.description} `}</Typography>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    type="text"
                    placeholder={`${translate?.globals?.description} `}
                    fullWidth
                    multiline
                    minRows={4}
                    maxRows={4}
                    name="description"
                    variant="outlined"
                    id="description"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />
                </Grid>
              </Grid>
              <div className="form_btn">
                <Button
                  size="large"
                  type="submit"
                  className="btn btn_primary"
                  onClick={() => setError(true)}
                >
                  {translate.globals.save}
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default AddPromotionalVideo;
