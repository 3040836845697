import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import {
  useLazyGetAllFaqsQuery,
  useAddFaqsMutation,
  useDeleteFaqsMutation,
} from "../services/main";

import { useEffect, useState } from "react";
import { Loader, showError, showToast } from "../constants";
import useAuth from "../hooks/useAuth";
import useTranslation from "../hooks/Translation";

const MainFaq = () => {
  const userData = useAuth();
  const [question, setQuestion] = useState("");
  const [questionAr, setQuestionAr] = useState("");
  const translate = useTranslation();
  const [answer, setAnswer] = useState("");
  const [answerAr, setAnswerAr] = useState("");
  const [faqs, setFaqs] = useState<any>();
  const [getAllFaqs, { isLoading }] = useLazyGetAllFaqsQuery();
  const [addFaqsMutation] = useAddFaqsMutation();
  const [deleteFaqs] = useDeleteFaqsMutation();
  const [hidePermission, setHidePermission] = useState<any>({
    isAdd: true,
    isDelete: true,
  });
  const [removedItemId, setRemovedItemId] = useState("");
  const [loading, setLoading] = useState(false);

  const getAllFaqsHandler = async () => {
    setLoading(true);
    try {
      const res = await getAllFaqs({}).unwrap();
      if (res?.statusCode === 200) {
        setLoading(false);
        setFaqs(res?.data?.data || []);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
    }
  };

  const checkPermission = () => {
    const permissions = userData?.permission;
    if (permissions?.length) {
      let idx = -1;
      idx = permissions?.findIndex((ele: any) => ele?.name === "Manage FAQs");

      if (idx != -1) {
        let data = {
          isAdd: permissions[idx]?.isAdd,
          isDelete: permissions[idx]?.isDelete,
        };
        setHidePermission(data);
      }
    }
  };

  useEffect(() => {
    if (userData && userData?.permission?.length) {
      checkPermission();
    }
  }, [userData]);

  const handleAddFaq = async () => {
    const newFaq = {
      question: question,
      answer: answer,
      question_ar: questionAr,
      answer_ar: answerAr,
    };

    try {
      const res = await addFaqsMutation(newFaq).unwrap();
      if (res?.statusCode === 200) {
        getAllFaqsHandler();
        showToast(translate.error.faqAdd);
        setQuestion("");
        setAnswer("");
        setQuestionAr("");
        setAnswerAr("");
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleDeleteFaq = async (id: string) => {
    try {
      const res = await deleteFaqs({ faqId: id }).unwrap();
      if (res?.statusCode === 200) {
        setRemovedItemId(id);
        getAllFaqsHandler();
        showToast(translate.error.faqDel);
      }
    } catch (error: any) {
      showError(error?.message || "");
    }
  };

  useEffect(() => {
    getAllFaqsHandler();
  }, []);

  return (
    <Grid container spacing={2}>
      <Loader isLoad={loading} />
      {faqs?.length > 0
        ? faqs?.map((item: any, index: any) => {
            return (
              <Grid key={index} item xs={12}>
                <Box className="faq_box">
                  <FormControl sx={{ mb: 2, width: "100%" }}>
                    <Typography className="custom_label">
                      {`${translate.main.question} ${translate.globals.english}`}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="name"
                      variant="outlined"
                      value={item?.question}
                      placeholder={`${translate.main.question} ${translate.globals.english}`}
                      disabled
                    ></TextField>
                    <Typography className="custom_label" sx={{ pt: 2 }}>
                      {`${translate.main.question} ${translate.globals.arabic}`}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="name_ar"
                      variant="outlined"
                      value={item?.question_ar}
                      placeholder={`${translate.main.question} ${translate.globals.arabic}`}
                      disabled
                    ></TextField>
                  </FormControl>
                  <FormControl sx={{ width: "100%" }}>
                    <Typography className="custom_label">
                      {`${translate.main.answer} ${translate.globals.english}`}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="name"
                      variant="outlined"
                      value={item?.answer}
                      placeholder={`${translate.main.answer} ${translate.globals.english}`}
                      disabled
                    ></TextField>
                    <Typography className="custom_label" sx={{ pt: 2 }}>
                      {`${translate.main.answer} ${translate.globals.arabic}`}
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="name_ar"
                      variant="outlined"
                      value={item?.answer_ar}
                      placeholder={`${translate.main.answer} ${translate.globals.arabic}`}
                      disabled
                    ></TextField>
                  </FormControl>
                  {hidePermission?.isAdd ? (
                    <Box className="faq_btn">
                      <Button
                        variant="contained"
                        className="btn btn_primary sm"
                        onClick={() => handleDeleteFaq(item?._id)}
                        sx={{ width: 80 }}
                        disabled={removedItemId === item?._id}
                      >
                        {translate.globals.remove}
                      </Button>
                    </Box>
                  ) : null}
                </Box>
              </Grid>
            );
          })
        : ""}

      <Grid item xs={12}>
        <Box className="faq_box">
          <FormControl sx={{ mb: 2, width: "100%" }}>
            <Typography className="custom_label">
              {`${translate.main.question} ${translate.globals.english}`}
            </Typography>
            <TextField
              hiddenLabel
              type={"text"}
              name="name"
              variant="outlined"
              value={question}
              onChange={(val) => setQuestion(val.target.value.trimStart())}
              placeholder={`${translate.main.question} ${translate.globals.english}`}
              inputProps={{ maxLength: 250 }}
            ></TextField>
            <Typography className="custom_label" sx={{ pt: 2 }}>
              {`${translate.main.question} ${translate.globals.arabic}`}
            </Typography>
            <TextField
              hiddenLabel
              type={"text"}
              name="name_ar"
              variant="outlined"
              value={questionAr}
              onChange={(val) => setQuestionAr(val.target.value.trimStart())}
              placeholder={`${translate.main.question} ${translate.globals.arabic}`}
              inputProps={{ maxLength: 250 }}
            ></TextField>
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <Typography className="custom_label">{`${translate.main.answer} ${translate.globals.english}`}</Typography>
            <TextField
              hiddenLabel
              type={"text"}
              name="name"
              variant="outlined"
              value={answer}
              onChange={(val) => setAnswer(val.target.value.trimStart())}
              placeholder={`${translate.main.answer} ${translate.globals.english}`}
              inputProps={{ maxLength: 500 }}
            ></TextField>
            <Typography
              className="custom_label"
              sx={{ pt: 2 }}
            >{`${translate.main.answer} ${translate.globals.arabic}`}</Typography>
            <TextField
              hiddenLabel
              type={"text"}
              name="name_ar"
              variant="outlined"
              value={answerAr}
              onChange={(val) => setAnswerAr(val.target.value.trimStart())}
              placeholder={`${translate.main.answer} ${translate.globals.arabic}`}
              inputProps={{ maxLength: 500 }}
            ></TextField>
          </FormControl>
          {hidePermission?.isDelete ? (
            <Box className="faq_btn">
              {faqs?.length !== 20 ? (
                <Button
                  variant="contained"
                  sx={{ width: 80 }}
                  disabled={
                    !question?.length ||
                    !answer?.length ||
                    !questionAr?.length ||
                    !answerAr?.length
                  }
                  className="btn btn_primary sm"
                  onClick={handleAddFaq}
                >
                  {translate.globals.add}
                </Button>
              ) : null}
            </Box>
          ) : null}
        </Box>
      </Grid>
    </Grid>
  );
};

export default MainFaq;
